import React, {Component} from "react";
import RecapDoctorPaneContent from "../content/RecapDoctorPaneContent";
import CallableDialog from "../../../../nsi-lib/components/modals/CallableDialog";
import PropTypes from "prop-types";
import TextContent from "../../../text-content/TextContent";

import "../../Doctor.css";
import {Col} from "react-bootstrap";

const {doctor} = TextContent(localStorage.getItem("language"));
const data = {
    title: doctor.weekSummary.dialogTitle,
    summary: doctor.weekSummary.summary

}

export default class RecapDoctorPaneDialog extends Component {
    constructor(props) {
        super(props);

        this.closeDialog = this.closeDialog.bind(this);
    }

    closeDialog() {
        if (this.props.onClose) {
            this.props.onClose();
        }
    }



    render() {
        const {isOpen, content} = this.props;

        const currentDate = this.props.currentDate;


        return (
            <CallableDialog
                dialogClassName="RecapDoctor-content"
                isOpen={isOpen}
                handleClose={this.closeDialog}
                fullwidth
                content={
                    <div>
<div>

                    <h2 className="title_recap">
                        {data.summary}</h2>
</div>
                    <div>
                        <Col lg={4} md={4} sm={12} xs={12} className="calendar-img">
                            <Col lg={12} md={12} sm={6} xs={6}>
                                <img src={require("../../../../static/images/holidays-agenda.png")} className="holidays-icon"
                                     alt="holidays-img" width={100}/>
                            </Col>
                        </Col>
                    </div>

                        <h3>{data.title} : </h3>
                        <RecapDoctorPaneContent
                            content={content}
                            currentDate={currentDate}
                        />
                        <button className="schedule-close-button" onClick={() => this.closeDialog()}>
                            <i className="fa fa-times fa-2x" />
                        </button>
                    </div>

                }
            />

        )
    }
}

RecapDoctorPaneDialog.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    content: PropTypes.any,
    currentDate: PropTypes.any
}
