import React from 'react';
import { NavLink } from 'react-router-dom';
import TextContent from "../../../components/text-content/TextContent";

import './SideDrawer.css';
import LanguageSelector from "../../language-selector/LanguageSelector";

const { navbar } = TextContent(localStorage.getItem("language"));
const data = (
    {
        Tab1: navbar.Tab_1,
        Tab2: navbar.Tab_2,
        Tab3: navbar.Tab_3,
        Tab4: navbar.Tab_4,
        Tab4bis: navbar.Tab_4bis,
        Tab5: navbar.Tab_5,
        Tab51 : navbar.Tab_51,
        Tab52 : navbar.Tab_52,
        Tab53 : navbar.Tab_53
    }
)

const sideDrawer = props => {
  let drawerClasses = 'side-drawer';
  if (props.show) {
    drawerClasses = 'side-drawer open';
  }
  return (
    <div className={drawerClasses}>
      <div className="row">
        <button className="side-close-button" onClick={props.backdropClickHandler}>
          <i className="fa fa-times fa-2x" />
        </button>
      </div>
      <div className="row items" style={{marginTop: '20px'}}>
        <nav>
          <ul>
            <li><NavLink onClick={props.backdropClickHandler} exact to="/" activeClassName="active">{data.Tab1}</NavLink></li>
            <li><NavLink onClick={props.backdropClickHandler} exact to="/contact">{data.Tab2}</NavLink></li>
            <li><NavLink onClick={props.backdropClickHandler} exact to="/help">{data.Tab3}</NavLink></li>
              {props.currentUser ? (
                  <>
                      <li key="profile"><NavLink onClick={props.backdropClickHandler} exact to="/profile">{data.Tab5}</NavLink></li>
                      {props.currentUser.roles && props.currentUser.roles.map(role =>
                          role === "Admin" &&
                          <li key="admin"> <NavLink onClick={props.backdropClickHandler} exact to="/administration">{data.Tab51}</NavLink></li>)
                      }
                      {props.currentUser.roles && props.currentUser.roles.map(role =>
                          role === "Secretariat" ?
                              <li key="secretariat"> <NavLink onClick={props.backdropClickHandler} exact to="/secretariat">{data.Tab52}</NavLink></li>
                              :
                          role === "Doctor" &&
                              <li key="doctor"> <NavLink onClick={props.backdropClickHandler} exact to="/doctor">{data.Tab53}</NavLink></li>
                      )}
                      <li key="login"><NavLink onClick={() => {props.logOut(); props.backdropClickHandler()}} exact to="/login">{data.Tab4bis}</NavLink></li>
                  </>
              ) : (
                  <li key="login"><NavLink onClick={props.backdropClickHandler} exact to="/login">{data.Tab4}</NavLink></li>
              )}
            <li><LanguageSelector /></li>
          </ul>
        </nav>
      </div>
    </div>
    
  );
};

export default sideDrawer;
