import React, {Component} from "react";
import PropTypes from 'prop-types';
import Drawer from "@material-ui/core/Drawer";

export default class CustomizableDrawer extends Component {

    constructor(props) {
        super(props);

        this.handleClose = this.handleClose.bind(this);
    }

    handleClose(e) {
        if (this.props.handleClose) {
            this.props.handleClose(e);
        }
    }

    render() {
        const open = this.props.open ? this.props.open : false;
        const content = this.props.children;
        const className = this.props.className;
        const variant = this.props.variant ? this.props.variant : 'temporary';
        const anchor = this.props.anchor ? this.props.anchor : 'left';

        return (
            <Drawer
                variant={variant}
                anchor={anchor}
                open={open}
                onClose={this.handleClose}
                className={className}>
                {content &&
                    content
                }
            </Drawer>
        );
    }

}

CustomizableDrawer.propTypes = {
    open: PropTypes.bool.isRequired,
    children: PropTypes.node,
    handleClose: PropTypes.func,
    className: PropTypes.string,
    variant: PropTypes.oneOf(['temporary', 'permanent', 'persistent']),
    anchor: PropTypes.oneOf(['left', 'right', 'top', 'bottom'])
}
