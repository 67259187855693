import React, {Component} from "react";
import {Link, NavLink, Route} from 'react-router-dom';
import WhitePanel from "../white-panel/WhitePanel";
import {Button, Col, ControlLabel, FormControl, FormGroup, HelpBlock, Row} from "react-bootstrap";

import TextContent from "../text-content/TextContent";

import {connect} from "react-redux";
import {login} from "../../actions/auth";
import {getUser} from "../../services/user/userService";
import AuthService from "../../services/auth/auth.service";

import "./auth.css"
import ForgetPassword from "./forgetPassword";

const {authentication, resources} = TextContent(localStorage.getItem("language"));
const data = {
    avatar: resources.authentication.login.avatar,
    title: authentication.login.title,
    username: authentication.login.username,
    password: authentication.login.password,
    submit: authentication.login.submit,
    success: authentication.login.success,
    error: authentication.login.error,
    blocked: authentication.login.blocked,
    mandatory: authentication.Error.Mandatory,
    forget_password:authentication.login.forget_password
}

class Login extends Component {
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onChangeLogin = this.onChangeLogin.bind(this);
        this.onChangePassword = this.onChangePassword.bind(this);

        this.state = {
            username: "",
            password: "",
            errors: {
                username: "",
                password: ""
            },
            successful: false,
            message: ""
        };
    }

    onChangeLogin(e) {
        const value = e.target.value;
        let error = false;
        if (value.length === 0) {
            error = true;
        }
        this.setState({
            username: e.target.value,
            errors: {
                ...this.state.errors,
                username: error
            }
        });
    }

    onChangePassword(e) {
        const value = e.target.value;
        let error = false;
        if (value.length === 0) {
            error = true;
        }
        this.setState({
            password: e.target.value,
            errors: {
                ...this.state.errors,
                password: error
            }
        });
    }

    handleSubmit(e) {
        e.preventDefault();

        this.setState({
            successful: false,
        });

        getUser(this.state.username)
            .then(value => {
                if (value.data.status.name !== "Locked") {
                    this.props.dispatch(login(this.state.username, this.state.password))
                        .then(() => {
                            this.setState({
                                successful: true,
                                message: data.success
                            });
                            window.location.href = "/";
                        })
                        .catch(() => {
                            AuthService.loginFail(this.state.username)
                                .then(this.setState({
                                    loading: false,
                                    message: data.error
                                }));

                            setTimeout(() => {
                                this.setState({
                                    message: ""
                                });
                            }, 2500);
                        });
                } else {
                    this.setState({
                        message: data.blocked,
                    });
                }
            }).catch(() => {
                this.setState({
                    loading: false,
                    message: data.error,
                });

                setTimeout(() => {
                    this.setState({
                        message: ""
                    });
                }, 2500);
            });
    }

    componentDidMount() {
        if (this.props.isLoggedIn) {
            window.location.href = "/profile";
        }
    }

    render() {
        const formValid = !this.state.errors.username && !this.state.errors.password
                       && this.state.username.length > 0 && this.state.password.length > 0;

        return (
            <div className="Login">
                <WhitePanel>
                    <h1>{data.title} :</h1>
                    <Row>
                        <Col xs={1} xsOffset={11}>
                            <Link to="/">
                                <i className="fa fa-times fa-2x" />
                            </Link>
                        </Col>
                    </Row>
                    <Row style={{marginTop: "20px", marginBottom: "-20px"}}>
                        <Col lg={4} md={4} sm={12} xs={12}>
                                <img src={require(`../../${data.avatar}`)} className="profile-img-card"
                                     alt="profile-img" style={{marginTop: '50px'}}/>
                        </Col>
                        <Col lg={8} md={12} sm={12} xs={12}>
                            <form onSubmit={this.handleSubmit}>
                                {!this.state.successful && (
                                    <div>
                                        <FormGroup controlId="username"
                                                   validationState={this.state.errors.username ? "error" : null}>
                                            <ControlLabel>{data.username}</ControlLabel>
                                            <FormControl
                                                placeholder={data.username}
                                                value={this.state.username}
                                                onChange={this.onChangeLogin}
                                            />
                                            {this.state.errors.username ?
                                                <HelpBlock>{data.mandatory}</HelpBlock> : null}
                                        </FormGroup>
                                        <FormGroup controlId="password"
                                                   validationState={this.state.errors.password ? "error" : null}>
                                            <ControlLabel>{data.password}</ControlLabel>
                                            <FormControl
                                                placeholder={data.password}
                                                type="password"
                                                value={this.state.password}
                                                onChange={this.onChangePassword}
                                            />
                                            {this.state.errors.password ?
                                                <HelpBlock>{data.mandatory}</HelpBlock> : null}
                                        </FormGroup>
                                        <br/>
                                        <nav>
                                            <NavLink exact to="/forget-password">
                                                {data.forget_password}
                                            </NavLink>
                                        </nav>
                                        <Route path="/forget-password" component={ForgetPassword} />
                                        <br/>
                                        <div className="text-right">
                                            <Button bsSize="large" disabled={!formValid} type="submit">
                                                {data.submit}
                                            </Button>
                                        </div>

                                    </div>
                                )}
                                {this.state.message && (
                                    <div className="form-group">
                                        <div
                                            className={this.state.successful ? "alert alert-success" : "alert alert-danger"}
                                            role="alert">
                                            {this.state.message}
                                        </div>
                                    </div>
                                )}
                            </form>
                        </Col>
                    </Row>
                </WhitePanel>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const {isLoggedIn} = state.auth;
    return {
        isLoggedIn
    };
}

export default connect(mapStateToProps)(Login);