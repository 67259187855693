import {
    GET_SETTINGS_SUCCESS,
    GET_SETTINGS_FAIL,
    UPDATE_SETTINGS_SUCCESS,
    UPDATE_SETTINGS_FAIL,
    SET_MESSAGE
} from "./types";

import SettingsService from "../services/settings/settingsService";

export const getSettings = (currentUser) => (dispatch) => {
    return SettingsService.getSettings(currentUser).then(
        (data) => {
            dispatch({
                type: GET_SETTINGS_SUCCESS,
                payload: {settings: data},
            });

            return Promise.resolve();
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            dispatch({
                type: GET_SETTINGS_FAIL,
            });

            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });

            return Promise.reject();
        }
    )
}

export const updateSettings = (settings, currentUser) => (dispatch) => {
    return SettingsService.updateSettings(settings, currentUser).then(
        (data) => {
            dispatch({
                type: UPDATE_SETTINGS_SUCCESS,
                payload: {settings: data},
            });

            dispatch({
                type: SET_MESSAGE,
                payload: data.message,
            });

            return Promise.resolve();
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            dispatch({
                type: UPDATE_SETTINGS_FAIL,
            });

            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });

            return Promise.reject();
        }
    )
}