import React, {Component} from "react";
import {connect} from "react-redux";
import moment from "moment";
import {getRoomLeader, getUserLedRooms} from "../../services/room/roomService";
import {getUserHolidays} from "../../services/holidays/holidaysService";
import {DropdownButton, MenuItem} from "react-bootstrap";
import ScheduleDetailsPaneDialog from "./Pane/dialog/ScheduleDetailsPaneDialog";
import CreateHolidaysPaneDialog from "./Pane/dialog/CreateHolidaysPaneDialog";
import RecapDoctorPaneDialog from "./Pane/dialog/RecapDoctorPaneDialog"
import Calendar from "@toast-ui/react-calendar";
import TextContent from "../text-content/TextContent";
import {
    createRoomForMeetings,
    setRoomParticipant,
    updateRoom
} from "../../services/room/roomService";
import {sendMailForInvitationCalendar} from "../../actions/mail";

import {sendSMS} from "../../services/sms/smsService";

import 'tui-calendar/dist/tui-calendar.css';
import 'tui-date-picker/dist/tui-date-picker.css';
import 'tui-time-picker/dist/tui-time-picker.css';
import "./Doctor.css";
import MeetingPaneDoctorDialog from "../doctor/Pane/dialog/MeetingPaneDoctorDialog";

const {doctor,secretariat} = TextContent(localStorage.getItem("language"));
const data = {
    day: doctor.day,
    week: doctor.week,
    today: doctor.today,
    away: doctor.away,
    holidays: doctor.holidays,
    availabilities: doctor.availabilities,
    weekMeetings: doctor.weekMeetings,
    windowConfirm: secretariat.windowConfirm,
    monday: doctor.weekDays.monday,
    tuesday: doctor.weekDays.tuesday,
    wednesday: doctor.weekDays.wednesday,
    thursday: doctor.weekDays.thursday,
    friday: doctor.weekDays.friday,
    saturday: doctor.weekDays.saturday,
    sunday: doctor.weekDays.sunday,
    format: doctor.dateFormat,
    template: {
        delete: secretariat.template.delete,
        edit: secretariat.template.edit,
        update: secretariat.template.update,
        save: secretariat.template.save,
        title: secretariat.template.title,
        startDate: secretariat.template.startDate,
        endDate: secretariat.template.endDate,
    },
    error: {
        create: secretariat.error.create,
        update: secretariat.error.update,
        delete: secretariat.error.delete,
    }
}

class Doctor extends Component {
    constructor(props) {
        super(props);

        this.state = {
            calendarInstance: "",
            scheduleList: [],
            holidayList: [],
            content: "",
            doctors: "",
            currentRoomCode: "",
            recapdoctor: "",
            selectedDoctor:"",
            toggledHolidaysPane: false,
            toggledScheduleDetailsPane: false,
            toggledRecapDoctor: false,
            currentMonday:[],
            createPane: {
                toggledCreatePane: false,
                defaultContent: ""
            },
            message: ""
        }


        this.onClickNavi = this.onClickNavi.bind(this);
        this.onClickMenu = this.onClickMenu.bind(this);
        this.toggleHolidays = this.toggleHolidays.bind(this);
        this.toggleScheduleDetails = this.toggleScheduleDetails.bind(this);
        this.toggleRecapDoctor = this.toggleRecapDoctor.bind(this);

        this.getNewSchedule = this.getNewSchedule.bind(this);
        this.updateSchedule = this.updateSchedule.bind(this);
    }

    toggleCreatePaneDialog(open, defaultContent) {
        let result;
        let shouldAsk = false;

        this.state.holidayList.forEach(holiday => {
            if (holiday.start !== false && holiday.end !== false) {
                const start = new Date(holiday.start);
                const end = new Date(holiday.end);
                const dateToCheck = defaultContent && new Date(defaultContent.start);

                result = defaultContent && moment(dateToCheck).isBetween(start, end);
                if (result) {
                    shouldAsk = true;
                }
            }
        });

        if (shouldAsk && !defaultContent.schedule) {
            const userChoice = window.confirm(data.windowConfirm);
            open = userChoice === true;
        }

        if(defaultContent && defaultContent.schedule) {


            if (defaultContent.end) {
                defaultContent.schedule.start = defaultContent.start
                defaultContent.schedule.end = defaultContent.end
            }


            defaultContent = defaultContent.schedule;
            this.setState({
                currentRoomCode: defaultContent.raw.code
            });
        }
        this.setState({
            createPane: {
                ...this.state.createPane,
                toggledCreatePane: open,
                defaultContent
            }
        });

        if(defaultContent){
            this.setState({
                createPane: {
                    ...this.state.createPane,
                    toggledCreatePane: open,
                    defaultContent
                }
            });
        }
        else {
            this.setState({
                createPane: {
                    ...this.state.createPane,
                    toggledCreatePane: open,

                }
            });
        }
    }

    calendarRef = React.createRef();

    currentCalendarDate(format) {
        const currentDate = moment([this.state.calendarInstance.getDate().getFullYear(), this.state.calendarInstance.getDate().getMonth(), this.state.calendarInstance.getDate().getDate()]);
        return currentDate.format(format);
    }

    setDropdownCalendarType() {
        const calendarTypeName = document.getElementById('calendarTypeName');
        const calendarTypeIcon = document.getElementById('calendarTypeIcon');
        let type = this.state.calendarInstance.getViewName();
        let iconClassName;

        if (type === 'day') {
            type = data.day;
            iconClassName = 'calendar-icon ic_view_day';
        } else if (type === 'week') {
            type = data.week;
            iconClassName = 'calendar-icon ic_view_week';
        }

        calendarTypeName.innerHTML = type;
        calendarTypeIcon.className = iconClassName;
    }

    setRenderRangeText(first) {



        const html = [];
        const renderRange = document.getElementById('renderRange');
        const viewName = this.state.calendarInstance.getViewName();
        const newHolidayList = this.state.holidayList.slice();

        if (viewName === 'day') {
            const currentDay = new Date(this.currentCalendarDate('YYYY-MM-DD'));
            newHolidayList.forEach(holiday => {
                if (holiday.raw.isRecurrent) {
                    if ((currentDay.getDay() || 7) - 1 === holiday.raw.selectedRecurrenceDay) {
                        holiday.start = holiday.raw.morning && holiday.raw.afternoon ? `${this.currentCalendarDate('YYYY-MM-DD')}T00:00:00.000Z` : holiday.raw.morning ? `${this.currentCalendarDate('YYYY-MM-DD')}T00:00:00.000Z` : holiday.raw.afternoon && `${this.currentCalendarDate('YYYY-MM-DD')}T12:00:00.000`;
                        holiday.end = holiday.raw.morning && holiday.raw.afternoon ? `${this.currentCalendarDate('YYYY-MM-DD')}T22:00:00.000Z` : holiday.raw.morning ? `${this.currentCalendarDate('YYYY-MM-DD')}T12:00:00.000` : holiday.raw.afternoon && `${this.currentCalendarDate('YYYY-MM-DD')}T22:00:00.000Z`;
                    }
                }
            });
            if(!first) this.setState({holidayList: newHolidayList});

            html.push(this.currentCalendarDate(data.format));
        } else {
            const monday = new Date(moment(this.state.calendarInstance.getDateRangeStart().toDate()).format('YYYY-MM-DD'));

            newHolidayList.forEach(holiday => {
                if (holiday.raw.isRecurrent) {
                    const recurrenceDay = new Date(monday).setDate(monday.getDate() + holiday.raw.selectedRecurrenceDay);
                    holiday.start = holiday.raw.morning && holiday.raw.afternoon ? `${moment(recurrenceDay).format('YYYY-MM-DD')}T00:00:00.000Z` : holiday.raw.morning ? `${moment(recurrenceDay).format('YYYY-MM-DD')}T00:00:00.000Z` : holiday.raw.afternoon && `${moment(recurrenceDay).format('YYYY-MM-DD')}T12:00:00.000`;
                    holiday.end = holiday.raw.morning && holiday.raw.afternoon ? `${moment(recurrenceDay).format('YYYY-MM-DD')}T22:00:00.000Z` : holiday.raw.morning ? `${moment(recurrenceDay).format('YYYY-MM-DD')}T12:00:00.000` : holiday.raw.afternoon && `${moment(recurrenceDay).format('YYYY-MM-DD')}T22:00:00.000Z`;
                }
            });
            if(!first) this.setState({holidayList: newHolidayList});

            html.push(moment(this.state.calendarInstance.getDateRangeStart().getTime()).format(data.format));
            html.push(' ~ ');
            html.push(moment(this.state.calendarInstance.getDateRangeEnd().getTime()).format(data.format));
        }
        renderRange.innerHTML = html.join('');
    }

    getDataAction(target) {
        return target.dataset ? target.dataset.action : target.getAttribute('data-action');
    }

    onClickMenu(e) {
        const target = e.target;
        const action = this.getDataAction(target);
        const options = this.state.calendarInstance.getOptions();
        let viewName = '';

        switch (action) {
            case 'toggle-daily':
                viewName = 'day';
                break;
            case 'toggle-weekly':
                viewName = 'week';
                break;
            default:
                break;
        }

        this.state.calendarInstance.setOptions(options, true);
        this.state.calendarInstance.changeView(viewName, true);

        this.setDropdownCalendarType();
        this.setRenderRangeText(false);
    }

    onClickNavi(e) {
        const action = this.getDataAction(e.target);

        //const PrevDate = new Date(moment(this.state.calendarInstance.getDateRangeStart().toDate()).format('YYYY-MM-DD'));


        switch (action) {

            case 'move-prev':
                this.state.calendarInstance.prev();
                const PrevDate = new Date(moment(this.state.calendarInstance.getDateRangeStart().toDate()).format('YYYY-MM-DD'));
                this.setState({
                    currentMonday : PrevDate
                })
                break;

            case 'move-next':
                this.state.calendarInstance.next();
                const NextDate = new Date(moment(this.state.calendarInstance.getDateRangeStart().toDate()).format('YYYY-MM-DD'));
                this.setState({
                    currentMonday : NextDate
                })
                break;

            case 'move-today':
                this.state.calendarInstance.today();
                break;
            default:
                return;
        }
        this.setRenderRangeText(false);
    }

    getCalendars() {



        let calendars = [];
        this.state.doctors &&
        this.state.doctors.forEach(doctor => {
            calendars.push({
                id: doctor.id,
                name: doctor.fullname,
                color: '#ffffff',
                bgColor: '#00a9ff',
                dragBgColor: '#00a9ff',
                borderColor: '#00a9ff'
            });
        });

        return calendars;
    }


    getNewSchedule(content, shouldSendEmail, shouldSendSMS) {
        let participantsToSend, leadersToSend;
        let emails = [];
        let phones = [];
        let mailForHost = [];


        participantsToSend = content.raw.participants.external;
        leadersToSend = this.props.user;


        participantsToSend.forEach(participant => {
            if (participant.email) {
                emails.push(participant.email);
            }
            if (participant.phone) {
                phones.push(participant.phone);
            }
        });


            if (leadersToSend.email) {
                emails.push(leadersToSend.email);
                mailForHost.push(leadersToSend.email);

            }
            if (leadersToSend.phone) {
                phones.push(leadersToSend.phone);
            }





        new Promise((resolve, reject) => {
             setRoomParticipant(this.props.user, participantsToSend)
                 .then(value => {
                     leadersToSend = [];
                     leadersToSend.push({"id":this.props.user.id})

                     resolve(createRoomForMeetings(this.props.user, content.start, content.end, leadersToSend, value.data, {title: content.title, description: content.raw.description})
                         .then(value => {
                             content.id = value.data.roomid;
                             content.raw.code = value.data.code;
                             this.setState(prevState => ({
                                 scheduleList: [...prevState.scheduleList, content]
                             }));
                             if (shouldSendEmail)
                                 resolve(this.props.dispatch(sendMailForInvitationCalendar("meetingcreation", value.data.code, this.props.user.fullname,mailForHost, emails, content.start, content.end)));
                             if (shouldSendSMS)
                                 resolve(sendSMS("meetingcreation", value.data.code, this.props.user.fullname, phones, content.start, content.end));
                             window.location.reload();
                         }).catch(() => {
                             reject(this.setState({
                                 message: data.error.create
                             }));
                         }));
                 });
         });
    }

    updateSchedule(content, shouldSendEmail, shouldSendSMS) {
        let oldParticipants, participantsToSend, newParticipants;
        let oldLeaders, leadersToSend, newLeaders;
        let oldEmails = [], newEmails = [];
        let oldPhones = [], newPhones = [];
        let oldMailForHost = [], newMailForHost = [];



        oldParticipants = content.raw.oldParticipants.external;
        oldLeaders = this.props.user;
        newParticipants = content.raw.newParticipants.external;
        newLeaders = content.raw.newParticipants.internal;
        participantsToSend = content.raw.participants.external;
        leadersToSend = content.raw.participants.internal;

        oldParticipants.forEach(participant => {
            if (participant.email) oldEmails.push(participant.email);
            if (participant.phone) oldPhones.push(participant.phone);
        });


            if (oldLeaders.email) oldEmails.push(oldLeaders.email);
            if (oldLeaders.phone) oldPhones.push(oldLeaders.phone);
            if (oldLeaders.email) oldMailForHost.push(oldLeaders.email)


        newParticipants.forEach(participant => {
            if (participant.email) newEmails.push(participant.email);
            if (participant.phone) newPhones.push(participant.phone);
        });


            if (newLeaders.email) newEmails.push(newLeaders.email);
            if (newLeaders.phone) newPhones.push(newLeaders.phone);
            if (newLeaders.email) newMailForHost.push(newLeaders.email)





        const index = this.state.scheduleList.findIndex(schedule => schedule.id === content.id),
            scheduleList = [...this.state.scheduleList];
        scheduleList[index] = content;

        new Promise((resolve, reject) => {
            if (newParticipants && newParticipants.length > 0) {
                setRoomParticipant(this.props.user, newParticipants)
                    .then(value => {
                        participantsToSend = [...value.data];
                        resolve(updateRoom(this.props.user, content.start, content.end, {title: content.title, description: content.raw.description}, leadersToSend, participantsToSend, content.id)
                            .then(() => {
                                this.setState({scheduleList});
                                if (shouldSendEmail) {
                                    resolve(this.props.dispatch(sendMailForInvitationCalendar("meetingupdate", this.state.currentRoomCode, this.props.user.fullname,oldMailForHost, oldEmails, content.start, content.end)));
                                    resolve(this.props.dispatch(sendMailForInvitationCalendar("meetingcreation", this.state.currentRoomCode, this.props.user.fullname,newMailForHost, newEmails, content.start, content.end)));
                                }
                                if (shouldSendSMS) {
                                    resolve(sendSMS("meetingupdate", this.state.currentRoomCode, this.props.user.fullname, oldPhones, content.start, content.end));
                                    resolve(sendSMS("meetingcreation", this.state.currentRoomCode, this.props.user.fullname, newPhones, content.start, content.end));
                                }
                                window.location.reload();

                            }).catch(() => {
                                reject(this.setState({
                                    message: data.error.update
                                }));
                            }));
                    });
            } else {
                updateRoom(this.props.user, content.start, content.end, {title: content.title, description: content.raw.description}, leadersToSend, participantsToSend, content.id)
                    .then(() => {
                        this.setState({scheduleList});
                        if (shouldSendEmail) {
                            resolve(this.props.dispatch(sendMailForInvitationCalendar("meetingupdate", this.state.currentRoomCode, this.props.user.fullname,oldMailForHost, oldEmails, content.start, content.end)));
                            resolve(this.props.dispatch(sendMailForInvitationCalendar("meetingcreation", this.state.currentRoomCode, this.props.user.fullname,newMailForHost, newEmails, content.start, content.end)));
                        }
                        if (shouldSendSMS) {
                            resolve(sendSMS("meetingupdate", this.state.currentRoomCode, this.props.user.fullname, oldPhones, content.start, content.end));
                            resolve(sendSMS("meetingcreation", this.state.currentRoomCode, this.props.user.fullname, newPhones, content.start, content.end));
                        }
                        window.location.reload();

                    }).catch(() => {
                    reject(this.setState({
                        message: data.error.update
                    }));
                });
            }
        });
    }

    toggleHolidays(open) {
        this.setState({
            toggledHolidaysPane: open
        })
    }

    toggleScheduleDetails(open, content) {
        this.setState({
            toggledScheduleDetailsPane: open,
            content
        })
    }

    toggleRecapDoctor(open, content, currentMonday) {
        this.setState({
            toggledRecapDoctor: open,
            content,
        })
    }

    componentDidMount() {

        this.setState({
            selectedDoctor : this.state.recapdoctor
        })

        if (!this.props.isLoggedIn) {
            this.props.history.push("/login");
        } else if (this.props.user && !this.props.user.roles.includes("Doctor")) {
            this.props.history.push("/profile");
        } else {
            this.setState({
                calendarInstance: this.calendarRef.current ? this.calendarRef.current.getInstance() : ""
            });
            return new Promise((resolve, reject) => {
                getUserLedRooms(this.props.user).then(value => {
                    this.setState({
                        recapdoctor : value.data
                    })
                    value.data && value.data.length > 0 && value.data.forEach(room => {
                        getRoomLeader(this.props.user, room.roomid).then(value => {
                            const leader = value.data;
                            this.setState(prevState => ({
                                scheduleList: [...prevState.scheduleList, {
                                    id: room.roomid,
                                    calendarId: this.props.user.id,
                                    title: room.details ? room.details.title : "",
                                    category: "time",
                                    start: room.starting_date,
                                    end: room.end_date,
                                    color: '#ffffff',
                                    bgColor: '#00a9ff',
                                    dragBgColor: '#00a9ff',
                                    borderColor: '#00a9ff',
                                    raw: {
                                        participants: {
                                            ...this.state.participants,
                                            internal: value.data,
                                            external: room.participants
                                        },
                                        leaders : leader,
                                        description: room.details ? room.details.description : "",
                                        code: room.code
                                    }
                                }]
                            }));
                        })
                    });
                }).catch(reason => {
                    reject(reason);
                });

                getUserHolidays(this.props.user, this.props.user.id).then(value => {
                    value.data && value.data.length > 0 && value.data.forEach(holiday => {
                        let monday;
                        let recurrenceDay;
                        let selectedRecurrenceDay;
                        if (holiday.day) {
                            const day = holiday.day.toLowerCase();
                            switch (day) {
                                case "monday": selectedRecurrenceDay = 0
                                    break;
                                case "tuesday": selectedRecurrenceDay = 1
                                    break;
                                case "wednesday": selectedRecurrenceDay = 2
                                    break;
                                case "thursday": selectedRecurrenceDay = 3
                                    break;
                                case "friday": selectedRecurrenceDay = 4
                                    break;
                                case "saturday": selectedRecurrenceDay = 5
                                    break;
                                case "sunday": selectedRecurrenceDay = 6
                                    break;
                                default: console.log("No recurrence day")
                            }
                        }
                        if (this.state.calendarInstance) {
                            monday = new Date(moment(this.state.calendarInstance.getDateRangeStart().getTime()).format('YYYY-MM-DD'));
                            recurrenceDay = moment(new Date(monday).setDate(monday.getDate() + selectedRecurrenceDay)).format('YYYY-MM-DD');
                        }

                        this.setState(prevState => ({
                            holidayList: [...prevState.holidayList, {
                                calendarId: this.props.user.id,
                                title: holiday.isrecurrent ? data.away : data.holidays,
                                category: "time",
                                start: holiday.isrecurrent ? holiday.morning && holiday.afternoon ? `${recurrenceDay}T00:00:00.000Z` : holiday.morning ? `${recurrenceDay}T00:00:00.000Z` : holiday.afternoon && `${recurrenceDay}T12:00:00.000` : holiday.starting_date,
                                end: holiday.isrecurrent ? holiday.morning && holiday.afternoon ? `${recurrenceDay}T22:00:00.000Z` : holiday.morning ? `${recurrenceDay}T12:00:00.000` : holiday.afternoon && `${recurrenceDay}T22:00:00.000Z` : holiday.end_date,
                                isVisible: holiday.isrecurrent ? !(!holiday.morning && !holiday.afternoon) : true,
                                isReadOnly: true,
                                color: '#ffffff',
                                bgColor: holiday.isrecurrent ? '#e3e3e3' : '#bdfcb8',
                                dragBgColor: holiday.isrecurrent ? '#e3e3e3' : '#bdfcb8',
                                borderColor: holiday.isrecurrent ? '#e3e3e3' : '#bdfcb8',
                                raw: {
                                    type: "holidays",
                                    holidayId: holiday.holidayid,
                                    day: holiday.day ? holiday.day.toLowerCase() : "",
                                    morning: holiday.morning,
                                    afternoon: holiday.afternoon,
                                    isRecurrent: holiday.isrecurrent,
                                    selectedRecurrenceDay
                                }
                            }]
                        }));
                    });
                }).catch(reason => {
                    reject(reason);
                });
            });
        }
    }




    render() {
        if(this.state.calendarInstance) {
            this.setRenderRangeText(true);
        }

        return (
            <div className="Doctor">
                <div id="menu" className="top-menu">
                    <span id="menu-navi" onClick={this.onClickNavi}>
                        <button type="button" className="btn btn-default btn-sm move-day" data-action="move-prev">
                            <i className="calendar-icon ic-arrow-line-left" data-action="move-prev"/>
                        </button>
                        <button type="button" className="btn btn-default btn-sm move-day" data-action="move-next">
                            <i className="calendar-icon ic-arrow-line-right" data-action="move-next"/>
                        </button>
                        <button type="button" className="btn btn-default btn-sm move-today" data-action="move-today">
                            {data.today}
                        </button>
                    </span>
                    <div className="calendar-type">
                        <button type="button" className="btn btn-default btn-sm weekMeetings" onClick={() => this.toggleRecapDoctor(true)}>
                            {data.weekMeetings}
                        </button>
                        <button type="button" className="btn btn-default btn-sm holidays" onClick={() => this.toggleHolidays(true)}>
                            {data.availabilities}
                        </button>
                        <DropdownButton
                            noCaret
                            id="dropdownMenu-calendarType"
                            title={
                                <div>
                                    <i id="calendarTypeIcon" className="calendar-icon ic_view_week"
                                       style={{marginRight: "4px"}}/>
                                    <span id="calendarTypeName">{data.week}</span>
                                    <i className="calendar-icon tui-full-calendar-dropdown-arrow"/>
                                </div>
                            }
                        >
                            <MenuItem eventKey="1" className="dropdown-menu-title" data-action="toggle-daily"
                                      onClick={this.onClickMenu}><i
                                className="calendar-icon ic_view_day"/>{data.day}</MenuItem>
                            <MenuItem eventKey="2" className="dropdown-menu-title" data-action="toggle-weekly"
                                      onClick={this.onClickMenu}><i
                                className="calendar-icon ic_view_week"/>{data.week}</MenuItem>
                        </DropdownButton>
                    </div>
                    <span id="renderRange" className="render-range"/>
                </div>


                <Calendar
                    ref={this.calendarRef}
                    height="700px"
                    usageStatistics={false}
                    template={{
                        timegridDisplayPrimaryTime: localStorage.getItem("language") !== "english" && function (time) {
                            return time.hour + "h";
                        },
                        popupDelete: () => {return data.template.delete},
                        popupEdit: () => {return data.template.edit},
                        popupSave: () => {return data.template.save},
                        popupUpdate: () => {return data.template.update},
                        titlePlaceholder: () => {return data.template.title},
                        startDatePlaceholder: () => {return data.template.startDate},
                        endDatePlaceholder: () => {return data.template.endDate},
                        alldayTitle: () => {return data.holidays}
                    }}
                    calendars={this.getCalendars()}
                    schedules={this.state.scheduleList.concat(this.state.holidayList)}
                    taskView={false}
                    useCreationPopup={false}
                    useDetailPopup={false}
                    week={{
                        daynames: [data.sunday, data.monday, data.tuesday, data.wednesday, data.thursday, data.friday, data.saturday],
                        hourStart: 7,
                        hourEnd: 19,
                        startDayOfWeek: 1
                    }}
                    month={{
                        daynames: [data.sunday, data.monday, data.tuesday, data.wednesday, data.thursday, data.friday, data.saturday],
                        narrowWeekend: true,
                        startDayOfWeek: 1
                    }}
                    onBeforeCreateSchedule={(e) => {this.toggleCreatePaneDialog(true, e)}}
                    onBeforeUpdateSchedule={(e) => {this.toggleCreatePaneDialog(true, e)}}
                    onClickSchedule={(e) => {this.toggleCreatePaneDialog(true,e)}}
                />

                <MeetingPaneDoctorDialog
                    isOpen={this.state.createPane.toggledCreatePane}
                    onClose={() => this.toggleCreatePaneDialog(false)}
                    defaultContent={this.state.createPane.defaultContent}
                        getScheduleContent={(e, shouldSendEmail, shouldSendSMS) => e.raw.isUpdate ? this.updateSchedule(e, shouldSendEmail, shouldSendSMS) : this.getNewSchedule(e, shouldSendEmail, shouldSendSMS)}
                    currentUser={this.props.user}
                    selectedDoctor={this.state.selectedDoctor}
                    settings={this.props.settings}
                />

                <CreateHolidaysPaneDialog
                    isOpen={this.state.toggledHolidaysPane}
                    onClose={() => this.toggleHolidays(false)}
                    content={this.state.holidayList}
                    currentUser={this.props.user}
                />
                <ScheduleDetailsPaneDialog
                    isOpen={this.state.toggledScheduleDetailsPane}
                    onClose={() => this.toggleScheduleDetails(false)}
                    content={this.state.content}
                    currentUser={this.props.user}
                />
                <RecapDoctorPaneDialog
                    isOpen={this.state.toggledRecapDoctor}
                    onClose={() => this.toggleRecapDoctor(false)}
                    content={this.state.recapdoctor}
                    currentDate={this.state.currentMonday}

                />
            </div>
        );
    }
}

function mapStateToProps(state) {
    const {user, isLoggedIn} = state.auth;
    const {settings} = state.settings;
    return {
        user,
        isLoggedIn,settings
    };
}

export default connect(mapStateToProps)(Doctor);
