import React, {Component} from "react";
import PropTypes from "prop-types";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";

export default class SelectInput extends Component {

    constructor(props) {
        super(props);

        this.state = {
            value: this.props.defaultValues
        }

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(e) {
        const value = e.target.value;
        this.setState({
            value: value
        });
        if (this.props.onChange) {
            this.props.onChange(value);
        }
    }

    render() {
        const title = this.props.title;
        const choices = this.props.choices;
        if (!title || !choices) return null;
        const id = title.toLowerCase().replaceAll(" ", "-");
        const helperText = this.props.helperText;

        const defaultValue = this.props.multiple ? [] : '';

        return (
            <FormControl required={this.props.required} className="w-100">
                <InputLabel id={"selector-label-" + id}>{title}</InputLabel>
                <Select
                    labelId={"selector-label-" + id}
                    id={"selector-" + id}
                    value={this.state.value ? this.state.value : defaultValue}
                    onChange={this.handleChange}
                    multiple={this.props.multiple}
                >
                    {choices && choices.map((c, index) => (
                        <MenuItem key={index} value={c.value}>{c.name}</MenuItem>
                    ))}
                </Select>
                {helperText &&
                <FormHelperText>{helperText}</FormHelperText>
                }
            </FormControl>
        );
    }

}

SelectInput.propTypes = {
    title: PropTypes.string.isRequired,
    helperText: PropTypes.string,
    choices: PropTypes.arrayOf(PropTypes.exact({
        name: PropTypes.string,
        value: PropTypes.any
    })).isRequired,
    onChange: PropTypes.func,
    defaultValues: PropTypes.any,
    multiple: PropTypes.bool,
    required: PropTypes.bool
}
