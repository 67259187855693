import React, {Component} from "react";
import {getUsers} from "../../services/user/userService";
import {getRoles} from "../../services/role/roleService";
import {getStatus} from "../../services/status/statusService";
import {connect} from "react-redux";
import WhitePanel from "../white-panel/WhitePanel";
import CollapsableSetting from "../../nsi-lib/components/settings/CollapsableSetting";
import TextContent from "../text-content/TextContent";
import {Button, Table} from "react-bootstrap";
import UpdateUserPaneDialog from "./Pane/dialog/UpdateUserPaneDialog";
import CreateUserPaneDialog from "./Pane/dialog/CreateUserPaneDialog";

import "./User.css";

const { userManagement, resources } = TextContent(localStorage.getItem("language"));
const data = {
    homeButton: resources.admin.homeButton,
    title: userManagement.title,
    buttonCreate: userManagement.userList.buttonCreate,
    buttonOrderByUsers: userManagement.userList.buttonOrderByUsers,
    buttonOrderByRoles: userManagement.userList.buttonOrderByRoles,
    search: userManagement.userList.search,
    fullname: userManagement.userList.fullname,
    username: userManagement.userList.username,
    email: userManagement.userList.email,
    roles: userManagement.userList.roles
}

class UserList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            userData: null,
            roleData: null,
            statusData: null,
            toView: null,
            toggledPane: false,
            toggledCreatePane: false,
            index: -1,
            displayBy: "Users"
        }
    }

    togglePaneDialog(open, user) {
        this.setState({
            toView: {...user},
            toggledPane: open
        });
    }

    toggleCreatePaneDialog(open) {
        this.setState({
            toggledCreatePane: open
        });
    }

    search() {
        let input, filter, table, tr, td, i, txtValue;
        input = document.getElementById("myInput");
        filter = input.value.toUpperCase();
        table = document.getElementById("myTable");
        tr = table.getElementsByTagName("tr");

        for (i = 0; i < tr.length; i++) {
            td = tr[i].getElementsByTagName("td")[1];
            if (td) {
                txtValue = td.textContent || td.innerText;
                if (txtValue.toUpperCase().indexOf(filter) > -1) {
                    tr[i].style.display = "";
                } else {
                    tr[i].style.display = "none";
                }
            }
        }
    }

    componentDidMount() {
        if (this.props.user && !this.props.user.roles.includes("Admin")) {
            this.props.history.push("/profile");
        } else {
            new Promise((resolve, reject) => {
                getUsers(this.props.user).then(value => {
                    resolve(this.setState({userData: value.data}));
                }).catch(reason => {
                    reject(reason);
                });
                getRoles(this.props.user).then(value => {
                    resolve(this.setState({roleData: value.data}));
                }).catch(reason => {
                    reject(reason);
                });
                getStatus(this.props.user).then(value => {
                    resolve(this.setState({statusData: value.data}));
                }).catch(reason => {
                    reject(reason);
                });
            }).catch(() => {
                this.props.history.push("/login");
            });
        }
    }

    render() {
        return (
            <div className="UserList">
                <WhitePanel>
                    <div className="upper-page">
                        <h1>{data.title} :</h1>
                        <button
                            style={{float: "right"}}
                            onClick={() => this.toggleCreatePaneDialog(true)}
                            className="button create-user"
                        >
                            {data.buttonCreate}
                        </button>
                    </div>
                    <div className="buttons-bellow-upper-page">
                        <Button
                            style={{float: "left"}}
                            bsSize="large"
                            onClick={() => this.setState({displayBy: "Users"})}
                            className="users"
                        >
                            {data.buttonOrderByUsers}
                        </Button>
                        <Button
                            bsSize="large"
                            style={{marginLeft: "5px"}}
                            onClick={() => this.setState({displayBy: "Roles"})}
                            className="roles"
                        >
                            {data.buttonOrderByRoles}
                        </Button>
                    </div>
                    <div>
                        <div>
                            {this.state.userData && this.state.displayBy === "Users" &&
                            <div>
                                <input type="text" id="myInput" onKeyUp={() => this.search()} placeholder={data.search} />
                                <Table responsive striped bordered condensed hover id="myTable">
                                    <thead>
                                    <tr className="table_title">
                                        <th>#</th>
                                        <th>{data.fullname}</th>
                                        <th>{data.username}</th>
                                        <th>{data.email}</th>
                                        <th>{data.roles}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.state.userData.map((user, index) =>
                                        <tr key={index} onClick={() => this.togglePaneDialog(true, user)} className="pointer">
                                            <td>{index + 1}</td>
                                            <td>{user.fullname}</td>
                                            <td>{user.login}</td>
                                            <td>{user.email}</td>
                                            <td>{user.roles.map(role => `${role.name} `)}</td>
                                        </tr>
                                    )}
                                    </tbody>
                                </Table>
                            </div>
                            }
                        </div>
                        <div>
                            {this.state.roleData !== null && this.state.displayBy === "Roles" &&
                            this.state.roleData.map((role, index) => {
                                return <CollapsableSetting
                                    collapsed={this.state.index !== index}
                                    onCollapseChange={() => this.state.index !== index ? this.setState({index}) : this.setState({index: -1})}
                                    key={index}
                                    title={role.name}
                                    collapseContent={
                                        <Table striped bordered condensed hover>
                                            <thead>
                                            <tr className="table_title">
                                                <th>{data.fullname}</th>
                                                <th>{data.username}</th>
                                                <th>{data.email}</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {this.state.userData !== null &&
                                                this.state.userData.map(user =>
                                                    user.roles.map((userRole, index) => {
                                                        return (userRole.name === role.name &&
                                                        <tr key={index}
                                                            onClick={() => this.togglePaneDialog(true, user)}
                                                            className="pointer">
                                                            <td>{user.fullname}</td>
                                                            <td>{user.login}</td>
                                                            <td>{user.email}</td>
                                                        </tr>)
                                                    }
                                            ))}
                                            </tbody>
                                        </Table>
                                    }
                                />})
                            }
                        </div>
                    </div>
                    <UpdateUserPaneDialog
                        isOpen={this.state.toggledPane}
                        user={this.state.toView}
                        roles={this.state.roleData}
                        status={this.state.statusData}
                        onClose={() => this.togglePaneDialog(false)}
                    />
                    <CreateUserPaneDialog
                        isOpen={this.state.toggledCreatePane}
                        roles={this.state.roleData}
                        onClose={() => this.toggleCreatePaneDialog(false)}
                    />
                </WhitePanel>
                <div className="home-img" onClick={() => this.props.history.push("/administration")}>
                    <img src={require(`../../${data.homeButton}`)} alt="home-img"/>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const {user} = state.auth;
    return {
        user,
    };
}

export default connect(mapStateToProps)(UserList);