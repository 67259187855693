import React from "react";
import { Button } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import "./RoomLink.css";
import TextContent from "../../text-content/TextContent";
import RoomShareDialog from "../room-share/RoomShareDialog";
import {connect} from "react-redux";

const { room } = TextContent(localStorage.getItem("language"))
const data = {
    Enter: room.Enter,
    Share: room.Share,
    Room: room.Room,
    Validity: room.Validity,
    Hours: room.Hours
}

class RoomLink extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            user: props.user,
            room: props.room
        }
    }

    togglePaneDialog(open) {
        this.setState({
            toggledPane: open
        });
    }

    handleJoinRoom = () => {
        this.props.history.push("/room/" + this.state.room.code);
        localStorage.removeItem("room");
    };

    render() {
        let roomDuration = 0;
        this.props.settings.forEach(setting => {
            if(setting.name === "room_duration")
            roomDuration = setting.value;
        })
        if( this.state.room != null) {
            const formatNumber = this.state.room.code.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
            return(
                <div style={{marginTop: "15px"}}>
                    <div className="room_created__text">
                            { this.state.user && this.state.user.name ?
                                <><b>{this.state.user.name}</b> <br /><br /></>
                                : null
                            }
                        {data.Room} <b><u>{formatNumber}</u></b> {data.Validity} {roomDuration} {data.Hours}.
                    </div>

                    <div>
                        <Button onClick={() => this.togglePaneDialog(true)} style={{float: "left"}}>
                            {data.Share}
                        </Button>
                    </div>

                    <div>
                        <Button onClick={this.handleJoinRoom} style={{float: "right"}}>
                            {data.Enter}
                        </Button>
                    </div>
                    <RoomShareDialog
                        isOpen={this.state.toggledPane}
                        code={formatNumber}
                        onClose={() => this.togglePaneDialog(false)}
                    />
                </div>
            )
        }else return null;
    }
}

function mapStateToProps(state) {
    const {settings} = state.settings;
    return {
        settings
    };
}

export default withRouter(connect(mapStateToProps)(RoomLink));