import React, {Component} from "react";
import PropTypes from 'prop-types';

/**
 * Contains the tab contents.
 * Require corresponding TabsController.
 */
export default class TabsContent extends Component {



    render() {
        const activeTab = this.props.activeTab;
        const tabs = this.props.tabs;
        return (
            tabs && tabs.map((tab, index) => (
                <div
                    key={index}
                    role="tabpanel"
                    hidden={activeTab !== index}
                    id={`scrollable-force-tabpanel-${index}`}
                    aria-labelledby={`scrollable-force-tab-${index}`}
                >
                    {activeTab === index && (
                        tab
                    )}
                </div>
            ))
        );
    }

}

TabsContent.propTypes = {
    activeTab: PropTypes.number,
    tabs: PropTypes.arrayOf(PropTypes.node)
}
