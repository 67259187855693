import React, {Component} from "react";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import PropTypes from 'prop-types';

export default class ComplexCard extends Component {



    render() {
        const cardClassName = this.props.cardClassName;
        const header = {
            avatar: this.props.headerAvatar,
            action: this.props.headerAction,
            title: this.props.headerTitle,
            subtitle: this.props.headerSubtitle,
            className: this.props.headerClassName
        }
        const hasHeader = header.className || header.avatar || header.action || header.title || header.subtitle;

        const media = {
            url: this.props.mediaImageUrl,
            title: this.props.mediaTitle,
            className: this.props.mediaClassName
        }
        const hasMedia = media.url;

        const content = this.props.content;
        const contentClassName = this.props.contentClassName;

        const action = this.props.action;
        const actionClassName = this.props.actionClassName;

        return (
            <Card className={cardClassName}>
                {hasHeader &&
                <CardHeader
                    avatar={header.avatar}
                    action={header.action}
                    title={header.title}
                    subheader={header.subtitle}
                    className={header.className}
                />
                }
                {hasMedia &&
                <CardMedia
                    image={media.url}
                    title={media.title}
                    className={media.className}
                />
                }
                {content &&
                <CardContent className={contentClassName}>
                    {content}
                </CardContent>
                }
                {action &&
                <CardActions disableSpacing className={actionClassName}>
                    {action}
                </CardActions>
                }
            </Card>
        );
    }
}

ComplexCard.propTypes = {
    // Card global
    cardClassName: PropTypes.string,
    // Card header
    headerAvatar: PropTypes.node,
    headerAction: PropTypes.node,
    headerTitle: PropTypes.any,
    headerSubtitle: PropTypes.any,
    headerClassName: PropTypes.string,
    // Card media
    mediaImageUrl: PropTypes.string,
    mediaTitle: PropTypes.string,
    mediaClassName: PropTypes.string,
    // Card content
    content: PropTypes.node,
    contentClassName: PropTypes.string,
    // Card action
    action: PropTypes.node,
    actionClassName: PropTypes.string
}
