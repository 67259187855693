import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

class mailService {
    sendMailForInvitationCalendar(type, code, fullname,mailForHost, emails, startDate, endDate) {
        return axios
            .post(API_URL + "mail/invite/" + type, {
                code,
                host: fullname,
                hostMail: mailForHost,
                mailAddresses: emails,
                startDate,
                endDate
            }).then((response) => {
                return response.data;
            });
    }


    sendMailForInvitation(type, code, fullname, emails, startDate, endDate) {
        return axios
            .post(API_URL + "mail/invite/" + type, {
                code,
                host: fullname,
                mailAddresses: emails,
                startDate,
                endDate
            }).then((response) => {
                return response.data;
            });
    }



    getMailContent(currentUser) {
        return axios
            .get(API_URL + "mail/content", {
                headers: {'Authorization': 'Bearer ' + currentUser.accessToken}
            }).then((response) => {
                return response.data;
            });
    }
}

export default new mailService();
