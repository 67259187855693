import React, {Component} from "react";
import PropTypes from "prop-types";
import {createUserHolidays, deleteUserHolidays} from "../../../../services/holidays/holidaysService";
import moment from "moment";
import {Button, Checkbox, Col, Row, Tab, Table, Tabs} from "react-bootstrap";
import ConfirmationDialog from "../../../../nsi-lib/components/modals/ConfirmationDialog";
import AddHolidaysDatesPaneDialog from "../dialog/AddHolidaysDatesPaneDialog";
import TextContent from "../../../text-content/TextContent";

import "../../Doctor.css";

const {resources, doctor} = TextContent(localStorage.getItem("language"));
const data = {
    image: resources.doctor.holidayAgenda,
    title: doctor.holiday.title,
    tab1: doctor.holiday.tab1,
    tab2: doctor.holiday.tab2,
    add: doctor.holiday.holidaysCreate.add,
    deleteTitle: doctor.holiday.holidaysCreate.delete.title,
    deleteCancel: doctor.holiday.holidaysCreate.delete.cancel,
    deleteValidate: doctor.holiday.holidaysCreate.delete.validate,
    leaves: {
        title: doctor.holiday.leaves.title,
        morning: doctor.holiday.leaves.morning,
        afternoon: doctor.holiday.leaves.afternoon,
        monday: doctor.holiday.leaves.weekDays.monday,
        tuesday: doctor.holiday.leaves.weekDays.tuesday,
        wednesday: doctor.holiday.leaves.weekDays.wednesday,
        thursday: doctor.holiday.leaves.weekDays.thursday,
        friday: doctor.holiday.leaves.weekDays.friday,
        saturday: doctor.holiday.leaves.weekDays.saturday,
        sunday: doctor.holiday.leaves.weekDays.sunday
    },
    submit: doctor.holiday.submit,
    format: doctor.dateFormat
}

export default class CreateHolidaysPaneContent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isRecurrent: false,
            day: "Monday",
            dates: [],
            selectedTab: 1,
            MonMor: false,
            MonAft: false,
            TueMor: false,
            TueAft: false,
            WedMor: false,
            WedAft: false,
            ThuMor: false,
            ThuAft: false,
            FriMor: false,
            FriAft: false,
            SatMor: false,
            SatAft: false,
            SunMor: false,
            SunAft: false,
            toDelete: null,
            toggledAddHolidaysDateDialog: false,
            toggledDeleteDialog: false,
            deleteConfirmationDialog: {
                title: data.deleteTitle,
                cancel: data.deleteCancel,
                validate: data.deleteValidate
            }
        }

        this.removeHoliday = this.removeHoliday.bind(this);
        this.deleteElement = this.deleteElement.bind(this);
        this.deleteHoliday = this.deleteHoliday.bind(this);
        this.setDates = this.setDates.bind(this);
        this.handleChangeCheckboxes = this.handleChangeCheckboxes.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

    }


    toggleAddHolidaysDatesDialog(open) {
        this.setState({
            toggledAddHolidaysDateDialog: open
        });
    }

    toggleDeleteDialog(open) {
        this.setState({
            toggledDeleteDialog: open
        });
    }

    removeHoliday(index) {
        const holidays = [...this.state.dates];
        holidays.splice(index, 1);

        this.setState({dates: holidays});
    }

    deleteElement(content) {
        this.setState({
            toDelete: content.raw.holidayId
        })
        this.toggleDeleteDialog(true);
    }

    deleteHoliday(toDelete) {
        if (toDelete && this.state.toDelete) {
            const index = this.props.content.forEach(function(holiday) {
                if(holiday.raw.holidayId) return holiday.raw.holidayId;
            }).indexOf(this.state.toDelete);

            this.props.content.splice(index, 1);
            deleteUserHolidays(this.props.currentUser, this.state.toDelete).then(() => {
                this.toggleDeleteDialog(false);
            });
        } else {
            this.toggleDeleteDialog(false);
        }
    }

    setDates(dates) {
        this.setState({dates: [...this.state.dates, dates]});
    }

    handleChangeCheckboxes(e) {
        this.setState({
            [e.target.name]: e.target.checked
        });
    }

    handleSelect(tab) {
        this.setState({selectedTab: tab});
    }

    handleSubmit(e) {
        e.preventDefault();

        const isrecurrent = this.state.selectedTab !== 1;

        const starting_date = !isrecurrent ? this.state.startDate : null;
        const end_date = !isrecurrent ? this.state.endDate : null;

        if (!isrecurrent) {
            const length = this.state.dates.length;

            if (length > 0) {
                this.state.dates.forEach((holiday, index) => {
                    createUserHolidays(this.props.currentUser, [{isrecurrent, starting_date: holiday.startDate, end_date: holiday.endDate, day: null, morning: null, afternoon: null}])
                        .then(() => {
                            if(length === (index + 1)) window.location.reload();
                        });
                });
            }
            else {
                window.location.reload();
            }
        } else if (isrecurrent) {
            const holidays = [{
                    isrecurrent,
                    starting_date,
                    end_date,
                    day: "Monday",
                    morning: !!this.state.MonMor,
                    afternoon: !!this.state.MonAft
                },
                {
                    isrecurrent,
                    starting_date,
                    end_date,
                    day: "Tuesday",
                    morning: !!this.state.TueMor,
                    afternoon: !!this.state.TueAft
                },
                {
                    isrecurrent,
                    starting_date,
                    end_date,
                    day: "Wednesday",
                    morning: !!this.state.WedMor,
                    afternoon: !!this.state.WedAft
                },
                {
                    isrecurrent,
                    starting_date,
                    end_date,
                    day: "Thursday",
                    morning: !!this.state.ThuMor,
                    afternoon: !!this.state.ThuAft
                },
                {
                    isrecurrent,
                    starting_date,
                    end_date,
                    day: "Friday",
                    morning: !!this.state.FriMor,
                    afternoon: !!this.state.FriAft
                },
                {
                    isrecurrent,
                    starting_date,
                    end_date,
                    day: "Saturday",
                    morning: !!this.state.SatMor,
                    afternoon: !!this.state.SatAft
                },
                {
                    isrecurrent,
                    starting_date,
                    end_date,
                    day: "Sunday",
                    morning: !!this.state.SunMor,
                    afternoon: !!this.state.SunAft
            }];

            createUserHolidays(this.props.currentUser, holidays)
                .then(() => {
                    window.location.reload();
                });
        }
    }

    componentDidMount() {
        this.props.content.forEach(content => {
            if (content.raw.isRecurrent) {
                if (content.raw.day === "monday") {
                    this.setState({
                        MonMor: content.raw.morning,
                        MonAft: content.raw.afternoon
                    });
                } else if (content.raw.day === "tuesday") {
                    this.setState({
                        TueMor: content.raw.morning,
                        TueAft: content.raw.afternoon
                    });
                } else if (content.raw.day === "wednesday") {
                    this.setState({
                        WedMor: content.raw.morning,
                        WedAft: content.raw.afternoon
                    });
                } else if (content.raw.day === "thursday") {
                    this.setState({
                        ThuMor: content.raw.morning,
                        ThuAft: content.raw.afternoon
                    });
                } else if (content.raw.day === "friday") {
                    this.setState({
                        FriMor: content.raw.morning,
                        FriAft: content.raw.afternoon
                    });
                } else if (content.raw.day === "saturday") {
                    this.setState({
                        SatMor: content.raw.morning,
                        SatAft: content.raw.afternoon
                    });
                } else if (content.raw.day === "sunday") {
                    this.setState({
                        SunMor: content.raw.morning,
                        SunAft: content.raw.afternoon
                    });
                }
            }
        });
    }

    render() {
        return (
            <div className="CreateHolidaysPaneContent">
                <Row>
                    <Col lg={4} md={4} sm={12} xs={12} className="calendar-img">
                        <Col lg={12} md={12} sm={6} xs={6}>
                            <img src={require(`../../../../${data.image}`)} className="holidays-icon"
                                 alt="holidays-img" width={100}/>
                        </Col>
                    </Col>
                    <Col lg={8} md={8} sm={12} xs={12}>
                        <form onSubmit={this.handleSubmit} id="Holidays">
                            <Tabs id="controlled-tab-example" onSelect={this.handleSelect}>
                                <Tab eventKey={1} title={data.tab1}>
                                    <div>
                                        {data.title} :
                                        <ul>
                                            {this.props.content.forEach((content, index) => {
                                                if (!content.raw.isRecurrent) {
                                                    if (moment(content.start).isBetween(moment(new Date()).subtract(1, 'months'), moment(new Date()).add(1, 'months'))) {
                                                        return (
                                                            <li key={index}>
                                                                <label>
                                                                    {moment(content.start).format(data.format)} - {moment(content.end).format(data.format)}
                                                                    <i className="fa fa-trash-o trash-icon" aria-hidden="true" onClick={() => this.deleteElement(content)}/>
                                                                </label>
                                                            </li>
                                                        );
                                                    }
                                                }
                                            })}
                                            {this.state.dates.length > 0 && this.state.dates.map((dates, index) =>
                                                <li key={index}>
                                                    <label>
                                                        {moment(dates.startDate).format(data.format)} - {moment(dates.endDate).format(data.format)} (Nouveau)
                                                        <i className="fa fa-times times-icon" aria-hidden="true" onClick={() => this.removeHoliday(index)}/>
                                                    </label>
                                                </li>
                                            )}
                                        </ul>
                                    </div>
                                    <Button
                                        variant={"outlined"}
                                        className="border-success add-holiday"
                                        bsSize="large" type="button"
                                        onClick={() => this.toggleAddHolidaysDatesDialog(true)}>
                                        <b className="add-holiday-plus">{data.add} +</b>
                                    </Button>
                                </Tab>
                                <Tab eventKey={2} title={data.tab2}>
                                    {data.leaves.title} :
                                    <Table className="absence" striped bordered condensed hover>
                                        <thead>
                                            <tr>
                                                <th/>
                                                <th style={{textAlign: "center"}}>{data.leaves.morning}</th>
                                                <th style={{textAlign: "center"}}>{data.leaves.afternoon}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                               <td>{data.leaves.monday}</td>
                                               <td>
                                                   <Checkbox
                                                       className="checkbox"
                                                       checked={this.state.MonMor}
                                                       onChange={this.handleChangeCheckboxes}
                                                       name="MonMor"/>
                                               </td>
                                               <td>
                                                   <Checkbox
                                                   className="checkbox"
                                                   checked={this.state.MonAft}
                                                   onChange={this.handleChangeCheckboxes}
                                                   name="MonAft"/>
                                               </td>
                                            </tr>
                                            <tr>
                                                <td>{data.leaves.tuesday}</td>
                                                <td>
                                                    <Checkbox
                                                        className="checkbox"
                                                        checked={this.state.TueMor}
                                                        onChange={this.handleChangeCheckboxes}
                                                        name="TueMor"/>
                                                </td>
                                                <td>
                                                    <Checkbox
                                                        className="checkbox"
                                                        checked={this.state.TueAft}
                                                        onChange={this.handleChangeCheckboxes}
                                                        name="TueAft"/>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>{data.leaves.wednesday}</td>
                                                <td>
                                                    <Checkbox
                                                        className="checkbox"
                                                        checked={this.state.WedMor}
                                                        onChange={this.handleChangeCheckboxes}
                                                        name="WedMor"/>
                                                </td>
                                                <td>
                                                    <Checkbox
                                                        className="checkbox"
                                                        checked={this.state.WedAft}
                                                        onChange={this.handleChangeCheckboxes}
                                                        name="WedAft"/>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>{data.leaves.thursday}</td>
                                                <td>
                                                    <Checkbox
                                                        className="checkbox"
                                                        checked={this.state.ThuMor}
                                                        onChange={this.handleChangeCheckboxes}
                                                        name="ThuMor"/>
                                                </td>
                                                <td>
                                                    <Checkbox
                                                        className="checkbox"
                                                        checked={this.state.ThuAft}
                                                        onChange={this.handleChangeCheckboxes}
                                                        name="ThuAft"/>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>{data.leaves.friday}</td>
                                                <td>
                                                    <Checkbox
                                                        className="checkbox"
                                                        checked={this.state.FriMor}
                                                        onChange={this.handleChangeCheckboxes}
                                                        name="FriMor"/>
                                                </td>
                                                <td>
                                                    <Checkbox
                                                        className="checkbox"
                                                        checked={this.state.FriAft}
                                                        onChange={this.handleChangeCheckboxes}
                                                        name="FriAft"/>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>{data.leaves.saturday}</td>
                                                <td>
                                                    <Checkbox
                                                        className="checkbox"
                                                        checked={this.state.SatMor}
                                                        onChange={this.handleChangeCheckboxes}
                                                        name="SatMor"/>
                                                </td>
                                                <td>
                                                    <Checkbox
                                                        className="checkbox"
                                                        checked={this.state.SatAft}
                                                        onChange={this.handleChangeCheckboxes}
                                                        name="SatAft"/>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>{data.leaves.sunday}</td>
                                                <td>
                                                    <Checkbox
                                                    className="checkbox"
                                                    checked={this.state.SunMor}
                                                    onChange={this.handleChangeCheckboxes}
                                                    name="SunMor"/>
                                                </td>
                                                <td>
                                                    <Checkbox
                                                        className="checkbox"
                                                        checked={this.state.SunAft}
                                                        onChange={this.handleChangeCheckboxes}
                                                        name="SunAft"/>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </Tab>
                            </Tabs>
                        </form>
                    </Col>
                </Row>
                <div className="validate-holidays">
                    <Button bsSize="large" type="submit" form="Holidays">
                        {data.submit}
                    </Button>
                </div>
                <ConfirmationDialog
                    isOpen={this.state.toggledDeleteDialog}
                    handleClose={this.deleteHoliday}
                    texts={this.state.deleteConfirmationDialog}
                />
                <AddHolidaysDatesPaneDialog
                    isOpen={this.state.toggledAddHolidaysDateDialog}
                    onClose={() => this.toggleAddHolidaysDatesDialog(false)}
                    dates={this.setDates}
                />
            </div>
        );
    }
}

CreateHolidaysPaneContent.propTypes = {
    content: PropTypes.any,
    currentUser: PropTypes.any
}