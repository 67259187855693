import React from 'react';
import {Route} from "react-router-dom";
import UnauthorizedPage from "../errors/UnauthorizedPage";

const GuardedRoute = ({ component: Component, authorized,  ...rest }) => (
    <Route {...rest} render={(props) => {
        if (authorized) {
            return <Component {...props} />;
        }

        return <UnauthorizedPage/>
    }}
    />
);

export default GuardedRoute;
