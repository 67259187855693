import React, {Component} from "react";
import PropTypes from "prop-types";
import CallableDialog from "../../../../nsi-lib/components/modals/CallableDialog";
import ScheduleDetailsPaneContent from "../content/ScheduleDetailsPaneContent";
import TextContent from "../../../text-content/TextContent";

import "../../Doctor.css";
import {Col} from "react-bootstrap";

const {doctor} = TextContent(localStorage.getItem("language"));
const data = {
    external: doctor.scheduleDetails.dialog.external,
    internal: doctor.scheduleDetails.dialog.internal,
    title: doctor.scheduleDetails.dialog.title
}

export default class ScheduleDetailsPaneDialog extends Component {
    constructor(props) {
        super(props);

        this.closeDialog = this.closeDialog.bind(this);
    }

    closeDialog() {
        if (this.props.onClose) {
            this.props.onClose();
        }
    }

    render() {
        const {isOpen, currentUser, content} = this.props;

        if (content && !content.schedule.raw.isRecurrent) {
            const participants = content.schedule.raw.participants;
            const leaders = content.schedule.raw.leaders;

            return (
                <CallableDialog
                    dialogClassName="ScheduleDetailsPaneContent"
                    isOpen={isOpen}
                    handleClose={this.closeDialog}
                    fullwidth
                    content={
                        <div>

                            <h2 className="title_recap">{data.title}</h2>

                            <div>
                                <Col lg={4} md={4} sm={12} xs={12} className="calendar-img">
                                        <img src={require("../../../../static/images/meeting_doctor.png")} className="holidays-icon"
                                             alt="meeting_doctor" width={100}/>
                                </Col>

                            <u><h3>{content && content.schedule.title}</h3></u>
                            {participants && participants.length > 0 &&
                                <h4>{data.external} :
                                    {participants.map(participant => <div>- {participant.name} <br/></div>)}
                                </h4>
                            }
                            {leaders && leaders.length > 0 &&
                                <h4>{data.internal} :
                                    {leaders.map(leader => <div>- {leader.fullname} <br /> </div>)}
                                </h4>
                            }
                            <button className="schedule-close-button" onClick={() => this.closeDialog()}>
                                <i className="fa fa-times fa-2x" />
                            </button>
                            <ScheduleDetailsPaneContent
                                content={content}
                                currentUser={currentUser}
                            />

                            </div>
                        </div>
                    }
                />
            );
        } else {
            return null;
        }
    }
}

ScheduleDetailsPaneDialog.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    currentUser: PropTypes.any,
    content: PropTypes.any
}