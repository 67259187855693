export const ERROR_MODAL_REGISTER = 'ERROR_MODAL_REGISTER';
export const ERROR_MODAL_CLEAR = 'ERROR_MODAL_CLEAR';

/**
 * Register an error in the redux errorModal state.
 * @param request
 * @param apiError
 * @param details
 * @param ignoreDefault
 * @return {{request: *, details: *, type: string, error: *, ignoreDefault: boolean}}
 */
export const registerError = (request, apiError, details, ignoreDefault = false) => {
    return {
        type: ERROR_MODAL_REGISTER,
        error: apiError,
        request: request,
        details: details,
        ignoreDefault: ignoreDefault
    }
}

/**
 * CLear all errors in the redux errorModal state.
 * @return {{type: string}}
 */
export const clearErrors = () => {
    return {
        type: ERROR_MODAL_CLEAR
    }
}
