import {
    GET_SETTINGS_FAIL,
    GET_SETTINGS_SUCCESS,
    UPDATE_SETTINGS_SUCCESS,
    UPDATE_SETTINGS_FAIL
} from "../actions/types";

const settings = JSON.parse(localStorage.getItem("settings"));

const initialState = settings
    ? {settings}
    : {settings: []};

export default function (state = initialState, action) {
    const {type, payload} = action;

    switch (type) {
        case GET_SETTINGS_SUCCESS:
            return {
                ...state,
                settings: payload.settings
            };
        case GET_SETTINGS_FAIL:
            return {
                ...state,
                settings: null
            };
        case UPDATE_SETTINGS_SUCCESS:
            return {
                ...state,
            };
        case UPDATE_SETTINGS_FAIL:
            return {
                ...state,
                settings: null
            };
        default:
            return state;
    }
}