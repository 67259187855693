import {MODALS_UPDATE} from "../actions/modalAction";

const initialState = {
    modalStates: {}
}

export default function (state = initialState, action) {

    switch (action.type) {

        case MODALS_UPDATE:
            return {
                ...state,
                modalStates: {
                    ...state.modalStates,
                    [action.payload.id]: action.payload.value
                }
            }

        default:
            return state;
    }

}
