import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./containers/home/Home";
import NotFound from "./containers/not-found/NotFound";
import Help from "./containers/help/Help";
import Contact from "./containers/contact/Contact";
import Login from "./components/authentication/login";
import Admin from "./containers/admin/Admin";
import RoomAdmin from "./containers/room-admin/RoomAdmin";
import JoinRoom from "./containers/join-room/JoinRoom";
import Room from "./containers/room/Room";
import UserList from "./components/user/UserList";
import Settings from "./components/settings/Settings"
import Profile from "./components/profile/Profile";
import Secretariat from "./components/secretariat/Secretariat";
import Doctor from "./components/doctor/Doctor";
import Dashboard from "./components/dashboard/Dashboard"
import ForgetPassword from "./components/authentication/forgetPassword";
import ResetPassword from "./components/authentication/resetPassword";

export default function Routes() {
    return (
        <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/createRoom" exact component={RoomAdmin} />
            <Route path="/joinRoom" exact component={JoinRoom} />
            <Route path="/room/:roomId" component={Room} />
            <Route path="/help" exact component={Help} />
            <Route path="/contact" exact component={Contact} />
            <Route path="/forget-password" component={ForgetPassword}/>
            <Route path="/confirm-reset" component={ResetPassword}/>
            <Route exact path="/login" component={Login} />
            <Route exact path="/administration" component={Admin} />
            <Route exact path="/dashboard" component={Dashboard} />
            <Route exact path="/users" component={UserList} />
            <Route exact path="/settings" component={Settings} />
            <Route exact path="/profile" component={Profile} />
            <Route exact path="/secretariat" component={Secretariat} />
            <Route exact path="/doctor" component={Doctor} />

            { /* Finally, catch all unmatched routes */ }
            <Route component={NotFound} />
        </Switch>
    );
}
