import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export function sendSMS(type, code, fullname, phones, startDate, endDate) {
    return new Promise((resolve, reject) => {
        axios.post(API_URL + "sms/invite/" + type, {
            code,
            host: fullname,
            number: phones,
            startDate,
            endDate
        })
            .then(value => { resolve(value) })
            .catch(reason => { reject(reason) });
    });
}

export function getSMSContent(currentUser) {
    return new Promise((resolve, reject) => {
        axios.get(API_URL + "sms/content", {
            headers: {'Authorization': 'Bearer ' + currentUser.accessToken}
        })
            .then(value => { resolve(value) })
            .catch(reason => { reject(reason) });
    });
}