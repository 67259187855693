import React, {Component} from "react";
import PropTypes from 'prop-types';

export default class ScrollableContainer extends Component {



    render() {
        const scroll = this.props.scrollDirection ? this.props.scrollDirection : 'none';
        const scrollX = (scroll === 'horizontal' || scroll === 'both');
        const scrollY = (scroll === 'vertical' || scroll === 'both');

        return (
            <div
                className={this.props.className}
                style={{
                    overflowX: scrollX ? 'auto' : 'hidden',
                    overflowY: scrollY ? 'auto' : 'hidden',
                    height: this.props.height ? this.props.height : '',
                    width: this.props.width ? this.props.width : '',
                    maxHeight: this.props.maxHeight ? this.props.maxHeight : '',
                    maxWidth: this.props.maxWidth ? this.props.maxWidth : '',
                }}
            >
                {this.props.children}
            </div>
        );
    }

}

ScrollableContainer.propTypes = {
    children: PropTypes.node.isRequired,
    scrollDirection: PropTypes.oneOf(['horizontal', 'vertical', 'both']),
    maxHeight: PropTypes.string,
    maxWidth: PropTypes.string,
    height: PropTypes.string,
    width: PropTypes.string,
    className: PropTypes.string
}
