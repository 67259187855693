import React, {Component} from "react";
import PropTypes from 'prop-types';
import CollapsableSetting from "./CollapsableSetting";

export default class CollapsableSettingArray extends Component {

    constructor(props) {
        super(props);

        this.state = {
            displayedElements: []
        }

        this.displayElementContent = this.displayElementContent.bind(this);
        this.hideElementContent = this.hideElementContent.bind(this);
        this.isContentDisplayed = this.isContentDisplayed.bind(this);
        this.hideAllElements = this.hideAllElements.bind(this);
    }

    hideAllElements() {
        this.setState({
            displayedElements: []
        });
    }

    displayElementContent(index) {
        const displayMode = this.props.displayMode ? this.props.displayMode : 'single';
        if (displayMode === "single") {
            this.setState({
                displayedElements: [index]
            });
        } else {
            this.setState(state => {
                const newElements = [...state.displayedElements];

                newElements.push(index);

                return {
                    ...state,
                    displayedElements: newElements
                }
            });
        }
    }

    hideElementContent(index) {
        const displayMode = this.props.displayMode ? this.props.displayMode : 'single';
        if (displayMode === "single") {
            this.setState({
                displayedElements: []
            });
        } else {
            this.setState(state => {
                const newElements = [...state.displayedElements];

                const elementIndex = newElements.indexOf(index);
                newElements.splice(elementIndex, 1);

                return {
                    ...state,
                    displayedElements: newElements
                }
            });
        }
    }

    isContentDisplayed(index) {
        if (index !== undefined && index >= 0) {
            return this.state.displayedElements.includes(index);
        }
    }

    render() {
        const elements = this.props.elements;
        if (!elements) return null;

        return (
            <div className="row">
                <div className="col">
                    {elements.map((value, index) => (
                        <div className="row" key={index}>
                            <div className="col">
                                <CollapsableSetting
                                    title={value.title}
                                    collapseContent={value.content}
                                    className={this.props.className}
                                    collapseClassName={this.props.collapseClassName}
                                    settingClassName={this.props.settingClassName}
                                    size={this.props.size}
                                    collapsed={!this.isContentDisplayed(index)}
                                    onCollapseChange={() => {
                                        if (this.isContentDisplayed(index)) {
                                            this.hideElementContent(index);
                                        } else {
                                            this.displayElementContent(index);
                                        }
                                    }}
                                    iconActionClassName={this.props.iconActionClassName}
                                />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        );
    }

}

CollapsableSettingArray.propTypes = {
    elements: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string,
        content: PropTypes.node
    })).isRequired,
    displayMode: PropTypes.oneOf(['single', 'multiple']),
    size: PropTypes.oneOf(['normal', 'small']),
    className: PropTypes.any,
    settingClassName: PropTypes.any,
    collapseClassName: PropTypes.any,
    iconActionClassName: PropTypes.any
}
