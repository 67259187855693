import React, {Component} from "react";
import CallableDialog from "./CallableDialog";
import {connect} from "react-redux";
import {Button} from "@material-ui/core";
import * as apiStatusCode from '../../models/ApiStatusCodes';
import {hideGlobalErrorModal} from "../../services/errorService";

class ErrorDialog extends Component {

    constructor(props) {
        super(props);
        this.handleClose = this.handleClose.bind(this);
    }

    render() {
        const isOpen = this.props.isOpen ? this.props.isOpen : false;

        // Dialog content.
        const content = (
            <div className="container">
                <div className="row">
                    <div className="col-12 text-center">
                        <h3>Oops, something wrong happens:</h3>
                    </div>
                    {this.props.errors.map((value, index) => (
                        <div key={index} className="row pt-2 mt-2 border-top border-light">
                            {getErrorHeader(value.error)}
                            {getErrorContext(value)}
                            {getErrorDetails(value.error, value.details)}
                        </div>
                    ))}
                </div>
            </div>
        );

        // Dialog buttons.
        const actions = (
            <div className="container">
                <div className="row">
                    <div className="col">
                        <Button type={"button"} onClick={this.handleClose} className="bg-warning">Close</Button>
                    </div>
                </div>
            </div>
        );

        return (
            <CallableDialog
                content={content}
                actions={actions}
                isOpen={isOpen}
                handleClose={this.handleClose}
            />
        );
    }

    handleClose(ignored) {
        hideGlobalErrorModal();
    }

}

/**
 * Get error context : generic text about the error (like Bad request, etc...).
 * @param apiError
 * @return {JSX.Element|null}
 */
function getErrorHeader(apiError) {
    if (apiError) {
        // 400
        if (apiError.httpStatusCode >= 400 && apiError.httpStatusCode < 500) {
            return (
                <div className="col-12">
                    <h5>Your request wasn't correct.</h5>
                </div>
            );
        }
        // 500
        else if (apiError.httpStatusCode >= 500) {
            return (
                <div className="col-12">
                    <h5>Server encountered an error.</h5>
                </div>
            )
        }
    }
    return null;
}

/**
 * Get the custom error title (like entity already exists).
 * Can be disabled by setting ignoreDefault to true in errorService.registerGlobalError.
 * @return {JSX.Element|null}
 * @param error
 */
function getErrorContext(error) {
    const apiError = error.error;
    if (apiError && apiError.apiStatusCode && !error.ignoreDefault) {
        switch (apiError.apiStatusCode) {
            case apiStatusCode.ENTITY_ALREADY_EXISTS:
                return (
                    <div className="col-12">
                        <p>Your request lead to conflict with another entity : the entity already exists.</p>
                    </div>
                );
            case apiStatusCode.ENTITY_RELATION_CONFLICT:
                return (
                    <div className="col-12">
                        <p>Your request lead to conflict with another entity(ies) : the entity have relation which
                            cannot be deleted.</p>
                    </div>
                );
            case apiStatusCode.INVALID_FILE_FORMAT:
                return (
                    <div className="col-12">
                        <p>The specified file have an incorrect format.</p>
                    </div>
                );
            case apiStatusCode.INVALID_REQUEST_DATA:
                return (
                    <div className="col-12">
                        <p>Server refuse your specified information. Please retry with correct information.</p>
                    </div>
                )
            case apiStatusCode.PASSWORD_POLICY_CONFLICT:
                return (
                    <div className="col-12">
                        <p>The specified password don't match with the password policies.</p>
                    </div>
                )
            default:
                return (
                    <div className="col-12">
                        <p>No additional information about the error. Report to the stacktrace for details.</p>
                    </div>
                );
        }
    }
    return null;
}

/**
 * Extract custom error details if details prop was present.
 * @param apiError The custom ApiError.
 * @param details The customer error details.
 * @return {null|*} Content or null.
 */
function getErrorDetails(apiError, details) {
    if (apiError && details && (apiError.apiStatusCode || apiError.httpStatusCode)) {
        for (let detail of details) {
            // Extract custom content by using custom API status code.
            if (detail.apiStatusCode) {
                if (apiError.apiStatusCode === detail.apiStatusCode) {
                    return detail.content;
                }
            }
            // Extract custom content by using standard HTTP code.
            else if (detail.httpStatusCode) {
                if (apiError.httpStatusCode === detail.httpStatusCode) {
                    return detail.content;
                }
            }
        }
    }
    return null;
}

const mapStateToProps = state => {
    return {
        errors: state.errorModal.errors
    }
}

export default connect(mapStateToProps, null)(ErrorDialog)
