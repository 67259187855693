import React, {Component} from "react";
import PropTypes from "prop-types";
import moment from "moment";
import TextContent from "../../../text-content/TextContent";

import "../../Doctor.css";

const {doctor} = TextContent(localStorage.getItem("language"));
const data = {
    at: doctor.weekSummary.at,
    noMeet : doctor.weekSummary.noMeet
}

export default class RecapDoctorPaneContent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            content: "",
            inTheWeek: false
        }


    }

    componentDidMount() {
        this.setState({
            content: this.props.content,
        });



        const date = moment(this.props.currentDate);
        const weekStart = date.clone().startOf('isoWeek');
        const weekEnd = date.clone().endOf('isoWeek');

        let contentData = this.props.content
        if (this.props.content.length> 0){
        for (let i = 0; i < this.props.content.length; i++) {
            const inTheWeek = (moment(contentData[i].starting_date).isBetween(weekStart, weekEnd));
             if (inTheWeek){
                 this.setState({
                     inTheWeek:true
                 })
             }
         }
    }
    }

    render() {
        


        const contents = this.state.content;
        const currentDate = this.props.currentDate;
        const date = moment(currentDate);
        const weekStart = date.clone().startOf('isoWeek');
        const weekEnd = date.clone().endOf('isoWeek');
        if (this.state.inTheWeek){

            return (
            <div className="RecapDoctorPaneContent" id="recapDoctor">
                <h3> {/* eslint-disable-next-line*/}
                    {contents && contents.map(content =>
                        {
                            const inTheWeek = (moment(content.starting_date).isBetween(weekStart, weekEnd));
                            if (inTheWeek){
                                return (
                                    <div key={content.details.detailsid}>
                                            <div>
                                                -{data.at} {moment(content.starting_date).format('YYYY-MM-DD HH:mm')} :&nbsp;
                                                <b>{content.details.title} </b>
                                            </div>
                                    </div>
                                );
                            }
                        }
                    )}
                </h3>
            </div>
        )}
        else {
            return (
                <div className="RecapDoctorPaneContent">
                   <h4>{data.noMeet}</h4>
                </div>
            )
        }
    }
}

RecapDoctorPaneContent.propTypes = {
    content: PropTypes.any,
    currentDate: PropTypes.any,
}