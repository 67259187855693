import React, {Component} from "react";
import PropTypes from "prop-types";
import moment from "moment";
import {Button, Col, FormControl, FormGroup, Row, Table} from "react-bootstrap";
import { MuiPickersUtilsProvider, DateTimePicker } from "@material-ui/pickers";
import MomentUtils from '@date-io/moment';
import AddExternalPaneDialog from "../dialog/AddExternalPaneDialogDoctor";
import AddInternalPaneDialog from "../dialog/AddInternalPaneDialogDoctor";
import TextContent from "../../../text-content/TextContent";

import '../../../secretariat/css/Secretariat.css';
import {deleteRoom} from "../../../../services/room/roomService";
import mailService from "../../../../services/mail/mailService";
import {sendSMS} from "../../../../services/sms/smsService";

const {resources, secretariat} = TextContent(localStorage.getItem("language"));
const data = {
    icon: resources.secretariat.meetingIcon,
    title: secretariat.meetingCreation.titlePlaceholder,
    description: secretariat.meetingCreation.description,
    validate: secretariat.meetingCreation.validate,
    internal: secretariat.meetingCreation.participantsArray.internal,
    external: secretariat.meetingCreation.participantsArray.external,
    name: secretariat.meetingCreation.participantsArray.name,
    email: secretariat.meetingCreation.participantsArray.email,
    phone: secretariat.meetingCreation.participantsArray.phone,
    format: secretariat.dateFormat,
    delete: secretariat.meetingCreation.delete,
    cancel: secretariat.meetingCreation.cancel,
    askDelete: secretariat.meetingCreation.askDelete,
    join: secretariat.meetingCreation.join
}

export default class MeetingPaneDoctorContent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            title: "",
            description: "",
            start: "",
            end: "",
            code: "",
            participants: {
                internal: [],
                external: []
            },
            oldParticipants: {
                internal: [],
                external: []
            },
            isUpdate: false,
            toggledAddExternalPane: false,
            toggledAddInternalPane: false,
            content: "",
            index: ""
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.closeDialog = this.closeDialog.bind(this);
        this.deleteSchedule = this.deleteSchedule.bind(this);
        this.joinRoom = this.joinRoom.bind(this);
    }

    joinRoom(){
        window.location.href = "/room/" + this.props.defaultContent.raw.code;
    }
    toggleAddExternalPaneDialog(open, external, index) {
        this.setState({
            toggledAddExternalPane: open,
            content: external,
            index
        });
    }

    toggleAddInternalPaneDialog(open) {
        this.setState({
            toggledAddInternalPane: open
        });
    }

    closeDialog() {
        if (this.props.onClose) {
            this.props.onClose();
        }
    }

    deleteSchedule(schedule){
        if( window.confirm(data.askDelete)) {
            const scheduleToDelete = schedule;
            const participants = scheduleToDelete.raw.participants;
            let emails = [];
            let phones = [];
            let mailForHost = [];

            if (participants) {
                participants.internal.forEach(leader => {
                    if (leader.email) emails.push(leader.email);
                    else if (leader.phone) phones.push(leader.phone);
                    else if (leader.email) mailForHost.push(leader.email);
                });
                participants.external.forEach(participant => {
                    if (participant.email) emails.push(participant.email);

                    else if (participant.phone) phones.push(participant.phone);
                });
            }

            new Promise((resolve, reject) => {

                deleteRoom(this.props.currentUser, scheduleToDelete.id)
                    .then(() => {
                        mailService.sendMailForInvitationCalendar("meetingdelete", scheduleToDelete.raw.code, scheduleToDelete.raw.participants.internal[0].fullname,mailForHost, emails, new Date(scheduleToDelete.start), new Date(scheduleToDelete.end));
                        resolve(sendSMS("meetingdelete", scheduleToDelete.raw.code, scheduleToDelete.raw.participants.internal[0].fullname, phones, new Date(scheduleToDelete.start), new Date(scheduleToDelete.end)));
                        window.location.reload();

                    })
                    .catch(() => {
                        reject(this.setState({
                            message: data.error.delete
                        }));
                    });
            });
        }
    }

    getExternal(external) {
        this.setState({
            participants: {
                ...this.state.participants,
                internal: [...this.state.participants.internal],
                external: [...this.state.participants.external, external]
            }
        });
    }

    getInternal(internal) {
        if (!this.state.participants.internal.some(e => e.fullname === internal.fullname)) {
            this.setState({
                participants: {
                    ...this.state.participants,
                    internal: [...this.state.participants.internal, internal],
                    external: [...this.state.participants.external]
                }
            });
        }
    }

    removeInternal(internal) {
        let newInternal = this.state.participants.internal.filter(oldInternal => oldInternal.id !== internal.id)
        this.setState({
            participants: {
                ...this.state.participants,
                internal: newInternal,
                external: [...this.state.participants.external]
            }
        });
    }

    editExternal(external) {
        const modifiedExternal = this.state.participants.external.slice();
        modifiedExternal[external.index] = external;

        this.setState({
            participants: {
                ...this.state.participants,
                internal: [...this.state.participants.internal],
                external: modifiedExternal
            }
        });
    }

    deleteExternal(external) {
        const deletedExternal = [...this.state.participants.external];
        deletedExternal.splice(external.index, 1);

        this.setState({
            participants: {
                ...this.state.participants,
                internal: [...this.state.participants.internal],
                external: deletedExternal
            }
        });
    }

    participants() {
        return (
            <div className="add-people">
                <div className="peoples">
                    <div className="internal-people">
                        <Table striped bordered condensed hover>
                            <thead>
                            <tr className="table_title" style={{textAlign: "center"}}>
                                <th>{data.internal}</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>
                                    {this.props.currentUser.fullname}
                                </td>
                            </tr>
                            </tbody>
                        </Table>
                    </div>
                    <div className="external-people">
                        <Table striped bordered condensed hover>
                            <thead>
                            <tr className="table_title external" style={{textAlign: "center"}}>
                                <th>{data.external}</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>
                                    <Table className="nested-table" striped bordered condensed hover>
                                        <thead>
                                        <tr style={{textAlign: "center"}}>
                                            <th>{data.name}</th>
                                            <th>{data.email}</th>
                                            <th>{data.phone}</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {this.state.participants && this.state.participants.external.map((external, index) =>
                                            <tr key={index}
                                                onClick={() => this.toggleAddExternalPaneDialog(true, external, index)}
                                                style={{cursor: "pointer"}}>
                                                <td>
                                                    {external.name}
                                                </td>
                                                <td>
                                                    {external.email}
                                                </td>
                                                <td>
                                                    {external.phone}
                                                </td>
                                            </tr>
                                        )}
                                        </tbody>
                                    </Table>
                                </td>
                            </tr>
                            <tr>
                                <td className="button-add-external">
                                    <p style={{float: "right"}}>
                                        <i
                                            className="fa fa-plus add"
                                            onClick={() => this.toggleAddExternalPaneDialog(true)}
                                        />
                                    </p>
                                </td>
                            </tr>
                            </tbody>
                        </Table>
                    </div>
                </div>
                <AddExternalPaneDialog
                    isOpen={this.state.toggledAddExternalPane}
                    onClose={() => this.toggleAddExternalPaneDialog(false)}
                    getExternal={(external) => this.getExternal(external)}
                    editExternal={(external) => this.editExternal(external)}
                    deleteExternal={(external) => this.deleteExternal(external)}
                    content={this.state.content}
                    index={this.state.index}
                />
                <AddInternalPaneDialog
                    isOpen={this.state.toggledAddInternalPane}
                    onClose={() => this.toggleAddInternalPaneDialog(false)}
                    getInternal={(internal) => this.getInternal(internal)}
                    currentUser={this.props.currentUser}
                />
            </div>
        );
    }

    handleChange(e, name) {
        if (name) {
            this.setState({[name]: e._d});
        } else {
            this.setState({[e.target.name]: e.target.value});
        }
    }

    handleSubmit(e) {
        e.preventDefault();

        let newInternal = this.state.participants.internal;
        let newExternal = this.state.participants.external;
        const highestId = this.props.selectedDoctor && this.props.selectedDoctor.led_rooms.length > 0 ? Math.max.apply(Math, this.props.selectedDoctor.led_rooms.map(function(e){return e.roomid;})) : 0;
        const startingAddedId = (highestId === 0) ? 0 : highestId + 1;

        if (this.state.oldParticipants.internal.length > 0) {
            newInternal = newInternal.filter(({ id: id1 }) => !this.state.oldParticipants.internal.some(({ id: id2 }) => id2 === id1));
        }

        if (this.state.oldParticipants.external.length > 0) {
            newExternal = newExternal.filter(({ id: id1 }) => !this.state.oldParticipants.external.some(({ id: id2 }) => id2 === id1));
        }

        this.props.getScheduleContent({
            id: this.props.defaultContent.id ? this.props.defaultContent.id : startingAddedId,
            calendarId: this.props.selectedDoctor.id,
            title: this.state.title,
            category: "time",
            start: this.state.start,
            end: this.state.end,
            raw: {
                code: this.state.code,
                description: this.state.description,
                participants: {
                    internal: this.state.participants.internal,
                    external: this.state.participants.external
                },
                oldParticipants: {
                    internal: this.state.oldParticipants.internal,
                    external: this.state.oldParticipants.external
                },
                newParticipants: {
                    internal: newInternal,
                    external: newExternal
                },
                isUpdate: this.state.isUpdate
            }
        });

        this.props.openSummary(true);



    }

    componentDidMount() {
        let defaultMeetingRoomDuration = 30;
        this.props.settings.forEach(setting => {
            if(setting.name === "default_meeting_room_duration")
                defaultMeetingRoomDuration = setting.value;
        });


        this.setState({



            title: this.props.defaultContent.title ? this.props.defaultContent.title : "",
            description: this.props.defaultContent.raw ? this.props.defaultContent.raw.description : "",
            start: this.props.defaultContent.start.toDate(),
            end: this.props.defaultContent.id ? this.props.defaultContent.end.toDate() : moment(this.props.defaultContent.start.toDate()).add(defaultMeetingRoomDuration, 'm').toDate(),
            code: this.props.defaultContent.raw ? this.props.defaultContent.raw.code : "",
            participants: {
                ...this.state.participants,
                internal: this.props.defaultContent.raw ? this.props.defaultContent.raw.participants.internal : [...this.state.participants.internal, this.props.selectedDoctor],
                external: this.props.defaultContent.raw ? this.props.defaultContent.raw.participants.external : [...this.state.participants.external]
            },
            oldParticipants: {
                ...this.state.participants,
                internal: this.props.defaultContent.raw ? this.props.defaultContent.raw.participants.internal : [],
                external: this.props.defaultContent.raw ? this.props.defaultContent.raw.participants.external : []
            },
            isUpdate: !!this.props.defaultContent.raw
        });
    }

    render() {




        if (this.state.isUpdate){


            let oldTitle = this.props.defaultContent.title;
            let newTitle = this.state.title;
            let oldDescription = this.props.defaultContent.raw.description;
            let newDescription = this.state.description;



            let formValidUpdate = this.state.isUpdate && (oldTitle !== newTitle || newDescription!== oldDescription
                || this.props.defaultContent.start.toDate() !== this.state.start || this.props.defaultContent.end.toDate() !== this.state.end
                || this.props.defaultContent.raw.participants.internal !== this.state.participants.internal
                || this.props.defaultContent.raw.participants.external !== this.state.participants.external);


            if(formValidUpdate) {
                let element = document.getElementById("validate_meeting");
                element.classList.remove("displayNoneButton");
                element.classList.add("displayButton");
            }
            else {
                let element = document.getElementById("validate_meeting");
                element.classList.add("displayNoneButton");
                element.classList.remove("displayButton");
            }


            return (


            <div className="MeetingPaneContent">
                <Row>
                    <Col lg={4} md={4} sm={12} xs={12}>
                        <Col lg={12} md={12} sm={6} xs={6}>
                            <img src={require(`../../../../${data.icon}`)} className="meeting-icon"
                                 alt="profile-img" width={400}/>
                            {this.props.defaultContent && this.props.defaultContent.raw &&
                            <h3 className="meeting-code">{this.props.defaultContent.raw.code.toString().match(/.{1,3}/g).join(' ')}</h3>
                            }
                        </Col>
                        <div align="center">
                            <Button bsSize="large" className="join_button" id="join_meeting" form="Meeting" onClick={this.joinRoom}>
                                {data.join}
                            </Button>
                        </div>
                    </Col>
                    <Col lg={8} md={8} sm={12} xs={12}>
                        <form onSubmit={this.handleSubmit} id="Meeting">
                            <FormGroup controlId="title">
                                <FormControl
                                    name="title"
                                    placeholder={data.title}
                                    value={this.state.title}
                                    onChange={this.handleChange}
                                />
                            </FormGroup>
                            <FormGroup controlId="description">
                                <FormControl
                                    name="description"
                                    placeholder={data.description}
                                    componentClass="textarea"
                                    value={this.state.description}
                                    onChange={this.handleChange}
                                />
                            </FormGroup>
                            <div className="dates-container">
                                <div className="creation-dates">
                                    <MuiPickersUtilsProvider utils={MomentUtils} format={localStorage.getItem("language") === "english" ? "hh:mm" : "HH:mm"}>
                                        <DateTimePicker
                                            ampm={localStorage.getItem("language") === "english" && true}
                                            format={`${data.format} ${localStorage.getItem("language") === "english" ? "hh:mm" : "HH:mm"}`}
                                            inputVariant="outlined"
                                            value={this.state.start}
                                            onChange={(e) => this.handleChange(e, "start")}
                                        />
                                    </MuiPickersUtilsProvider>
                                </div>
                                <div className="creation-dates">
                                    <MuiPickersUtilsProvider utils={MomentUtils} format={localStorage.getItem("language") === "english" ? "hh:mm" : "HH:mm"}>
                                        <DateTimePicker
                                            minDate={this.state.start}
                                            ampm={localStorage.getItem("language") === "english" && true}
                                            format={`${data.format} ${localStorage.getItem("language") === "english" ? "hh:mm" : "HH:mm"}`}
                                            inputVariant="outlined"
                                            value={this.state.end}
                                            onChange={(e) => this.handleChange(e, "end")}
                                        />
                                    </MuiPickersUtilsProvider>
                                </div>
                            </div>
                        </form>
                    </Col>
                </Row>
                {this.participants()}
                <div className="validate-meeting">
                    <Button bsSize="large" id="validate_meeting"  disabled={!formValidUpdate} type="submit" form="Meeting">
                        {data.validate}
                    </Button>
                    <Button bsSize="large"  id="cancel_meeting" form="Meeting" onClick={() => this.closeDialog()}>
                        {data.cancel}
                    </Button>
                    <Button className="btn_delete" onClick={() => this.deleteSchedule(this.props.defaultContent)}>
                        {data.delete}
                    </Button>
                </div>
            </div>
        );
    }
        else{
            let formValidCreate = this.state.title.length > 0;

            return (
                <div className="MeetingPaneContent">
                    <Row>
                        <Col lg={4} md={4} sm={12} xs={12}>
                            <Col lg={12} md={12} sm={6} xs={6}>
                                <img src={require(`../../../../${data.icon}`)} className="meeting-icon"
                                     alt="profile-img" width={400}/>
                                {this.props.defaultContent && this.props.defaultContent.raw &&
                                <h3 className="meeting-code">{this.props.defaultContent.raw.code.toString().match(/.{1,3}/g).join(' ')}</h3>
                                }
                            </Col>
                        </Col>
                        <Col lg={8} md={8} sm={12} xs={12}>
                            <form onSubmit={this.handleSubmit} id="Meeting">
                                <FormGroup controlId="title">
                                    <FormControl
                                        name="title"
                                        placeholder={data.title}
                                        value={this.state.title}
                                        onChange={this.handleChange}
                                    />
                                </FormGroup>
                                <FormGroup controlId="description">
                                    <FormControl
                                        name="description"
                                        placeholder={data.description}
                                        componentClass="textarea"
                                        value={this.state.description}
                                        onChange={this.handleChange}
                                    />
                                </FormGroup>
                                <div className="dates-container">
                                    <div className="creation-dates">
                                        <MuiPickersUtilsProvider utils={MomentUtils} format={localStorage.getItem("language") === "english" ? "hh:mm" : "HH:mm"}>
                                            <DateTimePicker
                                                ampm={localStorage.getItem("language") === "english" && true}
                                                format={`${data.format} ${localStorage.getItem("language") === "english" ? "hh:mm" : "HH:mm"}`}
                                                inputVariant="outlined"
                                                value={this.state.start}
                                                onChange={(e) => this.handleChange(e, "start")}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </div>
                                    <div className="creation-dates">
                                        <MuiPickersUtilsProvider utils={MomentUtils} format={localStorage.getItem("language") === "english" ? "hh:mm" : "HH:mm"}>
                                            <DateTimePicker
                                                minDate={this.state.start}
                                                ampm={localStorage.getItem("language") === "english" && true}
                                                format={`${data.format} ${localStorage.getItem("language") === "english" ? "hh:mm" : "HH:mm"}`}
                                                inputVariant="outlined"
                                                value={this.state.end}
                                                onChange={(e) => this.handleChange(e, "end")}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </div>
                                </div>
                            </form>
                        </Col>
                    </Row>

                    {this.participants()}
                    <div className="validate-meeting">
                        <Button bsSize="large" disabled={!formValidCreate } id="validate_meeting" type="submit" form="Meeting" >
                            {data.validate}
                        </Button>
                        <Button bsSize="large"  id="cancel_meeting" form="Meeting" onClick={() => this.closeDialog()}>
                            {data.cancel}
                        </Button>
                        <Button className="btn_delete" onClick={() => this.deleteSchedule(this.props.defaultContent)}>
                            {data.delete}
                        </Button>
                    </div>
                </div>
            );

        }
    }
}

MeetingPaneDoctorContent.propTypes = {
    onClose: PropTypes.func,
    defaultContent: PropTypes.any,
    getScheduleContent: PropTypes.any,
    selectedDoctor: PropTypes.any,
    currentUser: PropTypes.any,
    settings: PropTypes.any,
    openSummary: PropTypes.any
}