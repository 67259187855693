import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;


export function forgetPassword(email) {
    return new Promise((resolve, reject) => {
        axios.post(API_URL + "auth/resetpassword", {
            email: email
        }).then(value => {
            resolve(value);
        }).catch(reason => {
            reject(reason);
        });
    });
}

export function confirmToken(token) {
    return new Promise((resolve, reject) => {
        axios.get(API_URL + "auth/confirm-reset?token=" + token).then(value => {
            resolve(value);
        }).catch(reason => {
            reject(reason);
        });
    });
}


export function resetPassword(email, password) {
    return new Promise((resolve, reject) => {
        axios.post(API_URL + "auth/reset-password", {
            email: email,
            password: password
        }).then(value => {
            resolve(value);
        }).catch(reason => {
            reject(reason);
        });
    });
}





