export const MODALS_REGISTER = 'MODALS_REGISTER';
export const MODALS_UNREGISTER = 'MODALS_UNREGISTER';
export const MODALS_UPDATE = 'MODALS_UPDATE';

export const registerModal = (modalId, value) => {
    return {
        type: MODALS_REGISTER,
        payload: {
            id: modalId,
            value: value
        }
    }
}

export const updateModal = (modalId, value) => {
    return {
        type: MODALS_UPDATE,
        payload: {
            id: modalId,
            value: value
        }
    }
}

export const unregisterModal = (modalId) => {
    return {
        type: MODALS_UNREGISTER,
        payload: modalId
    }
}
