import React  from "react";
import * as MobileDetect from "mobile-detect";
import { Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./Room.css";
import Jitsi from "../../components/JitsiMeetComponent";
import WhitePanel from "../../components/white-panel/WhitePanel";
import {connect} from "react-redux";
import TextContent from "../../components/text-content/TextContent";

const { room } = TextContent(localStorage.getItem("language"));
const data = {
    Enter: room.Enter,
    Room: room.Room,
    CreatedBy: room.CreatedBy ? room.CreatedBy : null,
    CreatedByStart: room.CreatedByStart ? room.CreatedByStart : null,
    CreatedByEnd: room.CreatedByEnd ? room.CreatedByEnd : null,
    Invalid: room.Invalid,
    Expired: room.Expired,
    NotStart: room.NotStart
}

class Room extends React.Component {

    constructor(props) {
        super(props);
        this.roomId = props.match.params.roomId;
        this.url = process.env.REACT_APP_API_URL;
        this.mobileDetect = new MobileDetect(window.navigator.userAgent);

        let isDesktop = this.mobileDetect.phone() === null && this.mobileDetect.tablet() === null && this.mobileDetect.mobile() === null
            && this.mobileDetect.os() === null && this.mobileDetect.userAgent() === null;

        this.state = {
            roomStatus: "",
            user: null,
            isDesktop: isDesktop
        };
    }

    componentWillMount() {
        this.getData();
    }

    getData = () => {
        fetch(this.url + "room/" + this.roomId)
            .then(res => {
                let status;
                if (res.status === 200) {
                    status = RoomStatus.VALID;
                }
                else if (res.status === 204) {
                    status = RoomStatus.NOTSTART;
                }
                else if (res.status === 400) {
                    status = RoomStatus.EXPIRED;
                }
                else {
                    status = RoomStatus.INVALID;
                }
                this.setState({roomStatus: status});
                return res.json();
            })
            .then(data => {
                if (data !== undefined) {
                    this.setState({user: data.user});
                }
            })
            .catch(console.log);
    }

    redirectToAppStore = () => {
        let openedApp = false;
            if(window.location.href.indexOf("org.jitsi.meet") !== -1) {
                openedApp = true;
                }
                if(!openedApp) {
                    window.location.replace("https://apps.apple.com/us/app/jitsi-meet/id1165103905");
                }
    };

    handleMobileJoinRoom = () => {
        let os = this.mobileDetect.os();
        if (os === 'iOS') {
            window.location.href = "org.jitsi.meet://" + process.env.REACT_APP_JITSI_DOMAIN + "/" + this.roomId;
            setTimeout(this.redirectToAppStore, 2000);
        } else if (os === 'AndroidOS') {
            window.location.assign("intent://" + process.env.REACT_APP_JITSI_DOMAIN + "/" + this.roomId + "#Intent;scheme=org.jitsi.meet;package=org.jitsi.meet;end");
        } else {
            window.location.assign("https://" + process.env.REACT_APP_JITSI_DOMAIN + "/" + this.roomId);
        }
    };

    render() {
        const formatNumber = this.roomId.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
        return (
            <div className="Room">
                {
                    this.state.roomStatus === RoomStatus.INVALID || this.state.roomStatus === RoomStatus.EXPIRED ?
                        <WhitePanel>
                            <Row>
                                <Col xs={1} sm={1} style={{marginBottom: "10px"}}>
                                    <Link to="/joinRoom">
                                        <i className="fa fa-arrow-left fa-2x"/>
                                    </Link>
                                </Col>
                                <Col xs={10} sm={10}>
                                    <h1 className="text-center">
                                        {data.Invalid}
                                    </h1>
                                </Col>


                                <Col xs={1} sm={1} style={{marginBottom: "10px"}}>
                                    <Link to="/">
                                        <i className="fa fa-times fa-2x"/>
                                    </Link>
                                </Col>
                            </Row>
                            <img src={require("../../static/images/meeting_doctor.png")} className="meeting_img"
                                 alt="holidays-img" width={100}/>
                            <div style={{textAlign: 'center'}}>
                                {this.state.roomStatus === RoomStatus.INVALID &&
                                <h3>ID : {formatNumber} {data.Invalid}</h3>}
                                {this.state.roomStatus === RoomStatus.EXPIRED &&
                                <h3>ID : {formatNumber} {data.Expired}</h3>}
                            </div>
                        </WhitePanel> : null
                }
                {
                    this.state.roomStatus === RoomStatus.VALID ?
                        this.state.isDesktop ?
                            <Jitsi room={this.roomId} user={this.props.user}/>
                            :
                            <WhitePanel>
                                <Row>
                                    <Col xs={1} style={{marginBottom: "10px"}}>
                                        <Link to="/joinRoom">
                                            <i className="fa fa-arrow-left fa-2x"/>
                                        </Link>
                                    </Col>
                                    <Col xs={1} xsOffset={9} style={{marginBottom: "10px"}}>
                                        <Link to="/">
                                            <i className="fa fa-times fa-2x"/>
                                        </Link>
                                    </Col>
                                </Row>
                                <div style={{textAlign: 'center'}}>
                                    {this.state.user &&
                                    <h3>{data.Room} {formatNumber} {data.CreatedBy ? `${data.CreatedBy} ${this.state.user.fullname}` : `${data.CreatedByStart} ${this.state.user.fullname} ${data.CreatedByEnd}` }</h3>}
                                    <Button bsSize="large" onClick={this.handleMobileJoinRoom}>
                                        {data.Enter}
                                    </Button>
                                </div>
                            </WhitePanel>
                        : null
                }
                {
                    this.state.roomStatus === RoomStatus.NOTSTART  ?
                        <WhitePanel>
                            <Row>
                                <Col xs={1} sm={1} style={{marginBottom: "10px"}}>
                                    <Link to="/joinRoom">
                                        <i className="fa fa-arrow-left fa-2x"/>
                                    </Link>
                                </Col>
                                <Col xs={10} sm={10}>
                                    <h1 className="text-center">
                                        {data.NotStart}
                                    </h1>
                                </Col>


                                <Col xs={1} sm={1} style={{marginBottom: "10px"}}>
                                    <Link to="/">
                                        <i className="fa fa-times fa-2x"/>
                                    </Link>
                                </Col>
                            </Row>
                            <img src={require("../../static/images/meeting_doctor.png")} className="meeting_img"
                                 alt="holidays-img" width={100}/>
                            <div style={{textAlign: 'center'}}>


                                <h3>ID : {formatNumber} {data.NotStart}</h3>
                            </div>
                        </WhitePanel> : null
                }
            </div>
        );
    }
}

const RoomStatus = {
    VALID: "valid",
    NOTSTART: "notstart",
    EXPIRED: "expired",
    INVALID: "invalid"
}

function mapStateToProps(state) {
    const {user} = state.auth;
    return {
        user,
    };
}

export default connect(mapStateToProps)(Room);
