import React, {Component} from "react";
import PropTypes from 'prop-types';
import {Chip} from "@material-ui/core";
import InputBase from "@material-ui/core/InputBase";

export default class ChipTextInput extends Component {

    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        const editable = this.props.editable !== undefined ? this.props.editable : true;
        if (editable && this.props.onChange) {
            this.props.onChange(event.target.value);
        }
    }

    render() {
        // Func
        const onDelete = this.props.onDelete;
        const onClick = this.props.onClick;
        const onKeyPress = this.props.onKeyPress;

        // Icons
        const onDeleteIcon = this.props.deleteIcon;
        const avatarIcon = this.props.avatarIcon;

        // Settings
        const label = this.props.value;
        const editable = this.props.editable !== undefined ? this.props.editable : true;
        const disabled = this.props.disabled !== undefined ? this.props.disabled : false;
        const minWidth = this.props.minWidth ? this.props.minWidth : '';
        const maxWidth = this.props.maxWidth ? this.props.maxWidth : '';
        const width = this.props.autoSizing ? this.getInputWidth(label.length) : '';

        // Classnames
        const chipClassName = this.props.chipClassName;
        const inputClassName = this.props.inputClassName;

        const textInput = (
            <InputBase
                value={label}
                onChange={this.handleChange}
                disabled={!editable}
                className={inputClassName}
                onKeyPress={onKeyPress}
                style={{
                    minWidth: minWidth,
                    width: width,
                    maxWidth: maxWidth
                }}
            />
        )

        return (
            <Chip
                label={textInput}
                onDelete={onDelete}
                onClick={onClick}
                className={chipClassName}
                disabled={disabled}
                deleteIcon={onDeleteIcon}
                avatar={avatarIcon}
            />
        );
    }

    getInputWidth(characterLength) {
        return characterLength  + 'ch';
    }

}

ChipTextInput.propTypes = {
    editable: PropTypes.bool,
    disabled: PropTypes.bool,
    value: PropTypes.string,
    onChange: PropTypes.func,
    onDelete: PropTypes.func,
    onClick: PropTypes.func,
    onKeyPress: PropTypes.func,
    deleteIcon: PropTypes.node,
    avatarIcon: PropTypes.node,
    chipClassName: PropTypes.string,
    inputClassName: PropTypes.string,
    minWidth: PropTypes.string,
    maxWidth: PropTypes.string,
    autoSizing: PropTypes.bool
}
