import React from "react";
import Routes from "../../Routes";

import "./AppContent.css";

export default function AppContent() {
    return (
        <div className="AppContent">
            <Routes />
        </div>
    );
}