import store from "./libService";
import {updateModal} from "../actions/modalAction";

export function getModalState(id) {
    const state = store.getState().modals.modalStates;
    return state[id];
}

export function updateModalState(id, value) {
    const state = getModalState(id);
    if (state !== value) {
        store.dispatch(updateModal(id, value));
    }
}

export function showGlobalProgress() {
    const state = getModalState('globalProgressModal');
    if (!state) {
        updateModalState('globalProgressModal', true);
    }
}

export function hideGlobalProgress() {
    const state = getModalState('globalProgressModal');
    if (state) {
        updateModalState('globalProgressModal', false);
    }
}
