import React, {Component} from "react";
import PropTypes from 'prop-types';
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft, faHome} from "@fortawesome/free-solid-svg-icons";

export default class NavigableHeader extends Component {



    render() {
        const title = this.props.title;
        const back = this.props.backwardUrl;
        const home = this.props.homeUrl;

        return (
            <div className="row">
                <div className="col d-flex align-items-center">
                    {back &&
                    <Link to={back}>
                        <FontAwesomeIcon
                            icon={faChevronLeft}
                            size={"2x"}
                            className="mr-auto"
                        />
                    </Link>
                    }
                    {title &&
                    <div className="mx-auto px-2 text-break text-center">
                        <h3>
                            {title}
                        </h3>
                    </div>
                    }
                    {home &&
                    <Link to={home}>
                        <FontAwesomeIcon
                            icon={faHome}
                            size={"2x"}
                            className="ml-auto"
                        />
                    </Link>
                    }
                </div>
            </div>
        );
    }

}

NavigableHeader.propTypes = {
    backwardUrl: PropTypes.string,
    homeUrl: PropTypes.string,
    title: PropTypes.string
}
