import { combineReducers } from "redux";
import auth from "./auth";
import message from "./message";
import settings from "./settings";

export default combineReducers({
    settings,
    auth,
    message
});
