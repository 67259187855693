import React, {Component} from "react";
import DateFnsUtils from "@date-io/date-fns";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import {format, parse} from "date-fns";
import PropTypes from 'prop-types';

export default class DateInput extends Component {

    constructor(props) {
        super(props);

        this.onChange = this.onChange.bind(this);
    }

    onChange(newValue) {
        const outputFormat = this.props.outputFormat ? this.props.outputFormat : 'yyyy-MM-dd';

        if (this.props.onChange) {
            try {
                const newDate = format(newValue, outputFormat);
                this.props.onChange(newDate);
            } catch (ignored) {
                this.props.onChange('');
            }
        }
    }

    render() {
        const inputFormat = this.props.inputFormat ? this.props.inputFormat : 'dd/MM/yyyy';
        const outputFormat = this.props.outputFormat ? this.props.outputFormat : 'yyyy-MM-dd';
        const helperText = this.props.helperText ? this.props.helperText : 'day/month/year';

        const id = this.props.id ? this.props.id : 'date-input-picker';
        const label = this.props.label ? this.props.label : 'Date input picker';

        return (
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                    margin="normal"
                    id={id}
                    label={label}
                    format={inputFormat}
                    value={
                        this.props.value ? parse(this.props.value, outputFormat, new Date()) : null
                    }
                    helperText={helperText}
                    onChange={this.onChange}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                    className={"w-100"}
                    required={this.props.required}
                />
            </MuiPickersUtilsProvider>
        );
    }

}

DateInput.propTypes = {
    value: PropTypes.string,
    inputFormat: PropTypes.string,
    outputFormat: PropTypes.string,
    onChange: PropTypes.func,
    helperText: PropTypes.string,
    id: PropTypes.string,
    label: PropTypes.string,
    required: PropTypes.bool
}
