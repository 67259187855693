import React, { Component } from "react";
import PropTypes from 'prop-types';
import ConfirmationDialog from "../../nsi-lib/components/modals/ConfirmationDialog";
import UpdateUserPaneDialog from "./Pane/dialog/UpdateUserPaneDialog";
import {Row, Col} from "react-bootstrap";

class UserCard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            toView: null,
            toggledPane: false,
        }
    }

    togglePaneDialog(open) {
        this.setState({
            toView: {...this.props.user},
            toggledPane: open
        });
    }

    render() {
        const user = this.props.user;

        return (
            <div className="UserCard">
                <div className="list_dash bg-light">
                    <Row>
                        <Col lg={3} md={3} sm={3} xs={3} className="mg_field pointer" onClick={() => this.togglePaneDialog(true)}>{user.fullname}</Col>
                        <Col lg={3} md={3} sm={3} xs={3} className="mg_field">{user.email}</Col>
                    </Row>
                </div>
                <ConfirmationDialog
                    isOpen={this.state.toggledDeleteDialog}
                    handleClose={this.deleteUser}
                />
                <UpdateUserPaneDialog
                    isOpen={this.state.toggledPane}
                    user={this.state.toView}
                    roles={this.props.roles}
                    onClose={() => this.togglePaneDialog(false)}
                />
            </div>
        );
    }
}

UserCard.propTypes = {
    user: PropTypes.any,
    roles: PropTypes.any
}

export default UserCard