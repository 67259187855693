import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

class SettingsService {
    getSettings() {
        return axios.get(API_URL + "settings")
            .then((response) => {
                const index = response.data.map(item => item.name).indexOf("sms_factor_token");
                response.data.splice(index, 1);

                localStorage.setItem("settings", JSON.stringify(response.data));
                return response.data;
            }).catch(error => {
                return error.data;
            });
    }

    getSmsToken(currentUser) {
        return axios.get(API_URL + "settings/smsToken", {
                headers: { 'Authorization': 'Bearer ' + currentUser.accessToken }
            }).then(response => {
                return response.data;
            }).catch(error => {
                return error.data;
            });
    }

    updateSettings(settings, currentUser) {
        return axios.put(API_URL + "settings", settings, {
                headers: { 'Authorization': 'Bearer ' + currentUser.accessToken }
            }).then((response) => {
                const index = response.data.map(item => item.name).indexOf("sms_factor_token");
                response.data.splice(index, 1);

                localStorage.setItem("settings", JSON.stringify(response.data));
                return response.data;
            }).catch(error => {
                return error.data;
            });
    }

    updateSmsToken(value, settingsId, currentUser) {
        return axios.put(API_URL + "settings/" + settingsId, {value}, {
            headers: { 'Authorization': 'Bearer ' + currentUser.accessToken }
        }).then(response => {
            return response.data;
        }).catch(error => {
            return error.data;
        });
    }

    updateMails(mails, currentUser) {
        return axios.put(API_URL + "settings/email", mails, {
            headers: { 'Authorization': 'Bearer ' + currentUser.accessToken }
        }).then((response) => {
            return response.data;
        }).catch(error => {
            return error.data;
        });
    }

    updateSMS(sms, currentUser) {
        return axios.put(API_URL + "settings/sms", sms, {
            headers: { 'Authorization': 'Bearer ' + currentUser.accessToken }
        }).then((response) => {
            return response.data;
        }).catch(error => {
            return error.data;
        });
    }
}

export default new SettingsService();