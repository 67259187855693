import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;
const roleList = [];

class AuthService {
    login(login, password) {
        return axios
            .post(API_URL + "auth/signin", { login, password })
            .then((response) => {
                if (response.data.accessToken) {
                    axios.put(API_URL + "auth/signin/success/" + login)
                        .catch(function (e) {
                            throw e;
                        });
                    localStorage.setItem("user", JSON.stringify(response.data));
                }

                return response.data;
            });
    }

    loginFail(login) {
        return axios
            .put(API_URL + "auth/signin/fail/" + login)
            .then((response) => {
                if (response.data.accessToken) {
                    localStorage.setItem("user", JSON.stringify(response.data));
                }

                return response.data;
            });
    }

    logout() {
        localStorage.removeItem("user");
    }

    register(username, login, email, phone, password, roles) {
        if (roles[0].value === "*") {
            roles.shift()
        }
        roles.map(role => roleList.push(role.label));
        return axios.post(API_URL + "auth/signup", {
            fullname: username,
            login,
            email,
            phone,
            password,
            role: roleList,
            creation_date: new Date(),
            status: {
                id: 1,
                name: "Up"
            },
            password_retry: 0
        });
    }
}

export default new AuthService();