import React, {Component} from "react";
import PropTypes from 'prop-types';
import {Tabs} from "@material-ui/core";
import Tab from "@material-ui/core/Tab";

/**
 * Display tabs titles and control tab navigation.
 * Require corresponding TabsContent.
 */
export default class TabsController extends Component {

    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
    }

    render() {
        const activeTab = this.props.activeTab ? this.props.activeTab : 0;
        const titles = this.props.titles;
        return (
            <Tabs
                value={activeTab}
                onChange={this.handleChange}
                variant="scrollable"
                scrollButtons="on"
                indicatorColor="primary"
                aria-label="Scrollable tabs."
            >
                {titles && titles.map((t, index) => (
                    <Tab key={index} label={t} {...a11yProps(index)}/>
                ))}
            </Tabs>
        );
    }

    handleChange(e, newValue) {
        if (this.props.handleChange) {
            this.props.handleChange(newValue);
        }
    }
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`
    };
}

TabsController.propTypes = {
    activeTab: PropTypes.number,
    titles: PropTypes.array,
    handleChange: PropTypes.func
}
