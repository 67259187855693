import React, { Component } from "react";
import frenchFlag from "../../static/images/france-flag.png";
import englandFlag from "../../static/images/united-kingdom-flag.png";
import germanyFlag from "../../static/images/germany-flag.png";
import "./LanguageSelector.css";

export default class languageSelector extends Component {
    toggleEnglish = () => {
        if (localStorage.getItem("language") !== "english") {
            localStorage.setItem("language", "english")
            window.location.reload();
        }
    }

    toggleFrancais = () => {
        if (localStorage.getItem("language") !== "french") {
            localStorage.setItem("language", "french")
            window.location.reload();
        }
    }

    toggleDeutsch = () => {
        if (localStorage.getItem("language") !== "deutsch") {
            localStorage.setItem("language", "deutsch")
            window.location.reload();
        }
    }

    render() {
        return(
            <div>
                <button onClick={this.toggleEnglish}><img src={englandFlag} alt="english language" /></button>
                <button onClick={this.toggleFrancais}><img src={frenchFlag} alt="french language" /></button>
                <button onClick={this.toggleDeutsch}><img src={germanyFlag} alt="german language" /></button>
            </div>
        );
    }
}
