import React, {Component} from "react";
import PropTypes from "prop-types";
import CallableDialog from "../../../../nsi-lib/components/modals/CallableDialog";
import UpdateUserPaneContent from "../content/UpdateUserPaneContent";

import "../../User.css";

export default class UpdateUserPaneDialog extends Component {
    constructor(props) {
        super(props);

        this.closeDialog = this.closeDialog.bind(this);
    }

    closeDialog() {
        if (this.props.onClose) {
            this.props.onClose();
        }
    }

    render() {
        const {user, roles, status, isOpen} = this.props;
        if (!user) return null;

        return (
            <CallableDialog
                isOpen={isOpen}
                handleClose={this.closeDialog}
                fullwidth
                content={
                    <div className="update_dialog">
                        <button className="close-button" onClick={() => this.closeDialog()}>
                            <i className="fa fa-times fa-2x" />
                        </button>
                        <UpdateUserPaneContent
                            user={user}
                            roles={roles}
                            status={status}
                            onClose={() => this.closeDialog()}
                        />
                    </div>
                }
            />
        );
    }
}

UpdateUserPaneDialog.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    user: PropTypes.any,
    roles: PropTypes.any,
    status: PropTypes.any
}
