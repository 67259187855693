import {ERROR_MODAL_CLEAR, ERROR_MODAL_REGISTER} from "../actions/errorModalAction";

const initialState = {
    errors: []
}

export default function (state = initialState, action) {

    switch (action.type) {
        case ERROR_MODAL_REGISTER:
            return {
                ...state,
                errors: [
                    ...state.errors,
                    {
                        error: action.error,
                        request: action.request,
                        details: action.details ? action.details : [],
                        ignoreDefault: action.ignoreDefault ? action.ignoreDefault : false
                    }
                ]
            }

        case ERROR_MODAL_CLEAR:
            return {
                ...state,
                errors: []
            }

        default:
            return state;
    }

}
