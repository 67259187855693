import React, {Component} from "react";
import PropTypes from 'prop-types';
import CallableDialog from "./CallableDialog";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default class IconContentDialog extends Component {

    constructor(props) {
        super(props);
        this.handleClose = this.handleClose.bind(this);
    }

    render() {
        const isOpen = this.props.isOpen ? this.props.isOpen : false;
        const icon = this.props.icon ? this.props.icon : null;
        const title = this.props.title ? this.props.title : null;
        const content = this.props.content ? this.props.content : null;
        const actions = this.props.actions ? this.props.actions : null;
        const color = this.props.color ? this.props.color : null;
        const fullscreen = this.props.fullscreen;
        const fullwidth = this.props.fullwidth;
        const maxWidth = this.props.maxWidth;

        const formattedContent = (
            <div className="container">
                <div className="row pb-2 border-bottom border-light text-center">
                    {icon &&
                        <div className="col-12 mb-4">
                            <FontAwesomeIcon
                                icon={icon}
                                size={"5x"}
                                className="dialogIcon"
                                color={color}
                            />
                        </div>
                    }
                    {title &&
                        <div className="col-12">
                            <h5>{title}</h5>
                        </div>
                    }
                </div>
                <div className="row">
                    {content}
                </div>
            </div>
        )

        return (
            <CallableDialog
                isOpen={isOpen}
                content={formattedContent}
                actions={actions}
                handleClose={this.handleClose}
                fullscreen={fullscreen}
                fullwidth={fullwidth}
                maxWidth={maxWidth}
            />
        );
    }

    handleClose(e) {
        if (this.props.handleClose) {
            this.props.handleClose(e);
        }
    }

}

IconContentDialog.propTypes = {
    isOpen: PropTypes.bool,
    icon: PropTypes.any,
    title: PropTypes.string,
    content: PropTypes.node,
    actions: PropTypes.node,
    color: PropTypes.string,
    handleClose: PropTypes.func,
    fullscreen: PropTypes.bool,
    fullwidth: PropTypes.bool,
    maxWidth: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', false])
}
