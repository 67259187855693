import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export function getUserHolidays(currentUser, userid) {
    return new Promise((resolve, reject) => {
        axios.get(API_URL + "holiday/" + userid, {
            headers: { 'Authorization': 'Bearer ' + currentUser.accessToken }
        }).then(value => {
            resolve(value);
        }).catch(reason => {
            reject(reason);
        });
    });
}

export function createUserHolidays(currentUser, holidays) {
    return new Promise((resolve, reject) => {
        axios.post(API_URL + "holiday/" + currentUser.id, holidays,{
            headers: { 'Authorization': 'Bearer ' + currentUser.accessToken }
        }).then(value => {
            resolve(value);
        }).catch(reason => {
            reject(reason);
        });
    });
}

export function deleteUserHolidays(currentUser, holidayId) {
    return new Promise((resolve, reject) => {
        axios.delete(API_URL + "holiday/" + holidayId, {
            headers: { 'Authorization': 'Bearer ' + currentUser.accessToken }
        }).then(value => {
            resolve(value);
        }).catch(reason => {
            reject(reason);
        });
    });
}
