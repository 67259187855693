import React, {Component} from "react";
import {Button} from "react-bootstrap";
import {connect} from "react-redux";
import {Redirect} from "react-router-dom";
import WhitePanel from "../white-panel/WhitePanel";
import {getUserRooms} from "../../services/room/roomService";
import UpdatePasswordPaneDialog from "./Pane/dialog/UpdatePasswordPaneDialog";
import TextContent from "../text-content/TextContent";

import "./Profile.css";

const {profile} = TextContent(localStorage.getItem("language"));
const data = {
    title: profile.title,
    creationDate: profile.creationDate,
    fullname: profile.fullname,
    phone: profile.phone,
    email: profile.email,
    username: profile.username,
    password: profile.password,
    activeRooms: profile.activeRooms,
    code: profile.code,
    roles: profile.roles,
}

class Profile extends Component {
    constructor(props) {
        super(props);

        this.state = {
            roomData: null
        }
    }

    toggleUpdatePaneDialog(open) {
        this.setState({
            toggledPane: open
        });
    }

    componentDidMount() {
        new Promise((resolve, reject) => {
            getUserRooms(this.props.user).then(value => {
                resolve(this.setState({roomData: value.data}));
            }).catch(reason => {
                reject(reason);
            });
        }).catch(() => {
           this.props.history.push("/login");
        });
    }

    render() {
        const {user: currentUser} = this.props;

        if (!currentUser) {
            return <Redirect to="/login"/>;
        }


        return (
            <div className="Profile">
                    <WhitePanel>
                        <div>
                            <div className="profile-content">
                                <h1 className="title_profil">{data.title}</h1>
                                <div className="profile-title">
                                    <div className="row">
                                        <div className="col-md-3 col-sm-4">

                                        <img src={require("../../static/images/profile.png")}
                                             alt="holidays-img" width={100} className="img_profil"/>
                                        </div>
                                        <div className="col-md-4 col-sm-8 first_line_name">
                                            <h2 className="title_1">{currentUser.fullname}</h2>

                                            <h2 className="title_2">{currentUser.email}</h2>
                                        </div>
                                    </div>




                                <div className="row second_info">

                                    <div className="margin-left"></div>
                                    {/*<h4>{data.title} :</h4>*/}
                                            <div className="col-md-4 col-sm-12">
                                                <div className="user_name_info">
                                                <strong>{data.fullname} :</strong> {currentUser.fullname} < br/>
                                                <strong>{data.username} :</strong> {currentUser.login}< br/>
                                                    <strong>{data.roles} : </strong>
                                                    {
                                                        currentUser.roles.map((roles, index) =>
                                                            <p className="align_map" key={index}>{roles}</p>
                                                        )
                                                    }
                                                </div>

                                            </div>

                                        <div className="col-md-5 col-sm-12">
                                            <div className="user_name_info_2">
                                            <strong>{data.creationDate} :</strong> {currentUser.creation_date.split('T')[0]} <br />
                                            {currentUser.phone && <><strong>{data.phone} :</strong> {currentUser.phone} < br/></>}
                                            {currentUser.email && <><strong>{data.email} :</strong> {currentUser.email} < br/></>}

                                        </div>
                                        </div>
                                </div>
                                </div>


                                </div>
                                <Button style={{marginTop: "20px"}} onClick={() => this.toggleUpdatePaneDialog(true)}>
                                    {data.password}
                                </Button>

                            {this.state.roomData && this.state.roomData.length > 0 &&
                            <div>
                                <h3>{data.activeRooms} :</h3>
                                <ul>
                                {this.state.roomData.map((room, index) =>
                                    <li key={index} className="codes">
                                        {data.code} : <a href={"/room/" + room.code}>{room.code}</a>
                                    </li>
                                )}
                                </ul>
                            </div>
                            }
                        </div>
                        <UpdatePasswordPaneDialog
                            isOpen={this.state.toggledPane}
                            onClose={() => this.toggleUpdatePaneDialog(false)}
                        />
                </WhitePanel>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const {user} = state.auth;
    const {settings} = state.settings;
    return {
        user,
        settings
    };
}

export default connect(mapStateToProps)(Profile);