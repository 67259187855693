import React, {Component} from "react";
import {Button, Col, ControlLabel, FormControl, FormGroup, HelpBlock, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import WhitePanel from "../white-panel/WhitePanel";
import {getUser} from "../../services/user/userService";
import {login} from "../../actions/auth";
import AuthService from "../../services/auth/auth.service";
import {createRoom} from "../../services/room/roomService";
import {forgetPassword} from "../../services/auth/forgetPassword";
import TextContent from "../text-content/TextContent";

const {authentication, resources} = TextContent(localStorage.getItem("language"));
const data = {
    forget_password:authentication.login.forget_password,
    mail:authentication.login.mail,
    helpblock1:authentication.login.helpblock1,
    send: authentication.login.send,
    mailTitle: authentication.login.mailTitle
}


export default class ForgetPassword extends Component {
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onChangeEmail = this.onChangeEmail.bind(this);

        this.state = {
            email: "",
            status:"",
            errors: {
                email: ""
            },
            successful: false,
            message: ""
        };
    }

    onChangeEmail(e) {
        const value = e.target.value;
        let error = false;
        if (value.length === 0) {
            error = true;
        }
        this.setState({
            email: e.target.value,
            errors: {
                ...this.state.errors,
                email: error
            }
        });
    }

    handleSubmit(e) {
        e.preventDefault();
        new Promise((resolve, reject) => {
            forgetPassword(this.state.email).then(value => {
                this.setState({
                    status: value.status
                })
            });

        });
    }

    render() {
        return (
            <div className="content_forget">
                <WhitePanel >
                    <div className="whitePanel_class">
                    <div className="row">
                        <Col lg={7} lgOffset={3} mdOffset={1} md={7} xs={9} sm={8} smOffset={2} xsOffset={1}>
                            <h1 className="title_forget">{data.forget_password}</h1>
                        </Col>
                        <Col lg={1} lgOffset={1} mdOffset={1} md={1} xs={1} smOffset={1} sm={1} className="exit_class">
                            <Link to="/">
                                <i className="fa fa-times fa-2x" />
                            </Link>
                        </Col>
                    </div>

                    <form onSubmit={this.handleSubmit} className="form_class">

                    <div className="row">
                        <Col lg={3}>
                        <img src={require("../../static/images/forgot-password.png")} className="forgot-img"
                             alt="profile-img" />
                        </Col>
                        <Col  lg={7} id="form_password">
                        <FormGroup controlId="Email">
                            <ControlLabel>{data.mailTitle}</ControlLabel>
                            <FormControl
                                placeholder={data.mail}
                                value={this.state.email}
                                onChange={this.onChangeEmail}
                            />
                        </FormGroup>
                            {this.state.status ?
                                <HelpBlock>{data.helpblock1}</HelpBlock> : null}
                        </Col>
                    </div>

                        <br/>
                        <br/>
                        <div className="text-right right_button">
                            <Button bsSize="large"  type="submit" id="submit">
                                {data.send}
                            </Button>
                        </div>


                    </form>
                    </div>
                </WhitePanel>

            </div>
        )
    }


}