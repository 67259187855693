import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL

export function getUsers(currentUser) {
    return new Promise((resolve, reject) => {
        axios.get(API_URL + "user",{
            headers: { 'Authorization': 'Bearer ' + currentUser.accessToken }
        }).then(value => {
            resolve(value);
        }).catch(reason => {
            reject(reason);
        });
    });
}

export function getUser(login) {
    return new Promise((resolve, reject) => {
        axios.get(API_URL + "user/get/" + login,{
        }).then(value => {
            resolve(value);
        }).catch(reason => {
            reject(reason);
        });
    });
}

export function getDoctors(currentUser) {
    return new Promise((resolve, reject) => {
        axios.get(API_URL + "user/doctors",{
            headers: { 'Authorization': 'Bearer ' + currentUser.accessToken }
        }).then(value => {
            resolve(value);
        }).catch(reason => {
            reject(reason);
        });
    });
}

export function getAssistedUsers(currentUser, id) {
    return new Promise((resolve, reject) => {
        axios.get(API_URL + "user/" + (id ? id : currentUser.id) + "/assists", {
            headers: { 'Authorization': 'Bearer ' + currentUser.accessToken }
        }).then(value => {
            resolve(value);
        }).catch(reason => {
            reject(reason);
        });
    });
}

export function updateUser(id, fullname, login, password, email, phone, creation_date, roles, isLocked, password_retry, currentUser) {
    const nullStatus = {
        id: null,
        name: ""
    }
    const status = Object.create(nullStatus);

    if (roles[0].value === "*") {
        roles.shift()
    }
    roles.forEach(function(v) {
        v.id = v.value;
        delete v.value;
        delete v.label;
    });
    if (isLocked) {
        status.id = 3;
        status.name = "Locked"
    } else {
        status.id = 1;
        status.name = "Up"
    }

    return new Promise((resolve, reject) => {
        axios.put(API_URL + "user/" + id, {
            id,
            fullname,
            login,
            password,
            email,
            phone,
            creation_date,
            roles,
            status,
            password_retry
        }, {
            headers: { 'Authorization': 'Bearer ' + currentUser.accessToken }
        }).then(value => {
            resolve(value);
        }).catch(reason => {
            reject(reason);
        });
    });
}

export function updateUserAssistant(id, users, currentUser) {
    return new Promise((resolve, reject) => {
        axios.put(API_URL + "user/" + id + "/assists", {
            users
        }, {
            headers: { 'Authorization': 'Bearer ' + currentUser.accessToken }
        }).then(value => {
            resolve(value);
        }).catch(reason => {
            reject(reason);
        });
    });
}

export function updateUserPassword(previous_password, password, currentUser) {
    return new Promise((resolve, reject) => {
        axios.put(API_URL + "user/" + currentUser.id + "/password", {
            previous_password,
            password
        }, {
            headers: { 'Authorization': 'Bearer ' + currentUser.accessToken }
        }).then(value => {
            resolve(value);
        }).catch(reason => {
            reject(reason);
        });
    });
}

export function deleteUser(id, currentUser) {
    return new Promise((resolve, reject) => {
        axios.delete(API_URL + "user/" + id, {
            headers: { 'Authorization': 'Bearer ' + currentUser.accessToken }
        }).then(value => {
            resolve(value);
        }).catch(reason => {
            reject(reason);
        });
    });
}