import React, {Component} from "react";
import {Button, FormGroup, FormControl, ControlLabel, Row, Col, Alert} from "react-bootstrap";
import {connect} from "react-redux";
import RoomCreator from "../../components/room/room-creator/RoomCreator";
import WhitePanel from "../../components/white-panel/WhitePanel";
import {Link} from "react-router-dom";
import {login} from "../../actions/auth";
import TextContent from "../../components/text-content/TextContent";

import "./RoomAdmin.css";

const { resources, room } = TextContent(localStorage.getItem("language"));
const data = {
    createImage: resources.room.roomCreator.createImage,
    AccountExists: room.roomAdmin.AccountExists,
    AccountRights: room.roomAdmin.AccountRights,

    title: room.roomAdmin.title,
    Form: {
        username: room.roomAdmin.Form.username,
        password: room.roomAdmin.Form.password,
    },

    Enter: room.Enter
}

class RoomAdmin extends Component {

    constructor(props) {
        super(props);
        this.url = process.env.REACT_APP_API_URL;
        this.state = {
            login: "",
            password: "",
            secure_login: null,
            login_entered: false,
            postMessage: ""
        }

        this.handleChangeLogin = this.handleChangeLogin.bind(this);
    }

    handleChangeLogin = (login_entered, message) => {
        this.setState({login_entered, postMessage: message})
        setTimeout(() => {
            this.setState({
                postMessage: ""
            });
        }, 1000);
    }

    validateForm() {
        if (this.state.secure_login === "true") {
            return this.state.login.length > 0 && this.state.password.length > 0;
        } else {
            return this.state.login.length > 0
        }
    }

    handleSubmit = (event) => {
        event.preventDefault();
        if (this.state.secure_login === "true") {
            const {dispatch} = this.props;

            dispatch(login(this.state.login, this.state.password))
                .catch(() => {
                    this.setState({postMessage: data.AccountExists})
                });
        } else if (this.state.login.length > 0) {
            this.setState({login_entered: true})
        }
    }

    componentDidMount() {
        let secureLogin = true;
        this.props.settings.forEach(setting => {
            if(setting.name === "secure_login")
                secureLogin = setting.value;
        });

        this.setState({
            secure_login: secureLogin
        });
    }

    render() {
        if (this.state.secure_login === "true") {
            if (!this.props.user)
                return (
                    <div className="RoomAdmin">
                        <WhitePanel>
                            <h1>{data.title} :</h1>
                            {this.state.postMessage !== "" ?
                                <Row>
                                    <Col xs={12}>
                                        <Alert bsStyle="danger">
                                            {this.state.postMessage}
                                        </Alert>
                                    </Col>
                                </Row>
                                : null
                            }
                            <Row>
                                <Col xs={1} xsOffset={11}>
                                    <Link to="/">
                                        <i className="fa fa-times fa-2x"/>
                                    </Link>
                                </Col>
                            </Row>
                            <Row style={{marginTop: "20px", marginBottom: "-20px"}}>
                                <Col lg={4} md={4} sm={12} xs={12} className="create-image">
                                    <div className="container__img">
                                        <img src={require(`../../${data.createImage}`)} alt="" className="center-block"/>
                                    </div>
                                </Col>
                                <Col lg={8} md={12} sm={12} xs={12} className="fields">
                                    <form onSubmit={this.handleSubmit}>
                                        <FormGroup bsSize="large">
                                            <ControlLabel>{data.Form.username}</ControlLabel>
                                            <FormControl
                                                id="login"
                                                autoFocus
                                                value={this.login}
                                                onChange={e => this.setState({login: e.target.value})}
                                            />
                                            <ControlLabel>{data.Form.password}</ControlLabel>
                                            <FormControl
                                                id="password"
                                                type="password"
                                                value={this.password}
                                                onChange={e => this.setState({password: e.target.value})}
                                            />
                                        </FormGroup>
                                        <Button bsSize="large" disabled={!this.validateForm()} type="submit" style={{float: "right"}}>
                                            {data.Enter}
                                        </Button>
                                    </form>
                                </Col>
                            </Row>
                        </WhitePanel>
                    </div>
                );
            else {
                return (<RoomCreator user={this.props.user}/>)
            }
        } else if (!this.state.login_entered) {
            return (
                <div className="RoomAdmin">
                    <WhitePanel>
                        <h1>{data.title} :</h1>
                        {this.state.postMessage !== "" ?
                            <Row>
                                <Col xs={12}>
                                    <Alert bsStyle="danger">
                                        {this.state.postMessage}
                                    </Alert>
                                </Col>
                            </Row>
                            : null
                        }
                        <Row>
                            <Col xs={1} xsOffset={11}>
                                <Link to="/">
                                    <i className="fa fa-times fa-2x"/>
                                </Link>
                            </Col>
                        </Row>
                        <Row style={{marginTop: "20px", marginBottom: "-20px"}}>
                            <Col lg={4} md={4} sm={12} xs={12} className="create-image">
                                <div className="container__img">
                                    <img src={require(`../../${data.createImage}`)} alt="" className="center-block"/>
                                </div>
                            </Col>
                            <Col lg={8} md={12} sm={12} xs={12} className="fields">
                                <form onSubmit={this.handleSubmit}>
                                    <FormGroup controlId="Username" bsSize="large">
                                        <ControlLabel>Username</ControlLabel>
                                        <FormControl
                                            autoFocus
                                            value={this.login}
                                            onChange={e => this.setState({login: e.target.value})}
                                        />
                                    </FormGroup>
                                    <Button bsSize="large" disabled={!this.validateForm()} type="submit" style={{float: "right"}}>
                                        {data.Enter}
                                    </Button>
                                </form>
                            </Col>
                        </Row>
                    </WhitePanel>
                </div>
            );
        } else {
            return (<RoomCreator login={this.state.login} error={this.handleChangeLogin} />)
        }
    }
}

function mapStateToProps(state) {
    const {user} = state.auth;
    const {settings} = state.settings;
    return {
        user,
        settings
    };
}

export default connect(mapStateToProps)(RoomAdmin);
