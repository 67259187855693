import store from "./libService";
import {clearErrors, registerError} from "../actions/errorModalAction";
import {getModalState, updateModalState} from "./modalService";

/**
 * Handle and log httpError.
 * @param axiosError
 */
export function handleHttpError(axiosError) {
    const error = {
        status: axiosError.response.status,
        data: axiosError.response.data,
        headers: axiosError.response.headers,
        message: axiosError.message,
    }
    console.error("API response with HTTP error : " + error.message, error);
}

/**
 * Show the global ErrorModal.
 */
export function showGlobalErrorModal() {
    const state = getModalState('globalErrorModal');
    if (!state) {
        updateModalState('globalErrorModal', true);
    }
}

/**
 * Hide the global ErrorModal and clear persisted errors.
 */
export function hideGlobalErrorModal() {
    clearGlobalErrors();
    const state = getModalState('globalErrorModal');
    if (state) {
        updateModalState('globalErrorModal', false);
    }
}

/**
 * Register error for global ErrorModal.
 * @param httpError AxiosResponse
 * @param details [{apiStatusCode, content}]
 * @param ignoreDefault Ignore the defaults error content ? You should provide custom detail if true.
 */
export function registerGlobalError(httpError, details, ignoreDefault = false) {
    const apiError = formatHttpError(httpError);
    const request = httpError.request;

    if (apiError && request) {
        store.dispatch(registerError(request, apiError, details, ignoreDefault));
    } else {
        console.warn('Incorrect http error specified when register in global ErrorModal.');
    }
}

/**
 * Clear errors from the global ErrorModal
 */
function clearGlobalErrors() {
    store.dispatch(clearErrors());
}

/**
 * Format axios response to a custom ApiError response.
 * @param httpError
 * @return {null|{stacktrace: *, apiStatusCode: string, httpStatusString: *, message: *, httpStatusCode: *}}
 */
function formatHttpError(httpError) {
    if (httpError && httpError.response && httpError.response.data) {
        const data = httpError.response.data;
        return {
            httpStatusCode: data.httpStatusCode,
            httpStatusString: data.httpStatusString,
            stacktrace: data.stacktrace,
            apiStatusCode: data.apiStatusCode,
            message: data.message
        }
    }
    return null;
}
