import React, {Component} from "react";
import CallableDialog from "../../../../nsi-lib/components/modals/CallableDialog";
import PropTypes from "prop-types";
import UpdatePasswordPaneContent from "../content/UpdatePasswordPaneContent";

export default class UpdatePasswordPaneDialog extends Component {
    constructor(props) {
        super(props);

        this.closeDialog = this.closeDialog.bind(this);
    }

    closeDialog() {
        if (this.props.onClose) {
            this.props.onClose();
        }
    }

    render() {
        const isOpen = this.props.isOpen;
        return (
            <CallableDialog
                isOpen={isOpen}
                handleClose={this.closeDialog}
                fullwidth
                content={(
                    <div>
                        <button className="close-button" onClick={() => this.closeDialog()}>
                            <i className="fa fa-times fa-2x" />
                        </button>
                        <UpdatePasswordPaneContent />
                    </div>
                )}
            />
        );
    }
}

UpdatePasswordPaneDialog.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func
}
