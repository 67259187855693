import React, {Component} from "react";
import PropTypes from "prop-types";
import moment from "moment";
import {Button} from "react-bootstrap";
import {deleteUserHolidays} from "../../../../services/holidays/holidaysService";
import TextContent from "../../../text-content/TextContent";
import {connect} from "react-redux";

import "../../Doctor.css";

const {doctor} = TextContent(localStorage.getItem("language"));
const data = {
    leaveDay: doctor.scheduleDetails.leaveDay,
    start: doctor.scheduleDetails.start,
    end: doctor.scheduleDetails.end,
    join: doctor.scheduleDetails.join,
    delete: doctor.scheduleDetails.delete,
    format: doctor.dateFormat
}

class ScheduleDetailsPaneContent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            content: ""
        }

        this.deleteElement = this.deleteElement.bind(this);
    }

    deleteElement() {
        deleteUserHolidays(this.props.currentUser, this.state.content.raw.holidayId)
            .then(() => {
               window.location.reload();
            });
    }

    componentDidMount() {
        this.setState({
            content: this.props.content.schedule,
        });
    }

    render() {

        const startDate = new Date(this.state.content.start);
        const endDate = new Date(this.state.content.end);
        let meetingRoomAvailabilityBefore = 0;
        let meetingRoomAvailabilityAfter = 0;

        this.props.settings.forEach(setting => {
            if(setting.name === "meeting_room_availability_before")
                meetingRoomAvailabilityBefore = setting.value;
            if(setting.name === "meeting_room_availability_after")
                meetingRoomAvailabilityAfter = setting.value;
        });

        return (
            <div className="ScheduleDetailsPaneContent">
                {this.state.content.raw && this.state.content.raw.isRecurrent ?
                    <div>
                        {data.leaveDay} : {this.state.content.raw.day}
                    </div>
                    :
                    <div>
                        {data.start} : {moment(startDate).format(`${data.format} HH:mm`)}
                        <br />
                        {data.end} : {moment(endDate).format(`${data.format} HH:mm`)}
                    </div>
                }
                {this.state.content.raw && !this.state.content.raw.type ?
                    moment(new Date()).isBetween(moment(moment(this.state.content.start)._i._date).subtract(meetingRoomAvailabilityBefore, "minutes"), moment(moment(this.state.content.end)._i._date).add(meetingRoomAvailabilityAfter, "hours")) &&
                        <Button href={`/room/${this.state.content.raw.code}`}
                                 style={{float: "right", marginTop: "20px"}}>
                            {data.join}
                        </Button> :
                    this.state.content.raw && !this.state.content.raw.isRecurrent &&
                    <Button variant={"outlined"}
                            className="border-danger delete"
                            onClick={this.deleteElement}
                            style={{float: "right", marginTop: "20px"}}>
                        {data.delete}
                    </Button>
                }
            </div>
        );
    }
}

ScheduleDetailsPaneContent.propTypes = {
    content: PropTypes.any,
    currentUser: PropTypes.any
}

function mapStateToProps(state) {
    const {settings} = state.settings;
    return {
        settings
    };
}

export default connect(mapStateToProps)(ScheduleDetailsPaneContent);