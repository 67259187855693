import React from "react";
import "./WhitePanel.css";
import { Row, Col } from "react-bootstrap";

const WhitePanel = props => {
    return (
        <Row>
            <Col lg={6} lgOffset={3} md={6} mdOffset={3} sm={12} xs={12} className="alignment">
                <div className="WhitePanel">
                    {props.children}
                </div>
            </Col>
        </Row>
    );
};

export default WhitePanel;