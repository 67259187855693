import React from "react";
import { Button, FormGroup, FormControl, ControlLabel, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import WhitePanel from "../../components/white-panel/WhitePanel";
import "./JoinRoom.css";
import TextContent from "../../components/text-content/TextContent";

const { resources, room } = TextContent(localStorage.getItem("language"));
const data = {
    joinImage: resources.room.roomJoiner.joinRoom,
    title: room.joinRoom.title,
    roomNumber: room.joinRoom.roomNumber,
    Enter: room.Enter
}

class JoinRoom extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            roomNumber: ""
        };
    }
    
    validateForm = () => {
        let val = false;
        val = this.state.roomNumber.length > 0;
        return val;
    };
    
    handleChangeRoomNumber = (event) => {
        event.preventDefault();
        let value = event.target.value;
        if (/^[0-9]+$/.test(value)) {
            this.setState({roomNumber : event.target.value});
        }
    };
    
    handleSubmit = (event) => {
        event.preventDefault();
        this.props.history.push("/room/" + this.state.roomNumber);
    };
    
    render() {
        const roomNumber = this.state.roomNumber;
        return (
            <div className="JoinRoom">
                <WhitePanel>
                    <h1>{data.title} :</h1>
                    <Row>
                        <Col xs={1} xsOffset={11}>
                            <Link to="/">
                                <i className="fa fa-times fa-2x" />
                            </Link>
                        </Col>
                    </Row>
                    <Row style={{marginTop: "20px", marginBottom: "-20px"}}>
                        <Col lg={4} md={4} sm={12} xs={12} className="create-image">
                            <div className="container__img">
                                <img src={require(`../../${data.joinImage}`)} alt="" className="center-block" />
                            </div>
                        </Col>
                        <Col lg={8} md={12} sm={12} xs={12} className="fields">
                            <form onSubmit={this.handleSubmit}>
                                <FormGroup controlId="roomNumber" bsSize="large">
                                    <ControlLabel>{data.roomNumber}</ControlLabel>
                                    <FormControl
                                        autoFocus
                                        value={roomNumber}
                                        onChange={this.handleChangeRoomNumber}
                                    />
                                </FormGroup>
                                <Button bsSize="large" disabled={!this.validateForm()} type="submit" style={{float: "right"}}>
                                    {data.Enter}
                                </Button>
                            </form>
                        </Col>
                    </Row>
                </WhitePanel>
            </div>
        );
    }
}

export default JoinRoom;