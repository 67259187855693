import React from "react";
import { connect } from "react-redux";
import { createBrowserHistory } from "history";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import AppContent from "./containers/app-content/AppContent";
import Toolbar from "./containers/navbar/Toolbar/Toolbar";
import SideDrawer from "./containers/navbar/SideDrawer/SideDrawer";

import { logout } from "./actions/auth";
import { clearMessage } from "./actions/message";
import { getSettings } from "./actions/settings";

class App extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            sideDrawerOpen: false,
            currentUser: undefined
        };

        this.logOut = this.logOut.bind(this);

        createBrowserHistory().listen((location) => {
            props.dispatch(clearMessage());
        });
    }

    componentDidMount() {
        const user = this.props.user;
        this.props.dispatch(getSettings(user));

        if (user) {
            this.setState({
                currentUser: user
            });
        }
    }

    logOut() {
        this.props.dispatch(logout());
        window.location.reload();
    }
    
    drawerToggleClickHandler = () => {
        this.setState((prevState) => {
            return {sideDrawerOpen: !prevState.sideDrawerOpen};
        });
    };
    
    backdropClickHandler = () => {
        this.setState({sideDrawerOpen: false});
    };
    
    render() {
        const { currentUser } = this.state;

        return (
            <div className="App container">
            <Toolbar logOut={this.logOut} currentUser={currentUser} drawerClickHandler={this.drawerToggleClickHandler} />
            <SideDrawer logOut={this.logOut} currentUser={currentUser} show={this.state.sideDrawerOpen} backdropClickHandler={this.backdropClickHandler}/>
            <AppContent />
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { user } = state.auth;
    return {
        user
    };
}

export default connect(mapStateToProps)(App);
