import React, {Component} from "react";
import PropTypes from "prop-types";
import {Button, Col, ControlLabel, FormControl, FormGroup, HelpBlock, Row} from "react-bootstrap";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import TextContent from "../../../text-content/TextContent";
import {connect} from "react-redux";
import isEmail from "validator/lib/isEmail";
import {deleteUser, updateUser} from "../../../../services/user/userService";
import ConfirmationDialog from "../../../../nsi-lib/components/modals/ConfirmationDialog";
import AddUserPaneDialog from "../dialog/AddUserPaneDialog";
import Switch from "react-switch";
import PhoneInput, {isValidPhoneNumber} from "react-phone-number-input";
import flags from "react-phone-number-input/flags";

const { resources, userManagement } = TextContent(localStorage.getItem("language"));

const data = {
    avatar: resources.authentication.register.avatar,
    Form: {
        fullname: userManagement.updateUser.Form.fullname,
        username: userManagement.updateUser.Form.username,
        password: userManagement.updateUser.Form.password,

        passwordConfirm: userManagement.updateUser.Form.passwordConfirm,
        email: userManagement.updateUser.Form.email,
        locked: userManagement.updateUser.Form.locked,
        submit: userManagement.updateUser.Form.submit,
        manageDoctor: userManagement.updateUser.Form.manageDoctor
    },
    DeleteDialog: {
        title: userManagement.updateUser.DeleteDialog.title,
        validate: userManagement.updateUser.DeleteDialog.validate,
        cancel: userManagement.updateUser.DeleteDialog.cancel
    },
    Error: {
        mandatory: userManagement.updateUser.Error.Mandatory,
        mailValidator: userManagement.updateUser.FormError.mailValidator,
        fullnameLength: userManagement.updateUser.FormError.fullnameLength,
        usernameLength: userManagement.updateUser.FormError.usernameLength,

        passwordLengthStart: userManagement.updateUser.FormError.passwordLengthStart,
        passwordLengthEnd: userManagement.updateUser.FormError.passwordLengthEnd,
        passwordConfirm: userManagement.updateUser.FormError.passwordConfirm
    },
    Buttons: {
        delete: userManagement.updateUser.Buttons.delete,
        submit: userManagement.updateUser.Buttons.submit,
    }
}

class UpdateUserPaneContent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            fullname: "",
            username: "",
            email: "",
            phone: "",
            password: "",
            oldpassword:"",
            confirmPassword: "",
            creation_date: "",
            roles: {
                roleList: [],
                selectedRoles: []
            },
            locked: false,
            notModified: {
                fullname: "",
                username: "",
                email: "",
                phone: "",
                password: "",
                confirmPassword: "",
                oldpassword:"",
                roles: {
                    selectedRoles: []
                },
                locked: false
            },
            errors: {
                fullname: "",
                fullnameLength: "",
                username: "",
                usernameLength: "",
                emailFormat: "",
                phoneFormat: "",
                password: "",
                passwordLength: "",
                confirmPassword: "",
                confirmPasswordEquals: ""
            },
            successful: false,
            toDelete: null,
            toggledDeleteDialog: false,
            toggledAddDialog: false,
            deleteConfirmationDialog: {
                title: data.DeleteDialog.title,
                cancel: data.DeleteDialog.cancel,
                validate: data.DeleteDialog.validate
            }
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.onChangeUsername = this.onChangeUsername.bind(this);
        this.onChangeLogin = this.onChangeLogin.bind(this);
        this.onChangeEmail = this.onChangeEmail.bind(this);
        this.onChangePhone = this.onChangePhone.bind(this);
        this.onChangeStatus = this.onChangeStatus.bind(this);
        this.deleteUser = this.deleteUser.bind(this);
        this.deleteElement = this.deleteElement.bind(this);
        this.onChangePassword = this.onChangePassword.bind(this);
        this.onChangeConfirmPassword = this.onChangeConfirmPassword.bind(this);
    }

    toggleAddDialog(open) {
        this.setState({
            toggledAddDialog: open
        });
    }

    toggleDeleteDialog(open) {
        this.setState({
            toggledDeleteDialog: open
        });
    }

    onChangeUsername(e) {
        const value = e.target.value;
        let error = false;
        let lengthError = false;
        if (value.length === 0) {
            error = true;
        }
        if (value.length < 3 || value.length > 50)
        {
            lengthError = true;
        }
        this.setState({
            fullname: value,
            errors: {
                ...this.state.errors,
                fullname: error,
                fullnameLength: lengthError
            }
        });
    }

    onChangeLogin(e) {
        const value = e.target.value;
        let error = false;
        let lengthError = false;
        if (value.length === 0) {
            error = true;
        }
        if (value.length > 50) {
            lengthError = true;
        }
        this.setState({
            username: e.target.value,
            errors: {
                ...this.state.errors,
                username: error,
                usernameLength: lengthError
            }
        });
    }

    onChangeEmail(e) {
        const value = e.target.value;
        let formatError = false;
        if (value.length > 0) {
            if (!isEmail(value)) {
                formatError = true;
            }
        }
        this.setState({
            email: e.target.value,
            errors: {
                ...this.state.errors,
                emailFormat: formatError
            }
        });
    }

    onChangePassword(e) {
        let passwordLength = 0;
        this.props.settings.forEach(setting => {
            if(setting.name === "password_length")
                passwordLength = setting.value;
        });

        const value = e.target.value;
        let error = false;
        let lengthError = false;
        if (value.length === 0) {
            error = true;
        }
        if (value.length < passwordLength || value.length > 60) {
            lengthError = true;
        }
        this.setState({
            password: e.target.value,
            errors: {
                ...this.state.errors,
                password: error,
                passwordLength: lengthError
            }
        });
    }

    onChangeConfirmPassword(e) {
        const value = e.target.value;
        let error = false;
        let passwordError = false;
        if (value.length === 0) {
            error = true;
        }
        if (this.state.password !== this.state.confirmPassword) {
            passwordError = true;
        }
        this.setState({
            confirmPassword: e.target.value,
            errors: {
                ...this.state.errors,
                confirmPassword: error,
                confirmPasswordEquals: passwordError
            }
        });
    }

    onChangePhone(value) {
        let formatError = false;
        if (value) {
            if (!isValidPhoneNumber(value)) {
                formatError = true;
            }
        }
        this.setState({
            phone: value,
            errors: {
                ...this.state.errors,
                phoneFormat: formatError
            }
        });
    }

    onChangeRoles(value, event) {
        if (event.action === "select-option" && event.option.value === "*") {
            this.setState(this.options);
        } else if (
            event.action === "deselect-option" &&
            event.option.value === "*"
        ) {
            this.setState([]);
        } else if (event.action === "deselect-option") {
            this.setState(value.filter((o) => o.value !== "*"));
        } else if (value.length === this.options.length - 1) {
            this.setState(this.options);
        } else {
            this.setState(value);
        }
    }

    onChangeStatus(checked) {
        this.setState({
            locked: checked
        });
    }

    getDropdownButtonLabel({ placeholderButtonLabel, value }) {
        if (value && value.some((o) => o.value === "*")) {
            return `${placeholderButtonLabel}: All`;
        } else {
            return `${placeholderButtonLabel}: ${value.length} selected`;
        }
    }

    deleteElement() {
        const element = {...this.props.user};
        this.setState({
            toDelete: element
        });
        this.toggleDeleteDialog(true);
    }

    deleteUser(toDelete) {
        if (toDelete && this.state.toDelete) {
            deleteUser(this.state.toDelete.id, this.props.currentUser).then(() => {
                this.toggleDeleteDialog(false);
                window.location.reload();
            });
        } else {
            this.toggleDeleteDialog(false);
        }
    }

    handleSubmit(e) {
        e.preventDefault();

        const notModifiedRoles = this.state.notModified.roles.selectedRoles;
        const roles = this.state.roles.selectedRoles;

        const roleEquals = roles.length === notModifiedRoles.length && roles.every((e, i) => e.label === notModifiedRoles[i].label && e.value === notModifiedRoles[i].value);

        if (this.state.fullname !== this.state.notModified.fullname || this.state.username !== this.state.notModified.username ||
            this.state.email !== this.state.notModified.email || this.state.phone !== this.state.notModified.phone ||
            this.state.password !== this.state.notModified.password || this.state.notModified.locked !== this.state.locked || !roleEquals ) {

            this.setState({
                successful: false,
            });

            if (this.state.password.length > 7) {
                this.setState({
                    password: this.state.password
                })
            }
            else {
                // eslint-disable-next-line
                this.state.password = this.state.oldpassword;
            }


            updateUser(this.props.user.id, this.state.fullname, this.state.username, this.state.password, this.state.email ? this.state.email : null, this.state.phone ? this.state.phone : null, this.state.creation_date, this.state.roles.selectedRoles, this.state.locked, (this.state.notModified.locked !== this.state.locked) ? 0 : this.props.user.password_retry, this.props.currentUser)
                .then(() => {
                    this.setState({
                        successful: true,
                    });
                    window.location.reload();
                })
                .catch(() => {
                    this.setState({
                        successful: false,
                    });
                });
        } else {
            if (this.props.onClose) {
                this.props.onClose();
            }
        }
    }

    componentDidMount() {
        const user = this.props.user;
        const roleArray = user.roles.map(function(row) {
            return { label : row.name, value : row.id }
        });

        if (user) {
            this.setState({
                fullname: user.fullname,
                username: user.login,
                email: user.email,
                phone: user.phone,
                password: "",
                oldpassword: user.password,
                creation_date: user.creation_date,
                roles: {
                    ...this.state.roles,
                    roleList: this.props.roles,
                    selectedRoles: roleArray
                },
                locked: user.status.name === "Locked",
                notModified: {
                    ...this.state.notModified,
                    fullname: user.fullname,
                    username: user.login,
                    email: user.email,
                    phone: user.phone,
                    password: user.password,
                    oldpassword: user.password,
                    roles: {
                        ...this.state.roles,
                        selectedRoles: roleArray
                    },
                    locked: user.status.name === "Locked"
                }
            });
        }
    }

    render() {
        let passwordLength = 0;

        const {message, user, settings} = this.props;

        const formValid =  !this.state.errors.username
            && !this.state.errors.usernameLength && !this.state.errors.emailFormat && !this.state.errors.phoneFormat
            && !this.state.errors.password && !this.state.errors.passwordLength && this.state.password.length > 0
            && this.state.password.length > 0
            || this.state.confirmPassword.length > 0 || this.state.password === this.state.confirmPassword;

        const roleOptions = this.state.roles.roleList.map(function(row) {
            return { label : row.name, value : row.id }
        })

        settings.forEach(setting => {
            if(setting.name === "password_length")
                passwordLength = setting.value;
        });

        if(!user) return null;

        return (
            <div className="UserPane">
                <Row>
                    <Col lg={4} md={4} sm={12} xs={12}>
                        <Col lg={12} md={12} sm={6} xs={6}>
                            <img src={require(`../../../../${data.avatar}`)} className="profile-img-card"
                                 alt="profile-img" width={400} style={{marginTop: '50px'}}/>
                        </Col>
                    </Col>
                    <Col lg={7} md={7} sm={12} xs={12}>
                        <form onSubmit={this.handleSubmit} id="Update">
                            {!this.state.successful && (
                                <div>
                                    <FormGroup controlId="fullname"
                                             //  validationState={this.state.errors.fullname || this.state.errors.fullnameLength ? "error" : null}
                                    >
                                        <ControlLabel>{data.Form.fullname}</ControlLabel>
                                        <FormControl
                                            placeholder={data.Form.fullname}
                                            value={this.state.fullname}
                                            onChange={this.onChangeUsername}
                                        />
                                    </FormGroup>
                                    <FormGroup controlId="username"
                                               validationState={this.state.errors.username || this.state.errors.usernameLength ? "error" : null}>
                                        <ControlLabel>{data.Form.username}</ControlLabel>
                                        <FormControl
                                            placeholder={data.Form.username}
                                            value={this.state.username}
                                            onChange={this.onChangeLogin}
                                        />
                                        {this.state.errors.username ?
                                            <HelpBlock>{data.Error.mandatory}</HelpBlock> : null}
                                        {this.state.errors.usernameLength ?
                                            <HelpBlock>{data.Error.usernameLength}</HelpBlock> : null}
                                    </FormGroup>
                                    <FormGroup controlId="email" validationState={this.state.errors.emailFormat ? "error" : null}>
                                        <ControlLabel>{data.Form.email}</ControlLabel>
                                        <FormControl
                                            placeholder={data.Form.email}
                                            type="email"
                                            value={this.state.email}
                                            onChange={this.onChangeEmail}
                                        />
                                        {this.state.errors.emailFormat ? <HelpBlock>{data.Error.mailValidator}</HelpBlock> : null}
                                    </FormGroup>
                                    <FormGroup controlId="phone" validationState={this.state.errors.phoneFormat ? "error" : null}>
                                        <ControlLabel>Phone</ControlLabel>
                                        <PhoneInput
                                            placeholder="Phone"
                                            flags={flags}
                                            value={this.state.phone}
                                            onChange={this.onChangePhone}

                                        />
                                        {this.state.errors.phoneFormat ? <HelpBlock>Phone non valide!</HelpBlock> : null}
                                    </FormGroup>
                                    <FormGroup controlId="password"
                                               validationState={this.state.errors.password || this.state.errors.passwordLength ? "error" : null}>
                                        <ControlLabel>{data.Form.password}</ControlLabel>
                                        <FormControl
                                            placeholder={data.Form.password}
                                            type="password"
                                            value={this.state.password}
                                            onChange={this.onChangePassword}
                                        />
                                        {this.state.errors.password ?
                                            <HelpBlock>{data.Error.mandatory}</HelpBlock> : null}
                                        {this.state.errors.passwordLength ?
                                            <HelpBlock>{data.Error.passwordLengthStart} {passwordLength} {data.Error.passwordLengthEnd}</HelpBlock> : null}
                                    </FormGroup>
                                    <FormGroup controlId="confirmPassword"
                                               validationState={this.state.errors.confirmPassword || this.state.password !== this.state.confirmPassword ? "error" : null}>
                                        <ControlLabel>{data.Form.passwordConfirm}</ControlLabel>
                                        <FormControl
                                            placeholder={data.Form.passwordConfirm}
                                            type="password"
                                            value={this.state.confirmPassword}
                                            onChange={this.onChangeConfirmPassword}
                                        />
                                        {this.state.errors.confirmPassword ?
                                            <HelpBlock>{data.Error.mandatory}</HelpBlock> : null}
                                        {this.state.password !== this.state.confirmPassword ?
                                            <HelpBlock>{data.Error.passwordConfirm}</HelpBlock> : null}
                                    </FormGroup>
                                    <FormGroup controlId="roles" className="float-left">
                                            <ReactMultiSelectCheckboxes
                                                options={[
                                                    {label: "All", value: "*"},
                                                    ...roleOptions
                                                ]}
                                                placeholderButtonLabel="Roles"
                                                getDropdownButtonLabel={this.getDropdownButtonLabel}
                                                value={this.state.roles.selectedRoles}
                                                onChange={this.onChangeRoles}
                                                setState={(e) => this.setState({roles: {...this.state.roles, selectedRoles: e}})}
                                            />
                                        </FormGroup>
                                        <FormGroup controlId="status" className="status float-right">
                                            <label>
                                                <span>{data.Form.locked} :</span>
                                                <Switch
                                                    checked={this.state.locked}
                                                    onChange={this.onChangeStatus}
                                                    name="locked"
                                                    offColor="#008700"
                                                    onColor="#fa3a3a"
                                                    checkedIcon={
                                                        <div className="status-locked">
                                                            <i className="fa fa-lock" aria-hidden="true"/>
                                                        </div>
                                                    }
                                                    uncheckedIcon={
                                                        <div className="status-unlocked">
                                                            <i className="fa fa-unlock-alt" aria-hidden="true"/>
                                                        </div>
                                                    }
                                                />
                                            </label>
                                        </FormGroup>
                                    {user.roles && user.roles.some((o) => o.name === "Secretariat") &&
                                        <div style={{marginBottom: "15px", marginTop: "60px"}}>
                                            <Button bsSize="large" onClick={() => this.toggleAddDialog(true)}>
                                                {data.Form.manageDoctor}
                                            </Button>
                                        </div>
                                    }
                                </div>
                            )}
                            {message && (
                                <div className="form-group">
                                    <div
                                        className={this.state.successful ? "alert alert-success" : "alert alert-danger"}
                                        role="alert">
                                        {message}
                                    </div>
                                </div>
                            )}
                        </form>
                    </Col>
                </Row>
                <div className="delete-user">
                    <Button bsSize="large"
                            variant={"outlined"}
                            className="border-danger delete"
                            onClick={() => this.deleteElement()}
                    >
                        {data.Buttons.delete}
                    </Button>
                </div>
                <div className="update-user">
                    <Button bsSize="large" disabled={!formValid} type="submit" form="Update">
                        {data.Buttons.submit}
                    </Button>
                </div>
                <ConfirmationDialog
                    isOpen={this.state.toggledDeleteDialog}
                    handleClose={this.deleteUser}
                    texts={this.state.deleteConfirmationDialog}
                />
                <AddUserPaneDialog
                    isOpen={this.state.toggledAddDialog}
                    userId={user.id}
                    onClose={() => this.toggleAddDialog(false)}
                />
            </div>
        );
    }
}

UpdateUserPaneContent.propTypes = {
    user: PropTypes.any,
    roles: PropTypes.any,
    status: PropTypes.any,
    onClose: PropTypes.func
}

function mapStateToProps(state) {
    const {message} = state.message;
    const {user} = state.auth;
    const {settings} = state.settings;

    return {
        message,
        currentUser: user,
        settings
    };
}

export default connect(mapStateToProps)(UpdateUserPaneContent);