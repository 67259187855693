import React, {Component} from "react";
import {Button} from "@material-ui/core";
import IconContentDialog from "./IconContentDialog";
import {faQuestion} from "@fortawesome/free-solid-svg-icons";
import PropTypes from 'prop-types';

export default class ConfirmationDialog extends Component {

    constructor(props) {
        super(props);
        this.closeDialog = this.closeDialog.bind(this);
    }

    render() {
        const {texts} = this.props;

        const actions = (
            <div className="container-fluid">
                <div className="row">
                    <div className="col text-center">
                        <Button variant={"outlined"} className="text-danger mx-2" onClick={() => this.closeDialog(false)}>{!texts || !texts.cancel ? "Cancel" : texts.cancel}</Button>
                        <Button variant={"outlined"} className="text-success mx-2" onClick={() => this.closeDialog(true)}>{!texts || !texts.validate ? "Yes" : texts.validate}</Button>
                    </div>
                </div>
            </div>
        )

        return (
            <IconContentDialog
                title={!texts || !texts.title ? "Are you sure ?" : texts.title}
                icon={faQuestion}
                actions={actions}
                isOpen={this.props.isOpen}
                handleClose={() => this.closeDialog(false)}
            />
        );
    }

    closeDialog(result) {
        if (this.props.handleClose) {
            this.props.handleClose(result)
        }
    }
}

ConfirmationDialog.propTypes = {
    isOpen: PropTypes.bool,
    handleClose: PropTypes.func,
    texts: PropTypes.object
}


