import React, {Component} from "react";
import PropTypes from "prop-types";
import CallableDialog from "../../../../nsi-lib/components/modals/CallableDialog";
import CreateUserPaneContent from "../content/CreateUserPaneContent";

import "../../User.css";

export default class CreateUserPaneDialog extends Component {
    constructor(props) {
        super(props);

        this.closeDialog = this.closeDialog.bind(this);
    }

    closeDialog() {
        if (this.props.onClose) {
            this.props.onClose();
        }
    }

    render() {
        const {roles, isOpen} = this.props;

        return (
            <CallableDialog
                isOpen={isOpen}
                handleClose={this.closeDialog}
                fullwidth
                content={
                    <div>
                        <button className="close-button" onClick={() => this.closeDialog()}>
                            <i className="fa fa-times fa-2x" />
                        </button>
                        <CreateUserPaneContent
                            roles={roles}
                            onClose={() => this.closeDialog()}
                        />
                    </div>
                }
            />
        );
    }
}

CreateUserPaneDialog.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    roles: PropTypes.any
}
