import React, {Component} from "react";
import PropTypes from "prop-types";
import {Button, ControlLabel, FormControl, FormGroup, HelpBlock} from "react-bootstrap";
import TextContent from "../../../text-content/TextContent";
import PhoneInput, {isValidPhoneNumber} from 'react-phone-number-input';
import flags from 'react-phone-number-input/flags'

import 'react-phone-number-input/style.css';

const {secretariat} = TextContent(localStorage.getItem("language"));
const data = {
    fullname: secretariat.external.fullname,
    email: secretariat.external.email,
    phone: secretariat.external.phone,
    add: secretariat.external.add,
    edit: secretariat.external.edit,
    delete: secretariat.external.delete,
}

export default class AddExternalPaneContent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            information: {
                index: "",
                name: "",
                email: "",
                phone: ""
            },
            errors: {
                name: "",
                phoneFormat: "",
            }
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleChangePhone = this.handleChangePhone.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.deleteExternal = this.deleteExternal.bind(this);
    }

    handleChange(e) {
        this.setState({
            information: {
                ...this.state.information,
                [e.target.name]: e.target.value
            }
        });
    }

    handleChangePhone(value) {
        let formatError = false;
        if (value) {
            if (!isValidPhoneNumber(value)) {
                formatError = true;
            }
        }
        this.setState({
            information: {
                ...this.state.information,
                phone: value
            },
            errors: {
                ...this.state.errors,
                phoneFormat: formatError
            }
        });
    }

    validateForm = () => {
        return  !this.state.errors.phoneFormat;
    };

    deleteExternal() {
        if (this.props.content) {
            this.props.deleteExternal(this.state.information);
        }

        if (this.props.onClose) {
            this.props.onClose();
        }
    }

    handleSubmit(e) {
        e.preventDefault();

        if (this.props.content) {
            this.props.editExternal(this.state.information);
        } else {
            this.props.getExternal(this.state.information);
        }

        if (this.props.onClose) {
            this.props.onClose();
        }
    }

    componentDidMount() {
        const content = this.props.content;

        if (content) {
            this.setState({
                information: {
                   ...this.state.information,
                    index: this.props.index,
                    name: content.name,
                    email: content.email,
                    phone: content.phone
               }
            });
        }
    }

    render() {

        const formValid = !this.state.errors.phoneFormat && this.state.information.name.length > 0;


        return (
            <div className="AddExternalPane">
                <form onSubmit={this.handleSubmit} id="AddExternals">
                    <div>
                        <FormGroup controlId="name" validationState={this.state.errors.name ? "error" : null}>
                            <ControlLabel>{data.fullname}</ControlLabel>
                            <FormControl
                                placeholder={data.fullname}
                                name="name"
                                value={this.state.information.name}
                                onChange={this.handleChange}
                            />
                        </FormGroup>
                        <FormGroup controlId="email">
                            <ControlLabel>{data.email}</ControlLabel>
                            <FormControl
                                placeholder={data.email}
                                name="email"
                                value={this.state.information.email}
                                onChange={this.handleChange}
                            />
                        </FormGroup>
                        {/*<FormGroup controlId="phone">
                            <ControlLabel>{data.phone}</ControlLabel>
                            <PhoneInput
                                placeholder={data.phone}
                                flags={flags}
                                value={this.state.information.phone}
                                onChange={this.handleChangePhone}
                            />
                        </FormGroup>*/}

                        <FormGroup controlId="phone" validationState={this.state.errors.phoneFormat ? "error" : null}>
                            <ControlLabel>{data.phone}</ControlLabel>
                            <PhoneInput
                                placeholder={data.phone}
                                flags={flags}
                                value={this.state.information.phone}
                                onChange={this.handleChangePhone}
                            />
                            {this.state.errors.phoneFormat ? <HelpBlock>Phone non valide!</HelpBlock> : null}
                        </FormGroup>




                    </div>
                </form>
                {this.props.content ?
                    <div>
                        <div className="delete-external">
                            <Button bsSize="large" variant={"outlined"} className="border-danger delete" onClick={this.deleteExternal}>
                                {data.delete}
                            </Button>
                        </div>
                        <div className="edit-external">
                            <Button bsSize="large" type="submit" form="AddExternals">
                                {data.edit}
                            </Button>
                        </div>
                    </div> :
                    <div className="add-external">
                        <Button bsSize="large" type="submit" form="AddExternals" disabled={!formValid}>
                            {data.add}
                        </Button>
                    </div>
                }
            </div>
        );
    }
}

AddExternalPaneContent.propTypes = {
    onClose: PropTypes.func,
    getExternal: PropTypes.any,
    editExternal: PropTypes.any,
    deleteExternal: PropTypes.any,
    content: PropTypes.any,
    index: PropTypes.any
}