import React, {Component} from "react";
import PropTypes from 'prop-types';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleDown, faAngleUp} from "@fortawesome/free-solid-svg-icons";
import Setting from "./Setting";
import {Collapse} from '@material-ui/core';

export default class CollapsableSetting extends Component {

    constructor(props) {
        super(props);

        this.state = {
            collapsed: this.props.collapsed !== undefined ? this.props.collapsed : true
        }

        this.toggleCollapse = this.toggleCollapse.bind(this);
    }

    toggleCollapse() {
        if (this.props.collapsed !== undefined) {
            if (this.props.onCollapseChange) {
                this.props.onCollapseChange(!this.props.collapsed);
            }
        } else {
            this.setState(state => {
                return {
                    ...state,
                    collapsed: !state.collapsed
                }
            });
        }
    }

    render() {
        const title = this.props.title ? this.props.title : 'No title';
        const icon = this.props.icon
        const content = this.props.collapseContent;
        const collapsed = this.props.collapsed !== undefined ? this.props.collapsed : this.state.collapsed;
        const size = this.props.size ? this.props.size : 'normal';

        const className = this.props.className;
        const settingClassName = this.props.settingClassName;
        const titleClassName = this.props.titleClassName;
        const iconActionClassName = this.props.iconActionClassName;
        const collapseClassName = this.props.collapseClassName;

        const collapse = (
            <div>
                {content &&
                <Collapse in={!collapsed}>
                    {content}
                </Collapse>
                }
            </div>
        );

        /***
         @Deprecated
         */
        let iconComp = null;
        if (icon) {
            iconComp = (
                <FontAwesomeIcon
                    icon={icon}
                    size={"lg"}
                />
            )
        }

        const titleComp = (
            <div>
                {size === 'normal' &&
                <h5
                    className="text-dark" role="button"
                    onClick={() => this.toggleCollapse()}>
                    {title}
                </h5>
                }
                {size === 'small' &&
                <h6
                    className="text-dark" role="button"
                    onClick={() => this.toggleCollapse()}>
                    {title}
                </h6>
                }
            </div>
        );

        let iconSize = "2x";
        switch (size) {
            case "small":
                iconSize = "lg";
                break;
            case "normal":
                iconSize = "2x";
                break;
            default: console.log("Problem with icon size")
        }

        let actionComp = null;
        if (collapsed) {
            actionComp = (
                <FontAwesomeIcon
                    icon={faAngleDown}
                    size={iconSize}
                    onClick={() => this.toggleCollapse()}
                    role="button"
                />
            );
        } else {
            actionComp = (
                <FontAwesomeIcon
                    icon={faAngleUp}
                    size={iconSize}
                    onClick={() => this.toggleCollapse()}
                    role="button"
                />
            );
        }

        return (
            <div className={"row " + className}>
                <div className="col">
                    <div className="row">
                        <div className={"col " + settingClassName}>
                            <Setting
                                title={titleComp}
                                icon={iconComp}
                                action={actionComp}
                                actionClassName={"align-self-start " + iconActionClassName}
                                titleClassName={"align-self-start " + titleClassName}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className={"col " + collapseClassName}>
                            {collapse}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

CollapsableSetting.propTypes = {
    title: PropTypes.string.isRequired,
    collapseContent: PropTypes.node.isRequired,
    icon: PropTypes.any,
    size: PropTypes.oneOf(['normal', 'small']),
    collapsed: PropTypes.bool,
    onCollapseChange: PropTypes.func,
    className: PropTypes.any,
    settingClassName: PropTypes.any,
    titleClassName: PropTypes.any,
    iconActionClassName: PropTypes.any,
    collapseClassName: PropTypes.any
}
