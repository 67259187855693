import React from 'react';
import {NavLink} from "react-router-dom";
import DrawerToggleButton from '../SideDrawer/DrawerToggleButton';
import './Toolbar.css';
import {DropdownButton, MenuItem} from "react-bootstrap";
import LanguageSelector from "../../language-selector/LanguageSelector";
import TextContent from "../../../components/text-content/TextContent";
import {connect} from "react-redux";

const { resources, navbar } = TextContent(localStorage.getItem("language"));
const data = (
    {
        logo: resources.navbar.Logo,
        buttonMenu: resources.navbar.ButtonMenu,
        buttonMenuDisconnected: resources.navbar.ButtonMenuDisconnected,
        Tab1: navbar.Tab_1,
        Tab2: navbar.Tab_2,
        Tab3: navbar.Tab_3,
        Tab4: navbar.Tab_4,
        Tab4bis: navbar.Tab_4bis,
        Tab5: navbar.Tab_5,
        Tab51 : navbar.Tab_51,
        Tab52 : navbar.Tab_52,
        Tab53 : navbar.Tab_53,
        welcome: navbar.welcome
    }
)

const Toolbar = props => (
    <header className="toolbar">
        <nav className="toolbar__navigation">
            <div className="toolbar__logo">
                <NavLink to="/">
                    <img src={require(`../../../${data.logo}`)} alt="" height="70"/>
                </NavLink>
            </div>
            <div className="spacer"/>
            <div className="toolbar_navigation-items">
                <ul>
                    <li><NavLink exact to="/" activeClassName="active">{data.Tab1}</NavLink></li>
                    <li><NavLink exact to="/contact">{data.Tab2}</NavLink></li>
                    <li><NavLink exact to="/help">{data.Tab3}</NavLink></li>
                </ul>
            </div>
            <DropdownButton
                bsStyle="default"
                title={
                    <img src={require(`../../../${props.isLoggedIn ? data.buttonMenu : data.buttonMenuDisconnected}`)} alt="" height="30"/>
                }
                noCaret
                className="dropdown_button"
                id="dropdown-no-caret"
            >
                {props.currentUser ? (
                    <>
                        <MenuItem key="profile" eventKey={1} href="/profile">{data.Tab5}</MenuItem>
                        {props.currentUser.roles && props.currentUser.roles.map(role =>
                            role === "Admin" &&
                            <MenuItem key="admin" eventKey={2} href="/administration">{data.Tab51}</MenuItem>)
                        }
                        {props.currentUser.roles && props.currentUser.roles.map(role =>
                            role === "Secretariat" ?
                                <MenuItem key="secretariat" eventKey={2} href="/secretariat">{data.Tab52}</MenuItem>
                                :
                            role === "Doctor" &&
                                <MenuItem key="secretariat" eventKey={2} href="/doctor">{data.Tab53}</MenuItem>
                        )}
                        <MenuItem divider />
                        <MenuItem key="login" eventKey={2} href="/login" onClick={props.logOut}>{data.Tab4bis}</MenuItem>
                    </>
                ) : (
                    <MenuItem key="login" eventKey={1} href="/login">{data.Tab4}</MenuItem>
                )}
            </DropdownButton>
            {props.currentUser && <span className="toolbar_navigation-items" style={{fontSize: "15px"}}>{data.welcome} {props.currentUser.fullname} !</span>}
            <div className="languages">
                <LanguageSelector />
            </div>
            <div className="toolbar__toggle-button">
                <DrawerToggleButton click={props.drawerClickHandler}/>
            </div>
        </nav>
    </header>
);

function mapStateToProps(state) {
    const {isLoggedIn} = state.auth;
    return {
        isLoggedIn
    };
}

export default connect(mapStateToProps)(Toolbar);
