import React, {Component} from "react";
import PropTypes from 'prop-types';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

export default class CallableDialog extends Component {

    constructor(props) {
        super(props);
        this.handleClose = this.handleClose.bind(this);
    }

    render() {
        const title = this.props.title;
        const isOpen = this.props.isOpen ? this.props.isOpen : false;
        const content = this.props.content ? this.props.content : null;
        const actions = this.props.actions ? this.props.actions : null;
        const fullscreen = this.props.fullscreen;
        const fullwidth = this.props.fullwidth;
        const maxWidth = this.props.maxWidth;
        return (
            <Dialog
                onClose={this.handleClose}
                aria-labelledby="callable-dialog-title"
                open={isOpen}
                scroll={"paper"}
                fullWidth={fullwidth}
                fullScreen={fullscreen}
                maxWidth={maxWidth}
                className={this.props.dialogClassName}
            >
                <div className={"py-4 " + this.props.dialogContainerClassName}>
                    {title &&
                    <DialogTitle id="callable-dialog-title">{title}</DialogTitle>
                    }
                    {content &&
                    <DialogContent>
                        {content}
                    </DialogContent>
                    }
                    {actions &&
                    <DialogActions>
                        {actions}
                    </DialogActions>
                    }
                </div>
            </Dialog>
        );
    }

    handleClose(e) {
        if (this.props.handleClose) {
            this.props.handleClose(e);
        }
    }

}

CallableDialog.propTypes = {
    isOpen: PropTypes.bool,
    title: PropTypes.string,
    content: PropTypes.node,
    actions: PropTypes.node,
    handleClose: PropTypes.func,
    fullscreen: PropTypes.bool,
    fullwidth: PropTypes.bool,
    maxWidth: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', false]),
    dialogClassName: PropTypes.string,
    dialogContainerClassName: PropTypes.string
}
