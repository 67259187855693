import React, {Component} from "react";
import CallableDialog from "./CallableDialog";
import CircularProgress from "@material-ui/core/CircularProgress";

export default class ProgressDialog extends Component {



    render() {
        const isOpen = this.props.isOpen ? this.props.isOpen : false;

        const content = (
            <CircularProgress />
        );

        return (
            <CallableDialog
                isOpen={isOpen}
                content={content}
            />
        );
    }


}
