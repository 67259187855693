import React, {Component} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUserLock} from "@fortawesome/free-solid-svg-icons";
import {Button} from "@material-ui/core";
import {Link} from "react-router-dom";

export default class UnauthorizedPage extends Component {



    render() {
        return (
            <div className="container py-5">
                <div className="row">
                    <div className="col text-center">
                        <FontAwesomeIcon
                            icon={faUserLock}
                            size={"10x"}
                            className="text-danger"
                        />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col text-center">
                        <h5>You don't have the rights to see more...</h5>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col text-center">
                        <Link to={"/"}>
                            <Button
                                variant={"outlined"}
                            >
                                Return to unrestricted area.
                            </Button>
                        </Link>
                    </div>
                </div>
            </div>
        );
    }

}
