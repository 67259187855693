import React, {Component} from "react";
import PropTypes from 'prop-types';
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import {Button} from "@material-ui/core";

export default class CustomizableStepper extends Component {

    constructor(props) {
        super(props);

        this.handleBack = this.handleBack.bind(this);
        this.handleNext = this.handleNext.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleReset = this.handleReset.bind(this);
    }

    handleBack() {
        if (this.props.handleBack) {
            this.props.handleBack(this.props.activeStep, this.props.activeStep - 1);
        }
    }

    handleNext() {
        if (this.props.handleNext) {
            this.props.handleNext(this.props.activeStep, this.props.activeStep + 1);
        }
    }

    handleSubmit() {
        if (this.props.handleSubmit) {
            this.props.handleSubmit();
        }
    }

    handleReset() {
        if (this.props.handleReset) {
            this.props.handleReset();
        }
    }

    render() {
        const activeStep = this.props.activeStep ? this.props.activeStep : 0;
        const orientation = this.props.orientation ? this.props.orientation : 'horizontal';
        const titles = this.props.stepsTitles;
        const contents = this.props.stepsContents;

        // Classes
        const className = this.props.className ? this.props.className : '';
        const stepperClassname = this.props.stepperClassName ? this.props.stepperClassName : '';
        const contentClassName = this.props.contentClassName ? this.props.contentClassName : '';
        const actionClassName = this.props.actionClassName ? this.props.actionClassName : '';

        if (!titles || !contents || titles.length !== contents.length) return null;

        return (
            <div className="row">
                <div className={className + " col"}>
                    <div className="row">
                        <div className="col">
                            <Stepper
                                activeStep={activeStep}
                                alternativeLabel={orientation === 'horizontal'}
                                orientation={orientation}
                                className={stepperClassname}
                            >
                                {titles.map((label, index) => (
                                    <Step key={label}>
                                        <StepLabel>{label}</StepLabel>
                                        {orientation === 'vertical' &&
                                        <StepContent>
                                            {getStepContent(contents, index)}
                                        </StepContent>
                                        }
                                    </Step>
                                ))}
                            </Stepper>
                        </div>
                    </div>
                    {orientation === 'horizontal' &&
                    <div className="row">
                        <div className={contentClassName + " col"}>
                            {getStepContent(contents, activeStep)}
                        </div>
                    </div>
                    }
                    <div className="row">
                        <div className={actionClassName + " col text-right"}>
                            {this.props.handleBack && activeStep > 0 &&
                            <Button variant={"outlined"} size={"small"} className="mr-2"
                                    onClick={() => this.handleBack()}
                            >
                                Back
                            </Button>
                            }
                            {this.props.handleNext && activeStep < contents.length - 1 &&
                            <Button variant={"outlined"} size={"small"} className="mr-2"
                                    onClick={() => this.handleNext()}
                            >
                                Next
                            </Button>
                            }
                            {this.props.handleReset && activeStep === contents.length - 1 &&
                            <Button variant={"contained"} size={"small"} className="mr-2 bg-warning text-light"
                                    onClick={() => this.handleReset()}
                            >
                                Reset
                            </Button>
                            }
                            {this.props.handleSubmit && activeStep === contents.length - 1 &&
                            <Button variant={"contained"} size={"small"} className="bg-success text-light"
                                    onClick={() => this.handleSubmit()}
                            >
                                Finish
                            </Button>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

function getStepContent(contents, index) {
    if (contents && index != null && index >= 0 && index <= contents.length - 1) {
        return contents[index];
    } else {
        return (
            <p>Unknown step.</p>
        )
    }
}

CustomizableStepper.propTypes = {
    activeStep: PropTypes.number,
    orientation: PropTypes.oneOf(['vertical', 'horizontal']),
    stepsTitles: PropTypes.arrayOf(PropTypes.string).isRequired,
    stepsContents: PropTypes.arrayOf(PropTypes.node).isRequired,
    handleNext: PropTypes.func.isRequired,
    handleBack: PropTypes.func,
    handleSubmit: PropTypes.func.isRequired,
    handleReset: PropTypes.func,
    className: PropTypes.string,
    stepperClassName: PropTypes.string,
    actionClassName: PropTypes.string,
    contentClassName: PropTypes.string
}
