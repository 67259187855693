import React, {Component} from "react";
import PropTypes from "prop-types";
import CallableDialog from "../../../../nsi-lib/components/modals/CallableDialog";
import TextContent from "../../../text-content/TextContent";
import SummaryPaneContent from "../content/SummaryPaneContent";

const {secretariat} = TextContent(localStorage.getItem("language"));
const data = {
    title: secretariat.meetingCreation.summary.title
}

export default class SummaryPaneDialog extends Component {
    constructor(props) {
        super(props);

        this.closeDialog = this.closeDialog.bind(this);
    }

    closeDialog() {
        if (this.props.onClose) {
            this.props.onClose();
        }
    }

    render() {
        const {isOpen, content, validateSchedule} = this.props;

        return (
            <CallableDialog
                isOpen={isOpen}
                handleClose={this.closeDialog}
                fullwidth
                content={
                    <div>
                        <h3>{data.title} : </h3>
                        <br />
                        <button className="meeting-close-button" onClick={() => this.closeDialog()}>
                            <i className="fa fa-times fa-2x" />
                        </button>
                        <SummaryPaneContent
                            content={content}
                            onClose={this.closeDialog}
                            validateSchedule={(shouldSendEmail, shouldSendSMS) => validateSchedule(shouldSendEmail, shouldSendSMS)}
                        />
                    </div>
                }
            />
        );
    }
}

SummaryPaneDialog.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    content: PropTypes.any,
    validateSchedule: PropTypes.any
}