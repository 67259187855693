import React, {Component} from "react";
import {Button, Col, ControlLabel, FormControl, FormGroup, HelpBlock, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import WhitePanel from "../white-panel/WhitePanel";
import {confirmToken, resetPassword} from "../../services/auth/forgetPassword";
import TextContent from "../text-content/TextContent";

const {authentication} = TextContent(localStorage.getItem("language"));
const data = {
    reset_password:authentication.login.reset_password,
    mail:authentication.login.mail,
    password:authentication.login.password,
    confirm_password:authentication.login.confirm_password,
    helpblock2:authentication.login.helpblock2,
    send: authentication.login.send,
    invalid: authentication.login.invalid

}

export default class ResetPassword extends Component {

    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onChangePassword = this.onChangePassword.bind(this);
        this.onChangeConfirmPassword= this.onChangeConfirmPassword.bind(this);

        this.state = {
            email: "",
            password:"",
            confirmPassword:"",
            statusToken:"",
            status:"",
            errors: {
                email: "",
                password:"",
            },
            successful: false,
            message: ""
        };



    }



    onChangePassword(e) {
        const value = e.target.value;
        let error = false;
        if (value.length === 0) {
            error = true;
        }
        this.setState({
            password: e.target.value,
            errors: {
                ...this.state.errors,
                password: error
            }
        });
    }

    onChangeConfirmPassword(e) {
        const value = e.target.value;
        let error = false;
        if (value.length === 0) {
            error = true;
        }
        this.setState({
            confirmPassword: e.target.value,
            errors: {
                ...this.state.errors,
                confirmPassword: error
            }
        });
    }



    componentDidMount() {

        let token = new URLSearchParams(this.props.location.search).get("token")


       new Promise((resolve, reject) => {
            confirmToken(token).then(value => {

                this.setState({
                    email: value.data.email
                })

                this.setState({
                    statusToken:"VALID",
                    email: value.data.email
                })
            }).catch(() => {
                this.setState({
                    statusToken:"INVALID"
                })
            })

        });


    }

    sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds))
    }

    handleSubmit(e) {
        e.preventDefault();

        if (this.state.password === this.state.confirmPassword){
            new Promise((resolve, reject) => {
                resetPassword(this.state.email, this.state.password).then(value =>
                {
                    this.setState({
                        status: value.status
                    })

                    window.setTimeout(function(){
                        window.location.href = "/login";
                    }, 3000)
                }).catch(() => {
                    console.log("c'est pas envoyé")
                })

            });
        }

    }

    render() {

        let formValid = this.state.password === this.state.confirmPassword && this.state.password.length > 7;


        return (
            <div className="content_forget">
                {this.state.statusToken === "VALID" ?

                <WhitePanel>
                    <div className="row">
                        <Col lg={4} lgOffset={4} mdOffset={3} md={10}>
                            <h1 className="title_forget">{data.reset_password}</h1>
                        </Col>
                        <Col lg={1} lgOffset={3} mdOffset={1} md={1}>
                            <Link to="/">
                                <i className="fa fa-times fa-2x" />
                            </Link>
                        </Col>
                    </div>
                    <img src={require("../../static/images/forgot-password.png")} className="forgot-img"
                         alt="profile-img" />
                    <form onSubmit={this.handleSubmit}>

                    <div className="row">
                        <Col lgOffset={3} lg={6}>
                            <FormGroup controlId="Email">
                                <ControlLabel>{data.mail}</ControlLabel>
                                <FormControl
                                    placeholder={data.mail}
                                    value={this.state.email}
                                />
                            </FormGroup>
                        </Col>
                    </div>
                    <div className="row">
                        <Col lgOffset={3} lg={6}>
                        <FormGroup controlId="Password">
                            <ControlLabel>{data.password}</ControlLabel>
                            <FormControl
                                placeholder=""
                                type="password"
                                value={this.state.password}
                                onChange={this.onChangePassword}
                            />
                        </FormGroup>
                        </Col>
                    </div>
                    <div className="row">
                        <Col lgOffset={3} lg={6} id="form_password">
                            <FormGroup controlId="Confirm password">
                                <ControlLabel>{data.confirm_password}</ControlLabel>
                                <FormControl
                                    placeholder=""
                                    type="password"
                                    value={this.state.confirmPassword}
                                    onChange={this.onChangeConfirmPassword}
                                />
                            </FormGroup>
                            {this.state.status ?
                                <HelpBlock>{data.helpblock2}</HelpBlock> : null}
                        </Col>
                    </div>


                    <br/>
                    <br/>

                    <div className="text-right">
                        <Button bsSize="large"  type="submit" disabled={!formValid}>
                            {data.send}
                        </Button>
                    </div>
                    </form>
                </WhitePanel>
                : <WhitePanel>
                        <div className="row">
                            <Col lg={6} lgOffset={3} mdOffset={3} md={10}>
                                <h1 className="title_forget">{data.invalid}</h1>
                            </Col>
                            <Col lg={1} lgOffset={2} mdOffset={1} md={1}>
                                <Link to="/">
                                    <i className="fa fa-times fa-2x" />
                                </Link>
                            </Col>
                        </div>
                        <img src={require("../../static/images/forgot-password.png")} className="forgot-img"
                             alt="profile-img" />

                    </WhitePanel>

                }
            </div>
        )
    }
}

