import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Button, Col, Row, Table} from "react-bootstrap";
import {
    getAssistedUsers,
    getDoctors,
    updateUserAssistant
} from "../../../../services/user/userService";
import TextContent from "../../../text-content/TextContent";

import Select from "react-select";

import "../../User.css";

const {authentication} = TextContent(localStorage.getItem("language"));
const data = {
    doctorAssociate: authentication.add.doctorAssociate,
    validate : authentication.add.validate,
}

class AddUserPaneContent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            doctors: [],
            assistedDoctors: [],
            previousAssistedDoctors: [],
            message: ""
        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.addAssistant = this.addAssistant.bind(this);
    }

    addAssistant(doctor) {
        this.setState(prevState => ({
            assistedDoctors: [...prevState.assistedDoctors, doctor]
        }));
    }

    removeAssistant(doctor) {
        this.setState(prevState => ({
            assistedDoctors: prevState.assistedDoctors.filter(doctorToDelete => doctorToDelete.fullname !== doctor.fullname)
        }));
    }

    customFilter(option, searchText) {
        try {
            return option.data.fullname.toLowerCase().includes(searchText.toLowerCase()) ||
                option.data.login.toLowerCase().includes(searchText.toLowerCase());
        } catch {
            return null;
        }
    }

    handleSubmit() {
        if(JSON.stringify(this.state.previousAssistedDoctors) !== JSON.stringify(this.state.assistedDoctors)) {
            return new Promise((() => {
                updateUserAssistant(this.props.userId, this.state.assistedDoctors, this.props.currentUser).then(() => {
                    this.props.onClose();
                }).catch(() => {
                    this.setState({message: "An error occurred"});
                })
            }));
        } else {
            this.props.onClose();
        }
    }

    componentDidMount() {

        return new Promise(((resolve, reject) => {
            getDoctors(this.props.currentUser).then(value => {
                resolve(this.setState({
                    doctors: value.data
                }));
            }).catch(reason => {
                reject(reason);
            });
            getAssistedUsers(this.props.currentUser, this.props.userId).then(value => {
                resolve(this.setState({
                    assistedDoctors: value.data,
                    previousAssistedDoctors: value.data
                }));
            }).catch(reason => {
                reject(reason);
            });
        }));
    }

    render() {
        let i = 0;
        const nonAssistedDoctors = this.state.doctors.filter(({fullname: name1}) => !this.state.assistedDoctors.some(({fullname: name2}) => name2 === name1));

        return (
            <div className="AddUserPaneContent">
                <Row>
                    <Col md={12}>
                        <Select
                            className="react-selectcomponent doctor-selector"
                            classNamePrefix="name-select"
                            onChange={this.addAssistant}
                            getOptionLabel={option => option.fullname}
                            getOptionValue={option => `${option}`}
                            options={nonAssistedDoctors}
                            isSearchable={true}
                            filterOption={this.customFilter}
                            noOptionsMessage={() => null}
                            placeholder={'Enter Name'}
                            autoFocus={true}
                        />
                    </Col>
                </Row>
                <Table striped bordered condensed hover>
                    <thead>
                        <tr className="table_title" style={{textAlign: "center"}}>
                            <th>{data.doctorAssociate}</th>
                        </tr>
                    </thead>
                    <tbody>
                    {this.state.doctors.map((doctor, index) =>
                        this.state.assistedDoctors.some((o) => o.fullname === doctor.fullname) &&
                        <tr key={index}>
                            <td>
                                <div>
                                    <p style={{float: "left"}}>
                                        {doctor.fullname}
                                    </p>
                                    <p style={{float: "right"}}>
                                        <i className="fa fa-minus remove" onClick={() => this.removeAssistant(doctor)} />
                                    </p>
                                </div>
                            </td>
                        </tr>
                    )}
                    </tbody>
                </Table>
                <Button bsSize="large" style={{float: "right"}} onClick={() => this.handleSubmit()}>
                    {data.validate}
                </Button>
            </div>
        );
    }
}

AddUserPaneContent.propTypes = {
    userId: PropTypes.any,
    onClose: PropTypes.func
}

function mapStateToProps(state) {
    const {user} = state.auth;
    return {
        currentUser: user,
    };
}

export default connect(mapStateToProps)(AddUserPaneContent);