import React from 'react';
import { Col, Row, FormGroup, FormControl, ControlLabel, Button, HelpBlock, Alert } from "react-bootstrap";
import WhitePanel from '../../components/white-panel/WhitePanel';
import "./Contact.css";
import TextContent from "../../components/text-content/TextContent";

import PhoneInput from "react-phone-number-input";
import flags from "react-phone-number-input/flags";
import { isValidPhoneNumber } from 'react-phone-number-input'

const { contact, resources } = TextContent(localStorage.getItem("language"));
const data = {
    Title: contact.Title,
    facebook: resources.contact.link.Facebook,
    linkedin: resources.contact.link.Linkedin,
    instagram: resources.contact.link.Instagram,
    instaLogo: resources.contact.instagram,
    contactButton: resources.contact.button,

    website: contact.website,
    Company: {
        name: contact.Company.Name,
        phone: contact.Company.Phone,
        building: contact.Company.Building,
        address: contact.Company.Address,
        city: contact.Company.City
    },
    FormLabel: {
        fullname: contact.FormLabel.Fullname,
        phone: contact.FormLabel.Phone,
        mail: contact.FormLabel.Mail,
        message: contact.FormLabel.Message
    },
    FormPlaceholder: {
        fullname: contact.FormPlaceholder.Fullname,
        phone: contact.FormPlaceholder.Phone,
        mail: contact.FormPlaceholder.Mail,
        message: contact.FormPlaceholder.Message,
        ask: contact.FormPlaceholder.ask
    },
    Error: {
        mandatory: contact.Error.Mandatory,
        format: contact.Error.Format
    },
    Submit: contact.Submit
}

class Contact extends React.Component {

    constructor(props) {
        super(props);
        this.url = process.env.REACT_APP_API_URL;
        this.state = {
            user: "",
            phone: "",
            mail: "",
            message: "",
            first_number:"",
            second_number:"",
            isVerified: "",
            errors: {
                user: false,
                mail: false,
                phoneFormat: "",
                mailRegex: false,
                message: false
            },
            postMessageCode: "",
            postMessage: "",
        };
        this.onChangePhone = this.onChangePhone.bind(this);
    }

    onChangePhone(value) {
        let formatError = false;
        if (value) {
            if (!isValidPhoneNumber(value)) {
                formatError = true;
            }
        }
        this.setState({
            phone: value,
            errors: {
                ...this.state.errors,
                phoneFormat: formatError
            }
        });
    }


    validateForm = () => {
        return this.state.errors.user && this.state.errors.mail && this.state.errors.message
            && !this.state.errors.phoneFormat
        && this.state.user.length > 0 && this.state.mail.length > 0 && this.state.message.length > 0;
    };

    handleUserChange = (event) => {
        let value = event.target.value;
        let inError = false;
        if (value.length === 0) {
            inError = true;
        }
        this.setState({ user: value, errors: { ...this.state.errors, user: inError}});
    };

    handleMailChange = (event) => {
        let value = event.target.value;
        let inError = false;
        let inErrorRegex = false;
        let regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (value.length === 0) {
            inError = true;
        }
        if (value && value.length > 0 && !(regex.test(value))) {
            inErrorRegex = true
        }
        this.setState({ mail: value, errors: { ...this.state.errors, mail: inError, mailRegex: inErrorRegex }});
    };

    handleTextareaChange = (event) => {
        let value = event.target.value;
        let inError = false;
        if (value.length === 0) {
            inError = true;
        }
        this.setState({ message: value, errors: { ...this.state.errors, message: inError}});
    };

    handleCaptchaChange = (event) => {
        let value = event.target.value;
        let inError = false;
        if (value.length === 0) {
            inError = true;
        }
        this.setState({ isVerified: value, errors: { ...this.state.errors, isVerified: inError}});
    };

    handleSubmit = (event) => {
        event.preventDefault();
        const options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json'},
            body: JSON.stringify({name: this.state.user, phone: this.state.phone, email: this.state.mail, message: this.state.message })
        };
        fetch(this.url + "mail/contact", options)
        .then(response => {
            if (response.status === 200) {
                this.setState({
                    user: "",
                    phone: "",
                    mail: "",
                    message: "",
                    errors: {
                        user: false,
                        mail: false,
                        mailRegex: false,
                        message: false
                    },
                    postMessageCode: 200,
                    postMessage: "Votre message a bien été envoyé à nos équipes."
                });
            } else {
                this.setState({
                    user: "",
                    phone: "",
                    mail: "",
                    message: "",
                    errors: {
                        user: false,
                        mail: false,
                        mailRegex: false,
                        phoneFormat: "",
                        message: false
                    },
                    postMessageCode: response.status,
                    postMessage: "Erreur lors de l'envoi du formulaire !!"
                });
            }
        });
    };

    componentDidMount() {
        this.setState({
            first_number : Math.floor(Math.random() * 5) + 1
        })
        this.setState({
            second_number : Math.floor(Math.random() * 5) + 1
        })



    }

    render() {

        const result = this.state.first_number + this.state.second_number
        const placeHolder = data.FormPlaceholder.ask + this.state.first_number + "+" + this.state.second_number;

        const formValid = !this.state.errors.user && !this.state.errors.mail && !this.state.errors.mailRegex
            && !this.state.errors.phoneFormat && !this.state.errors.message && this.state.user.length > 0
            && this.state.mail.length > 0  // eslint-disable-next-line
            && this.state.message.length > 0 && this.state.isVerified == result;

        return (
            <div className="Contact">
                <WhitePanel>
                    { this.state.postMessage !== "" ?
                        <Row>
                            <Col xs={12}>
                                <Alert bsStyle={this.state.postMessageCode === 200 ? "success" : "danger"}>
                                    {this.state.postMessage}
                                </Alert>
                            </Col>
                        </Row>
                        : null
                    }
                    <Row>
                        <Col lg={5} md={5} sm={12} xs={12}>
                            <Col lg={12} md={12} sm={6} xs={6}>
                                <h1>{data.Title} :</h1>
                                <img src={require(`../../${data.contactButton}`)} alt="" width={200} style={{marginTop: '50px'}}/>
                            </Col>
                            <Col lg={12} md={12} sm={6} xs={6}>
                                <div className="contact-fields">
                                    <Row>
                                        <Col xs={1} xsOffset={2}>
                                            <i className="fa fa-map-marker"></i>
                                        </Col>
                                        <Col>
                                            {data.Company.name} <br />
                                            {data.Company.building && ( <>{data.Company.building} <br /></>)}
                                            {data.Company.address} <br />
                                            {data.Company.city}
                                        </Col>
                                    </Row>
                                    <br />
                                    <Row>
                                        <Col xs={1} xsOffset={2}>
                                            <i className="fa fa-phone"/>
                                        </Col>
                                        <Col>
                                            {data.Company.phone}
                                        </Col>
                                    </Row>
                                    <br />
                                    <Row>
                                        <Col xs={1} xsOffset={2}>
                                            <i className="fa fa-link"/>
                                        </Col>
                                        <Col>
                                            <a href="https://www.zithasenior.lu/" target="_blank" rel="noopener noreferrer">&nbsp;{data.website}</a>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Col>
                        <Col lg={7} md={7} sm={12} xs={12}>
                            <form onSubmit={this.handleSubmit}>
                                <FormGroup controlId="user" validationState={this.state.errors.user ? "error" : null}>
                                    <ControlLabel>{data.FormLabel.fullname}</ControlLabel>
                                    <FormControl
                                        placeholder={data.FormPlaceholder.fullname}
                                        autoFocus
                                        value={this.state.user}
                                        onChange={this.handleUserChange}
                                    />
                                    {this.state.errors.user ? <HelpBlock>{data.Error.mandatory}</HelpBlock> : null}
                                </FormGroup>


                                <FormGroup controlId="phone" validationState={this.state.errors.phoneFormat ? "error" : null}>
                                    <ControlLabel>Phone</ControlLabel>
                                    <PhoneInput
                                        placeholder={data.FormPlaceholder.phone}
                                        flags={flags}
                                        value={this.state.phone}
                                        onChange={this.onChangePhone}
                                        className="phone_input"
                                    />
                                    {this.state.errors.phoneFormat ? <HelpBlock>Phone non valide!</HelpBlock> : null}
                                </FormGroup>
                                <FormGroup controlId="mail" validationState={this.state.errors.mail || this.state.errors.mailRegex ? "error" : null}>
                                    <ControlLabel>{data.FormLabel.mail}</ControlLabel>
                                    <FormControl
                                        placeholder={data.FormPlaceholder.mail}
                                        type="email"
                                        value={this.state.mail}
                                        onChange={this.handleMailChange}
                                    />
                                    {this.state.errors.mail ? <HelpBlock>{data.Error.mandatory}</HelpBlock> : null}
                                    {this.state.errors.mailRegex ? <HelpBlock>{data.Error.format}</HelpBlock> : null}
                                </FormGroup>
                                <FormGroup controlId="message" validationState={this.state.errors.message ? "error" : null}>
                                    <ControlLabel>{data.FormLabel.message}</ControlLabel>
                                    <FormControl
                                        placeholder={data.FormPlaceholder.message}
                                        componentClass="textarea"
                                        rows={4}
                                        value={this.state.message}
                                        onChange={this.handleTextareaChange}
                                    />
                                    {this.state.errors.message ? <HelpBlock>{data.Error.mandatory}</HelpBlock> : null}
                                </FormGroup>

                                <FormGroup controlId="user">
                                    <ControlLabel></ControlLabel>
                                    <FormControl
                                        placeholder={placeHolder}
                                        autoFocus
                                        value={this.state.isVerified}
                                        onChange={this.handleCaptchaChange}
                                    />

                                </FormGroup>


                                <div className="text-right">
                                    <Button bsSize="large" disabled={!formValid} type="submit">
                                        {data.Submit}
                                    </Button>
                                </div>
                            </form>
                        </Col>
                    </Row>
                </WhitePanel>
            </div>
        );
    };
}

export default Contact;