import React, {Component} from "react";
import PropTypes from 'prop-types';
import Setting from "./Setting";
import Checkbox from "@material-ui/core/Checkbox";
import Switch from "@material-ui/core/Switch";

export default class CheckboxSetting extends Component {

    constructor(props) {
        super(props);

        this.state = {
            value: this.props.value ? this.props.value : false
        }

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(e) {
        const editable = this.props.editable ? this.props.editable : false;
        const value = e.target.checked;

        if (editable) {
            if (!this.props.basedOnPropValue) {
                this.setState({
                    value: value
                });
            }

            if (this.props.onChange) {
                this.props.onChange(value);
            }
        }
    }

    render() {
        const value = this.props.basedOnPropValue ? this.props.value : this.state.value;
        const title = this.props.title ? this.props.title : 'No title';
        const type = this.props.type ? this.props.type : "checkbox";
        const size = this.props.size ? this.props.size : 'normal';
        const editable = this.props.editable ? this.props.editable : true;

        let titleComp;
        switch (size) {
            case "small":
                titleComp = (
                    <h6>{title}</h6>
                );
                break;
            case "normal":
                titleComp = (
                    <h5>{title}</h5>
                );
                break;
            default: console.log("Problem with size title")
        }

        let actionComp;
        switch (type) {
            case "slider":
                actionComp = (
                    <Switch
                        checked={value}
                        onChange={this.handleChange}
                        disabled={!editable}
                    />
                );
                break;
            case "checkbox":
                actionComp = (
                    <Checkbox
                        checked={value}
                        onChange={this.handleChange}
                        disabled={!editable}
                    />
                );
                break;
            default: console.log("No choice between checkbox or switch")

        }

        return (
            <Setting title={titleComp} action={actionComp}/>
        );
    }

}

CheckboxSetting.propTypes = {
    onChange: PropTypes.func,
    title: PropTypes.string.isRequired,
    value: PropTypes.bool,
    basedOnPropValue: PropTypes.bool,
    editable: PropTypes.bool,
    type: PropTypes.oneOf(['checkbox', 'slider']),
    size: PropTypes.oneOf(['small', 'normal'])
}
