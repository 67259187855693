import React, {Component} from "react";
import Proptypes from 'prop-types';
import {CircularProgress, IconButton, Tooltip} from '@material-ui/core';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faAngleDoubleLeft, faAngleDoubleRight, faAngleLeft, faAngleRight} from "@fortawesome/free-solid-svg-icons";
import InfiniteScroll from "react-infinite-scroll-component";

export default class Paginator extends Component {

    constructor(props) {
        super(props);

        const initialState = this.getInitialState();
        this.state = {
            elements: initialState.initialPages ? initialState.initialPages : {},
            pageSize: initialState.pageSize,
            pageNo: initialState.pageNo,
            lastPageNo: null,
            error: false
        }

        this.goBack = this.goBack.bind(this);
        this.goNext = this.goNext.bind(this);
        this.goTo = this.goTo.bind(this);
        this.requestElements = this.requestElements.bind(this);
        this.addPage = this.addPage.bind(this);
        this.getDisplayedElements = this.getDisplayedElements.bind(this);
        this.getElementUI = this.getElementUI.bind(this);
        this.resetPages = this.resetPages.bind(this);
        this.getInitialState = this.getInitialState.bind(this);
    }

    render() {
        /// UI
        const selector = this.props.selector ? this.props.selector : "buttons";
        const displayCounter = this.props.displayCounters !== undefined ? this.props.displayCounters : true;
        const lastPage = this.props.lastPage;
        const totalElements = this.props.totalElements;

        /// Mechanisms
        // Elements by page.
        const displayedElements = this.getDisplayedElements();
        // All elements (ONLY ON SCROLL VERSION).
        const allElements = selector === 'scroll' ? getElements(this.state.elements) : [];
        const loading = this.state.error === undefined;
        const error = this.state.error;

        const canGoBack = this.state.pageNo > 0;

        let canGoNext = false;
        if (this.props.lastPage !== undefined) {
            canGoNext = this.state.pageNo < this.props.lastPage;
        } else if (this.state.lastPageNo !== undefined) {
            canGoNext = this.state.pageNo < this.state.lastPageNo;
        }

        /// ClassNames
        const containerClassName = this.props.containerClassName;
        const contentsClassName = this.props.contentsClassName;
        const elementClassName = this.props.elementClassName;
        const selectorClassName = this.props.selectorClassName;

        return (
            <div className={containerClassName}>
                {selector === 'buttons' &&
                <div className={contentsClassName}>
                    {!loading && displayedElements && displayedElements.map((el, index) => (
                        <div key={index} className={elementClassName}>
                            {this.getElementUI(el)}
                        </div>
                    ))}
                    {!loading && !displayedElements &&
                    <div className="text-center mx-auto">
                        <p>No content to display.</p>
                    </div>
                    }
                    {loading && !error &&
                    <div className="text-center mx-auto">
                        <CircularProgress/>
                    </div>
                    }
                </div>
                }
                {selector === 'scroll' &&
                <InfiniteScroll
                    next={this.goNext}
                    hasMore={true}
                    loader={
                        <div className="text-center">
                            <CircularProgress/>
                        </div>
                    }
                    dataLength={allElements.length}
                    className={"overflow-hidden " + contentsClassName}
                >
                    {allElements && allElements.map((el, index) => (
                        <div key={index} className={elementClassName}>
                            {this.getElementUI(el)}
                        </div>
                    ))}
                </InfiniteScroll>
                }
                <div className={selectorClassName}>
                    {selector === 'buttons' &&
                    <div className="d-flex align-items-center justify-content-center">
                        <IconButton
                            onClick={() => this.goTo(0)}
                            disabled={loading || !canGoBack}
                            className="p-0 mr-1"
                        >
                            <FontAwesomeIcon
                                icon={faAngleDoubleLeft}
                                size={"lg"}
                            />
                        </IconButton>
                        <IconButton
                            onClick={this.goBack}
                            disabled={loading || !canGoBack}
                            className="p-0 mr-2"
                        >
                            <FontAwesomeIcon
                                icon={faAngleLeft}
                                size={"lg"}
                            />
                        </IconButton>
                        <Tooltip
                            title={displayCounter && totalElements ? totalElements + " elements in total" : ""}
                        >
                            <span
                                className="font-weight-bold mx-1"
                            >
                                {this.state.pageNo + 1}
                                {displayCounter && lastPage !== undefined &&
                                " / " + (lastPage + 1)
                                }
                            </span>
                        </Tooltip>
                        <IconButton
                            onClick={this.goNext}
                            disabled={loading || !canGoNext}
                            className="p-0 ml-2"
                        >
                            <FontAwesomeIcon
                                icon={faAngleRight}
                                size={"lg"}
                            />
                        </IconButton>
                        <IconButton
                            onClick={() => this.goTo(lastPage)}
                            disabled={loading || !canGoNext || lastPage === undefined}
                            className="p-0 ml-1"
                        >
                            <FontAwesomeIcon
                                icon={faAngleDoubleRight}
                                size={"lg"}
                            />
                        </IconButton>
                    </div>
                    }
                </div>
            </div>
        );
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const oldElements = prevProps.initialElements;
        const newElements = this.props.initialElements;

        const oldPageSize = prevProps.pageSize;
        const newPageSize = this.props.pageSize;

        const elementsChanged = JSON.stringify(oldElements) !== JSON.stringify(newElements);
        const pageSizeChanged = JSON.stringify(oldPageSize) !== JSON.stringify(newPageSize)

        if (elementsChanged || pageSizeChanged) {
            this.resetPages();
        }
    }

    goBack() {
        const canGoBack = this.state.pageNo > 0;
        const previousPageNo = this.state.pageNo - 1;
        if (canGoBack) this.goTo(previousPageNo);
    }

    goNext() {
        const canGoNext = this.props.lastPage ? this.state.pageNo < this.props.lastPage : true;
        const nextPageNo = this.state.pageNo + 1;
        if (canGoNext) this.goTo(nextPageNo);
    }

    goTo(pageNo) {
        const loading = this.state.error === undefined;

        if (!loading) {
            const page = this.state.elements[pageNo];

            if (!page || page.length !== this.state.pageSize) {
                this.setState({
                    error: undefined
                });

                this.requestElements(pageNo, this.state.pageSize).then(value => {
                    if (value) {
                        this.addPage(value, pageNo);
                        this.setState({
                            error: false,
                            pageNo: pageNo
                        });
                    } else {
                        if (page) {
                            this.setState({
                                lastPageNo: pageNo,
                                error: false,
                                pageNo: pageNo
                            });
                        } else {
                            this.setState({
                                lastPageNo: pageNo - 1,
                                error: false,
                                pageNo: pageNo - 1
                            });
                        }
                    }
                }).catch(ignored => {
                    this.setState({
                        error: true
                    });
                });
            } else {
                this.setState({
                    pageNo: pageNo
                });
            }
        }
    }

    requestElements(pageNo, pageSize) {
        return new Promise((resolve, reject) => {
            if (this.props.getPagePromise) {
                this.props.getPagePromise(pageNo, pageSize).then(elements => {
                    resolve(elements);
                }).catch(ignored => {
                    reject();
                });
            } else {
                resolve(null);
            }
        });
    }

    addPage(elements, pageNo) {
        if (elements && pageNo >= 0 && elements.length === this.state.pageSize) {
            this.setState(state => {
                return {
                    ...state,
                    elements: {
                        ...state.elements,
                        [pageNo]: elements
                    }
                }
            });
        } else if (elements && pageNo >= 0 && elements.length > 0 && elements.length <= this.state.pageSize) {
            this.setState(state => {
                return {
                    ...state,
                    lastPageNo: pageNo,
                    elements: {
                        ...state.elements,
                        [pageNo]: elements
                    }
                }
            });
        }
    }

    getDisplayedElements() {
        const pageNo = this.state.pageNo;
        return this.state.elements[pageNo];
    }

    getElementUI(element) {
        if (this.props.getElementUI) {
            return this.props.getElementUI(element);
        }
    }

    resetPages() {
        const initialState = this.getInitialState()

        this.setState({
            elements: initialState.initialPages ? initialState.initialPages : {},
            pageSize: initialState.pageSize,
            pageNo: initialState.pageNo,
            lastPageNo: null,
            error: false
        });
    }

    getInitialState() {
        const initialElements = this.props.initialElements ? this.props.initialElements : [];
        let pageNo = this.props.pageNo ? this.props.pageNo : 0;
        const pageSize = this.props.pageSize ? this.props.pageSize : initialElements.length;

        const initialPages = getInitialPages(initialElements, pageNo, pageSize);

        if (this.props.selector && this.props.selector === "scroll") {
            pageNo = (initialElements.length / pageSize) - 1;
        }

        return {
            initialElements: initialElements,
            pageNo: pageNo,
            pageSize: pageSize,
            initialPages: initialPages
        };
    }

}

function getInitialPages(elements, pageNo, pageSize) {
    if (elements && pageNo >= 0 && pageSize >= 1) {
        const pages = {};
        let counter = 0;
        let pageIndex = pageNo;

        while ((counter) * pageSize <= elements.length) {
            const elementsSlice = elements.slice(counter * pageSize, (counter + 1) * pageSize);
            if (elementsSlice.length > 0 && elementsSlice.length <= pageSize) pages[pageIndex] = elementsSlice;
            counter = counter + 1;
            pageIndex = pageIndex + 1;
        }

        return pages;
    }

    return null;
}

function getElements(pages) {
    if (pages) {
        const elements = []; // eslint-disable-next-line
        for (let i = 0; i === i; i++) {
            if (pages[i]) {
                elements.push(pages[i]);
            } else {
                break;
            }
        }
        return elements;
    }
    return [];
}

Paginator.propTypes = {
    initialElements: Proptypes.arrayOf(Proptypes.any),
    pageNo: Proptypes.number,
    pageSize: Proptypes.number,
    lastPage: Proptypes.number,
    totalElements: Proptypes.number,
    // Shape: async func(pageNo: number, pageSize: number): element[]
    getPagePromise: Proptypes.func.isRequired,
    // Shape: func(el: element): React.node
    getElementUI: Proptypes.func.isRequired,
    selector: Proptypes.oneOf(['buttons', 'scroll']),
    displayCounters: Proptypes.bool,

    // CSS
    containerClassName: Proptypes.string,
    contentsClassName: Proptypes.string,
    elementClassName: Proptypes.string,
    selectorClassName: Proptypes.string
}
