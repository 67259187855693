import React, {Component} from "react";
import {NavLink, Redirect} from 'react-router-dom';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {connect} from "react-redux";
import {faChartBar, faUser} from "@fortawesome/free-regular-svg-icons";
import {faTools} from "@fortawesome/free-solid-svg-icons";
import WhitePanel from "../../components/white-panel/WhitePanel";
import {Col, Row} from "react-bootstrap";
import TextContent from "../../components/text-content/TextContent";

import "./Admin.css";

const { admin } = TextContent(localStorage.getItem("language"));
const data = (
    {
        title: admin.title,
        users: {
            title: admin.users.title,
            subtitle_1: admin.users.subtitle_1,
            subtitle_2: admin.users.subtitle_2
        },
        roles: {
            title: admin.roles.title,
            subtitle_1: admin.roles.subtitle_1,
            subtitle_2: admin.roles.subtitle_2
        },
        settings: {
            title: admin.settings.title,
            subtitle_1: admin.settings.subtitle_1,
            subtitle_2: admin.settings.subtitle_2
        },
        dashboard:{
            title: admin.dashboard.title,
            subtitle_1: admin.dashboard.subtitle_1,
            subtitle_2: admin.dashboard.subtitle_2,
        }
    }
)

class Admin extends Component {

    render() {
        const {user: currentUser} = this.props;

        if (!currentUser || !currentUser.roles.includes("Admin")) {
            return <Redirect to="/login"/>;
        }

        return (
            <div className="Admin">
                <header className="jumbotron">
                    <h3>
                        {data.title} <strong>{currentUser.fullname}</strong> !
                    </h3>
                </header>
                <WhitePanel>
                    <div className="container-fluid py-3">
                        <NavLink to="/users">
                            <Row className="py-3 section">
                                <Col>
                                    <div className="container">
                                        <Row>
                                            <Col className="d-flex align-items-center">
                                                <FontAwesomeIcon
                                                    icon={faUser}
                                                    size={"6x"}
                                                    className="mr-5 text-dark"
                                                />
                                                <div className="text-dark">
                                                    <h3>
                                                        {data.users.title}<br/>
                                                        <small className="font-italic">{data.users.subtitle_1}, {data.users.subtitle_2},
                                                            ...</small>
                                                    </h3>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                        </NavLink>

                        <NavLink to="/settings">
                            <div className="section mt-2">
                                <Row className="bg-light py-3">
                                    <Col>
                                        <div className="container">
                                            <Row>
                                                <Col className="d-flex align-items-center">
                                                    <FontAwesomeIcon
                                                        icon={faTools}
                                                        size={"6x"}
                                                        className="mr-5 text-dark"
                                                    />
                                                    <div className="text-dark">
                                                        <h3>
                                                            {data.settings.title}<br/>
                                                            <small className="font-italic">{data.settings.subtitle_1}, {data.settings.subtitle_2},
                                                                ...</small>
                                                        </h3>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </NavLink>

                        <NavLink to="/dashboard">
                            <div className="section mt-2">
                                <Row className="bg-light py-3">
                                    <Col>
                                        <div className="container">
                                            <Row>
                                                <Col className="d-flex align-items-center">
                                                    <FontAwesomeIcon
                                                        icon={faChartBar}
                                                        size={"6x"}
                                                        className="mr-5 text-dark"
                                                    />
                                                    <div className="text-dark">
                                                        <h3>
                                                            {data.dashboard.title}<br/>
                                                            <small className="font-italic">{data.dashboard.subtitle_1}
                                                                ...</small>
                                                        </h3>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </NavLink>



                    </div>
                </WhitePanel>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const {user} = state.auth;
    return {
        user,
    };
}

export default connect(mapStateToProps)(Admin);