import React, {Component} from "react";
import PropTypes from "prop-types";
import {MuiPickersUtilsProvider, DateTimePicker} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import TextContent from "../../../text-content/TextContent";

import "../../Doctor.css";
import {Button} from "react-bootstrap";

const {doctor} = TextContent(localStorage.getItem("language"));
const data = {
    format: doctor.dateFormat,
    start: doctor.holiday.holidaysDate.start,
    end: doctor.holiday.holidaysDate.end,
    create: doctor.holiday.holidaysDate.create
}

export default class AddHolidaysDatesPaneContent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dates: {
                startDate: new Date(),
                endDate: new Date()
            }
        }

        this.handleChangeStart = this.handleChangeStart.bind(this);
        this.handleChangeEnd = this.handleChangeEnd.bind(this);
        this.getDates = this.getDates.bind(this);
    }

    handleChangeStart(e) {
        this.setState({
            dates: {
                ...this.state.dates,
                startDate: e._d
            }
        });
    }

    handleChangeEnd(e) {
        this.setState({
            dates: {
                ...this.state.dates,
                endDate: e._d
            }
        });
    }

    getDates() {
        this.props.dates(this.state.dates);
    }

    render() {
        return (
            <div className="AddHolidaysDatesPaneContent">
                <div className="holidays-dates">
                    <MuiPickersUtilsProvider utils={MomentUtils} format='HH:mm' name="startDate">
                        <DateTimePicker
                            ampm={false}
                            format={`[${data.start}] : ${data.format} HH:mm`}
                            inputVariant="outlined"
                            value={this.state.dates.startDate}
                            onChange={this.handleChangeStart}
                        />
                    </MuiPickersUtilsProvider>
                    <MuiPickersUtilsProvider utils={MomentUtils} format='HH:mm'>
                        <DateTimePicker
                            ampm={false}
                            minDate={this.state.dates.startDate}
                            format={`[${data.end}] : ${data.format} HH:mm`}
                            inputVariant="outlined"
                            value={this.state.dates.endDate}
                            onChange={this.handleChangeEnd}
                        />
                    </MuiPickersUtilsProvider>
                </div>
                <div className="create-holiday">
                    <Button bsSize="large" onClick={() => this.getDates()}>
                        {data.create}
                    </Button>
                </div>
            </div>
        );
    }
}

AddHolidaysDatesPaneContent.propTypes = {
    onClose: PropTypes.func,
    dates: PropTypes.any
}