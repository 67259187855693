import React, {Component} from "react";
import PropTypes from 'prop-types';
import TextField from "@material-ui/core/TextField";
import {IconButton} from "@material-ui/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMinusSquare, faPlusSquare} from "@fortawesome/free-regular-svg-icons";

export default class TextFieldArray extends Component {

    constructor(props) {
        super(props);

        this.state = {
            values: this.props.values ? this.props.values : []
        }

        this.handleChange = this.handleChange.bind(this);
        this.computeValue = this.computeValue.bind(this);
    }

    handleChange(id, value) {
        this.setState(state => {
            const newValues = [...state.values];


            if (value !== undefined) {
                if (!value) value = '';
                if (id === state.values.length) {
                    newValues.push(value);
                } else {
                    newValues[id] = value;
                }
            } else {
                newValues.splice(id, 1);
            }

            if (this.props.onChange) {
                this.props.onChange(this.computeValue(newValues));
            }

            return {
                ...state,
                values: newValues
            }
        });
    }

    computeValue(values) {
        const valuesArray = []
        for (let value of values) {
            if (value && value.length > 0) valuesArray.push(value);
        }
        return valuesArray;
    }

    render() {
        const values = this.state.values;
        return (
            <div className="row">
                <div className="col">
                    {values && values.map((value, index) => (
                        <div className="row" key={index}>
                            <div className="col d-flex">
                                <IconButton
                                    size={"small"}
                                    onClick={() => this.handleChange(index, undefined)}
                                    className="mr-2"
                                >
                                    <FontAwesomeIcon
                                        icon={faMinusSquare}
                                        className="text-danger"
                                        size={"lg"}
                                    />
                                </IconButton>
                                <TextField
                                    value={value ? value : ''}
                                    autoFocus={true}
                                    onChange={(e) => this.handleChange(index, e.target.value)}
                                    className="w-100"
                                />
                            </div>
                        </div>
                    ))}
                    {values && values.length === 0 &&
                    <div className="row">
                        <div className="col d-flex">
                            <IconButton
                                size={"small"}
                                className="mr-2"
                                disabled
                            >
                                <FontAwesomeIcon
                                    icon={faMinusSquare}
                                    className="text-disabled"
                                    size={"lg"}
                                />
                            </IconButton>
                            <TextField
                                value={""}
                                onChange={(e) => this.handleChange(0, e.target.value)}
                                className={"w-100"}
                            />
                        </div>
                    </div>
                    }
                    <div className="row mt-2">
                        <div className="col text-left">
                            <IconButton
                                size={"small"}
                                onClick={() => this.handleChange(values.length, null)}
                            >
                                <FontAwesomeIcon
                                    icon={faPlusSquare}
                                    className="text-success"
                                    size={"lg"}
                                />
                            </IconButton>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

TextFieldArray.propTypes = {
    onChange: PropTypes.func,
    values: PropTypes.arrayOf(PropTypes.string)
}

