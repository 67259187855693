import React, {Component} from "react";
import PropTypes from "prop-types";
import CallableDialog from "../../../../nsi-lib/components/modals/CallableDialog";
import TextContent from "../../../text-content/TextContent";
import MeetingPaneDoctorContent from "../content/MeetingPaneDoctorContent";
import SummaryPaneDialogDoctor from "./SummaryPaneDialogDoctor";

const {secretariat} = TextContent(localStorage.getItem("language"));
const data = {
    titleCreate: secretariat.external.titleCreate,
    titleUpdate: secretariat.external.titleUpdate,
}

export default class MeetingPaneDoctorDialog extends Component {
    constructor(props) {
        super(props);

        this.state = {
            toggledSummaryPane: false,
            content: ""
        }

        this.closeDialog = this.closeDialog.bind(this);
    }

    toggleSummaryPaneDialog(open) {
        this.setState({
            toggledSummaryPane: open
        });
    }

    getScheduleContent(content) {
        this.setState({content});
    }

    validateSchedule(shouldSendEmail, shouldSendSMS) {
        this.props.getScheduleContent(this.state.content, shouldSendEmail, shouldSendSMS);
        this.props.onClose();
    }

    closeDialog() {
        if (this.props.onClose) {
            this.props.onClose();
        }
    }

    render() {
        const {isOpen, defaultContent, selectedDoctor, currentUser} = this.props;


        return (
            <CallableDialog
                isOpen={isOpen}
                handleClose={this.closeDialog}
                fullwidth
                content={
                    <div>
                        {defaultContent && defaultContent.raw ?
                            <h3>{data.titleUpdate} : </h3> :
                            <h3>{data.titleCreate} : </h3>}
                        <br />
                        <button className="meeting-close-button" onClick={() => this.closeDialog()}>
                            <i className="fa fa-times fa-2x" />
                        </button>
                        <MeetingPaneDoctorContent
                            defaultContent={defaultContent}
                            currentUser={currentUser}
                            selectedDoctor={selectedDoctor}
                            getScheduleContent={(content) => this.getScheduleContent(content)}
                            onClose={this.closeDialog}
                            settings={this.props.settings}
                            openSummary={open => this.toggleSummaryPaneDialog(open)}
                        />
                        <SummaryPaneDialogDoctor
                            isOpen={this.state.toggledSummaryPane}
                            onClose={() => this.toggleSummaryPaneDialog(false)}
                            content={this.state.content}
                            currentUser={currentUser}
                            validateSchedule={(shouldSendEmail, shouldSendSMS) => {
                                this.toggleSummaryPaneDialog(false);
                                this.validateSchedule(shouldSendEmail, shouldSendSMS);
                            }}
                        />
                    </div>
                }
            />
        );
    }
}

MeetingPaneDoctorDialog.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    defaultContent: PropTypes.any,
    getScheduleContent: PropTypes.any,
    selectedDoctor: PropTypes.any,
    currentUser: PropTypes.any,
    settings: PropTypes.any
}
