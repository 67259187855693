import React, {Component} from "react";
import {connect} from "react-redux";
import ProgressDialog from "./ProgressDialog";
import ErrorDialog from "./ErrorDialog";

class StaticModals extends Component {



    render() {

        const globalProgress = (
            <ProgressDialog
                isOpen={this.props.modals['globalProgressModal']}
            />
        );

        const globalError = (
            <ErrorDialog
                isOpen={this.props.modals['globalErrorModal']}
            />
        )

        return (
            <div>
                {globalProgress}
                {globalError}
            </div>
        );
    }

}

const mapStateToProps = state => {
    return {
        modals: state.modals.modalStates
    }
}

const mapDispatchToProps = ignored => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(StaticModals);
