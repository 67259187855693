import React, {Component} from "react";
import PropTypes from "prop-types";
import CallableDialog from "../../../../nsi-lib/components/modals/CallableDialog";
import AddInternalPaneContentDoctor from "../content/AddInternalPaneContentDoctor";import TextContent from "../../../text-content/TextContent";

const {secretariat} = TextContent(localStorage.getItem("language"));
const data = {
    title: secretariat.internal.title,
}

export default class AddInternalPaneDialogDoctor extends Component {
    constructor(props) {
        super(props);

        this.closeDialog = this.closeDialog.bind(this);
        this.getInternal = this.getInternal.bind(this);
    }

    getInternal(internal) {
        this.props.getInternal(internal)
    }

    closeDialog() {
        if (this.props.onClose) {
            this.props.onClose();
        }
    }

    render() {
        const {isOpen} = this.props;

        return (
            <CallableDialog
                isOpen={isOpen}
                dialogClassName="AddInternalPaneDialog"
                handleClose={this.closeDialog}
                fullwidth
                content={
                    <div>
                        <h3>{data.title} :</h3>
                        <button className="close-button" onClick={() => this.closeDialog()}>
                            <i className="fa fa-times fa-2x"/>
                        </button>
                        <AddInternalPaneContentDoctor
                            onClose={() => this.closeDialog()}
                            getInternal={this.getInternal}
                            currentUser={this.props.currentUser}
                        />
                    </div>
                }
            />
        );
    }
}

AddInternalPaneDialogDoctor.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    getInternal: PropTypes.any,
    currentUser: PropTypes.any
}
