import React from "react";
import { Row, Col } from "react-bootstrap";
import WhitePanel from "../../components/white-panel/WhitePanel";
import "./Help.css";
import TextContent from "../../components/text-content/TextContent";

const { resources, help } = TextContent(localStorage.getItem("language"));
const data = (
    {
        video: resources.help.Video,
        Family_pdfEN: resources.help.Guide_EN_pdf,
        Family_pdfFR: resources.help.Guide_FR_pdf,
        Family_pdfDE: resources.help.Guide_DE_pdf,
        Unit_pdfFR: resources.help.Guide_FR_Unit_pdf,
        Unit_pdfDE: resources.help.Guide_DE_Unit_pdf,
        Title: help.Title,
        Guide: help.Guide,
        Family_Guide_EN: help.Guide_EN,
        Family_Guide_FR: help.Guide_FR,
        Family_Guide_DE: help.Guide_DE,
        Unit_Guide_FR: help.Guide_FR_Unit,
        Unit_Guide_DE: help.Guide_DE_Unit
    }
)

const Help = () => {
    return(
        <div className="Help">
            <div className="container__title">
                <div className="title">
                    {data.Title}
                </div>
                <div className="line"/>
            </div>
            <WhitePanel>
                <video controls>
                    <source src={require(`../../${data.video}`)} type="video/mp4"/>
                </video>
            </WhitePanel>
            <Row>
                <Col lg={8} lgOffset={2} md={8} mdOffset={2} smOffset={2} xs={12} className="text-center">
                    <div className="container__title">
                        <div className="title">
                            {data.Guide}
                        </div>
                        <div className="line"/>
                    </div>
                    <span>
                        <a className="btn btn-lg btn-default" href={require(`../../${data.Family_pdfEN}`)} target="_blank" rel="noopener noreferrer" style={{marginBottom: '15px'}}>
                            <i className="fa fa-download"/>
                            &nbsp;{data.Family_Guide_EN}
                        </a>
                    </span>
                    <span style={{marginLeft: '15px'}}>
                        <a className="btn btn-lg btn-default" href={require(`../../${data.Family_pdfFR}`)} target="_blank" rel="noopener noreferrer" style={{marginBottom: '15px'}}>
                            <i className="fa fa-download"/>
                            &nbsp;{data.Family_Guide_FR}
                        </a>
                    </span>
                    <span style={{marginLeft: '15px'}}>
                        <a className="btn btn-lg btn-default" href={require(`../../${data.Family_pdfDE}`)} target="_blank" rel="noopener noreferrer" style={{marginBottom: '15px'}}>
                            <i className="fa fa-download"/>
                            &nbsp;{data.Family_Guide_DE}
                        </a>
                    </span>
                </Col>
            </Row>
            <Row>
                <Col lg={8} lgOffset={2} md={8} mdOffset={2} smOffset={2} xs={12} className="text-center">
                    <span style={{marginLeft: '15px'}}>
                        <a className="btn btn-lg btn-default" href={require(`../../${data.Unit_pdfFR}`)} target="_blank" rel="noopener noreferrer" style={{marginBottom: '15px'}}>
                            <i className="fa fa-download"/>
                            &nbsp;{data.Unit_Guide_FR}
                        </a>
                    </span>
                    <span style={{marginLeft: '15px'}}>
                        <a className="btn btn-lg btn-default" href={require(`../../${data.Unit_pdfDE}`)} target="_blank" rel="noopener noreferrer" style={{marginBottom: '15px'}}>
                            <i className="fa fa-download"/>
                            &nbsp;{data.Unit_Guide_DE}
                        </a>
                    </span>
                </Col>
            </Row>
        </div>
    );
};

export default Help;