import React, {Component} from "react";
import PropTypes from "prop-types";
import moment from "moment";
import {Button, Table} from "react-bootstrap";
import TextContent from "../../../text-content/TextContent";
import Switch from "react-switch";

import "../../../secretariat/css/Secretariat.css";

const {secretariat} = TextContent(localStorage.getItem("language"));
const data = {
    title: secretariat.meetingCreation.summary.meetingTitle,
    description: secretariat.meetingCreation.summary.description,
    dates: secretariat.meetingCreation.summary.dates,
    participant: secretariat.meetingCreation.summary.participant,
    internal: secretariat.meetingCreation.summary.internal,
    external: secretariat.meetingCreation.summary.external,
    cancel: secretariat.meetingCreation.summary.cancel,
    sendMail: secretariat.meetingCreation.summary.sendMail,
    update: secretariat.meetingCreation.summary.update,
    create: secretariat.meetingCreation.summary.create,
    preventGuestMail: secretariat.meetingCreation.summary.preventGuestMail,
    preventGuestPhone: secretariat.meetingCreation.summary.preventGuestPhone
}

export default class SummaryPaneContentDoctor extends Component {
    constructor(props) {
        super(props);

        this.state = {
            title: "",
            description: "",
            start: "",
            end: "",
            participants: {
                internal: [],
                external: []
            },
            isUpdate: false,
            shouldSendMail: true,
            shouldSendSMS: false
        }

        this.onChangeSendMail = this.onChangeSendMail.bind(this);
        this.onChangeSendSMS = this.onChangeSendSMS.bind(this);
    }

    onChangeSendMail(checked) {
        this.setState({
            shouldSendMail: checked
        });
    }

    onChangeSendSMS(checked) {
        this.setState({
            shouldSendSMS: checked
        });
    }

    componentDidMount() {
        const {content} = this.props;

        this.setState({
            title: content.title,
            description: content.raw.description,
            start: content.start,
            end: content.end,
            participants: {
                ...this.state.participants,
                internal: content.raw.participants.internal,
                external: content.raw.participants.external
            },
            isUpdate: !!content.raw.isUpdate
        });
    }

    render() {
        return (
            <div className="SummaryPaneContent">
                <ul>
                    <li>
                        <u>{data.title}</u> : {this.state.title}
                    </li>
                    {this.state.description &&
                    <li>
                        <u>{data.description}</u> : {this.state.description}
                    </li>
                    }
                    <li>
                        <u>{data.dates}</u> : {`${moment(this.state.start).format("DD/MM/YYYY HH:mm")} - ${moment(this.state.end).format("DD/MM/YYYY HH:mm")}`}
                    </li>
                    <li>
                        <u>{data.participant}</u> :
                    </li>
                </ul>
                <div className="peoples">
                    <div className="internal-people">
                        <Table striped bordered condensed hover>
                            <thead>
                            <tr className="table_title" style={{textAlign: "center"}}>
                                <th>{data.internal}</th>
                            </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        {this.props.currentUser.fullname}
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                    <div className="external-people">
                        <Table striped bordered condensed hover>
                            <thead>
                            <tr className="table_title external" style={{textAlign: "center"}}>
                                <th>{data.external}</th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.state.participants && this.state.participants.external.map((external, index) =>
                                <tr key={index}>
                                    <td>
                                        {external.name}
                                    </td>
                                </tr>
                            )}
                            </tbody>
                        </Table>
                    </div>
                </div>
                <label className="notify-guests">
                    <span>{data.preventGuestMail} :</span>
                    <Switch
                        className="mail-switch"
                        checked={this.state.shouldSendMail}
                        onChange={this.onChangeSendMail}
                        name="shouldSendMail"
                    />
                </label>
                <label className="notify-guests">
                    <span>{data.preventGuestPhone} :</span>
                    <Switch
                        className="sms-switch"
                        checked={this.state.shouldSendSMS}
                        onChange={this.onChangeSendSMS}
                        name="shouldSendSMS"
                    />
                </label>
                <Button className="validate-button" onClick={() => this.props.validateSchedule(this.state.shouldSendMail, this.state.shouldSendSMS)}>
                    {this.state.isUpdate ?
                        <span>{data.update}</span> :
                        <span>{data.create}</span>
                    }
                </Button>
            </div>
        );
    }
}

SummaryPaneContentDoctor.propTypes = {
    content: PropTypes.any,
    onClose: PropTypes.any,
    validateSchedule: PropTypes.any
}