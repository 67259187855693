import React, {Component} from "react";
import PropTypes from 'prop-types';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFilter, faSearch} from "@fortawesome/free-solid-svg-icons";
import TextField from "@material-ui/core/TextField";
import CallableDialog from "./modals/CallableDialog";
import Button from "@material-ui/core/Button";

export default class ResearchForm extends Component {

    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.toggleFilters = this.toggleFilters.bind(this);

        this.state = {
            filterOpen: false
        }
    }

    render() {
        const defaultValue = this.props.defaultValue ? this.props.defaultValue : '';
        const filters = this.props.filterContent;

        return (
            <div className="d-flex justify-content-center align-items-center">
                <FontAwesomeIcon
                    icon={faSearch}
                    size={"lg"}
                    className="text-dark mr-2"
                />
                <TextField
                    label={"Research"}
                    className="text-center"
                    size={"small"}
                    variant={"outlined"}
                    onChange={this.handleChange}
                    defaultValue={defaultValue}
                />
                {filters &&
                <div>
                    <FontAwesomeIcon
                        icon={faFilter}
                        size={"lg"}
                        className="text-dark ml-2"
                        onClick={() => this.toggleFilters(true)}
                    />
                    <CallableDialog
                        handleClose={() => this.toggleFilters(false)}
                        isOpen={this.state.filterOpen}
                        actions={
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col text-center">
                                        <Button variant={"outlined"} onClick={() => this.toggleFilters(false)}>
                                            Close
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        }
                        content={
                            filters
                        }
                    />
                </div>
                }
            </div>
        );
    }

    handleChange(e) {
        if (this.props.handleChange) {
            this.props.handleChange(e.target.value);
        }
    }

    toggleFilters(open) {
        this.setState({
            filterOpen: open
        })
    }

}

ResearchForm.propTypes = {
    handleChange: PropTypes.func,
    defaultValue: PropTypes.string,
    filterContent: PropTypes.node
}
