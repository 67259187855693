import React, { Component } from "react";
import {connect} from "react-redux";
import WhitePanel from "../white-panel/WhitePanel";
import {
    Button,
    FormGroup,
    Checkbox,
    FormControl,
    ControlLabel,
    Radio,
    Popover,
    OverlayTrigger,
} from "react-bootstrap";
import {updateSettings} from "../../actions/settings";
import {getSMSContent} from "../../services/sms/smsService";
import settingsService from "../../services/settings/settingsService";
import {Redirect} from "react-router-dom";
import CollapsableSetting from "../../nsi-lib/components/settings/CollapsableSetting";
import mailService from "../../services/mail/mailService";
import TextContent from "../text-content/TextContent";

import "./Settings.css";

const { resources, settings } = TextContent(localStorage.getItem("language"));
const data = {
    homeButton: resources.admin.homeButton,
    helpButton: resources.settings.helpButton,
    room: {
        title: settings.rooms.title,
        invitationEmail: settings.rooms.invitationEmail,
        invitationSms: settings.rooms.invitationSms,
        availability: settings.rooms.availability
    },
    user: {
        title: settings.users.title,
        minimumPasswordLength: settings.users.minimumPasswordLength,
        triesBeforeBlocking: settings.users.triesBeforeBlocking,
        roomCreationRequirement: {
            title: settings.users.roomCreationRequirement.title,
            secureLogin: settings.users.roomCreationRequirement.secureLogin,
            code: settings.users.roomCreationRequirement.code,
        }
    },
    meetings: {
        title: settings.meetings.title,
        defaultDuration: settings.meetings.defaultDuration,
        availabilityBefore: settings.meetings.availabilityBefore,
        availabilityAfter: settings.meetings.availabilityAfter
    },
    contact: {
        title: settings.contact.title,
        placeholder: settings.contact.placeholder
    },
    email: {
        title: settings.emails.title,
        subTitle: settings.emails.subTitle,
        subject: settings.emails.subject,
        body: settings.emails.body,
        help: {
            title: settings.emails.help.title,
            description: settings.emails.help.description,
            variables: {
                title: settings.emails.help.variables.title,
                fullname: settings.emails.help.variables.fullname,
                code: settings.emails.help.variables.code,
                start: settings.emails.help.variables.start,
                end: settings.emails.help.variables.end,
            },
            html: {
                title: settings.emails.help.html.title,
                underline: settings.emails.help.html.underline,
                bold: settings.emails.help.html.bold,
                italic: settings.emails.help.html.italic
            }
        }
    },
    sms: {
        title: settings.sms.title,
        subTitle: settings.sms.subTitle,
        message: settings.sms.message,
        help: {
            title: settings.sms.help.title,
            description: settings.sms.help.description,
            variables: {
                title: settings.sms.help.variables.title,
                fullname: settings.sms.help.variables.fullname,
                code: settings.sms.help.variables.code,
                start: settings.sms.help.variables.start,
                end: settings.sms.help.variables.end,
            }
        }
    },
    meetingEmails: {
        title: settings.meetingEmails.title,
        subTitle1: settings.meetingEmails.subTitle1,
        subTitle2: settings.meetingEmails.subTitle2,
        subTitle3: settings.meetingEmails.subTitle3
    },
    meetingSMS: {
        title: settings.meetingSMS.title,
        subTitle1: settings.meetingSMS.subTitle1,
        subTitle2: settings.meetingSMS.subTitle2,
        subTitle3: settings.meetingSMS.subTitle3
    },
    SMSToken: settings.SMSToken,
    message: {
        success: settings.messages.success,
        error: settings.messages.error
    },
    submit: settings.submit
}

class Settings extends Component {
    constructor(props) {
        super(props);

        this.state = {
            settings: {
                invitation_email: "",
                invitation_sms: "",
                secure_login: "",
                contact_mail: "",
                sms_token: {
                    value: "",
                    id: ""
                },
                room_duration: {
                    value: "",
                    disableDec: false
                },
                meeting_room_availability_before: {
                    value: "",
                    disableDec: false
                },
                meeting_room_availability_after: {
                    value: "",
                    disableDec: false
                },
                default_meeting_room_duration: {
                    value: "",
                    disableDec: false
                },
                password_length: {
                    value: "",
                    disableDec: false
                },
                password_max_retries: {
                    value: "",
                    disableDec: false
                },
                invitationMailContent: {
                    type: "invitation",
                    subject: "",
                    body: ""
                },
                meetingCreationMailContent: {
                    type: "meetingcreation",
                    subject: "",
                    body: ""
                },
                meetingUpdateMailContent: {
                    type: "meetingupdate",
                    subject: "",
                    body: ""
                },
                meetingDeleteMailContent: {
                    type: "meetingdelete",
                    subject: "",
                    body: ""
                },
                invitationSMSContent: {
                    type: "invitation",
                    message: ""
                },
                meetingCreationSMSContent: {
                    type: "meetingcreation",
                    message: ""
                },
                meetingUpdateSMSContent: {
                    type: "meetingupdate",
                    message: ""
                },
                meetingDeleteSMSContent: {
                    type: "meetingdelete",
                    message: ""
                },
                currentEmailMeetingName: "",
                currentSMSMeetingName: ""
            },
            prevSettings: {
                invitation_email: "",
                invitation_sms: "",
                secure_login: "",
                room_duration: "",
                password_length: "",
                password_max_retries: "",
                sms_token: {
                    value: "",
                    id: ""
                },
                invitationMailContent: {
                    subject: "",
                    body: ""
                },
                meetingCreationMailContent: {
                    subject: "",
                    body: ""
                },
                meetingUpdateMailContent: {
                    subject: "",
                    body: ""
                },
                meetingDeleteMailContent: {
                    subject: "",
                    body: ""
                },
                invitationSMSContent: {
                    type: "invitation",
                    message: ""
                },
                meetingCreationSMSContent: {
                    type: "meetingcreation",
                    message: ""
                },
                meetingUpdateSMSContent: {
                    type: "meetingupdate",
                    message: ""
                },
                meetingDeleteSMSContent: {
                    type: "meetingdelete",
                    message: ""
                }
            },
            successful: false,
            message: "",
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleChangeSmsToken = this.handleChangeSmsToken.bind(this);

        this.handleChangeContent = this.handleChangeContent.bind(this);
        this.handleChangeCheckboxes = this.handleChangeCheckboxes.bind(this);
        this.handleChangeRadios = this.handleChangeRadios.bind(this);
        this.handleMailCollapseChange = this.handleMailCollapseChange.bind(this);
        this.handleSMSCollapseChange = this.handleSMSCollapseChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.increment = this.increment.bind(this);
        this.decrement = this.decrement.bind(this);
    }

    handleChange(e) {
        this.setState({
            settings: {
                ...this.state.settings,
                [e.target.name]: e.target.value,
            }
        });
    }

    handleChangeSmsToken(e) {
        this.setState({
            settings: {
                ...this.state.settings,
                sms_token: {
                    ...this.state.settings.sms_token,
                    value: e.target.value
                }
            }
        });
    }

    handleChangeCheckboxes(e) {
        this.setState({
            settings: {
                ...this.state.settings,
                [e.target.name]: e.target.checked,
            }
        });
    }

    handleChangeRadios() {
        this.setState({
            settings: {
                ...this.state.settings,
                secure_login: !this.state.settings.secure_login
            }
        });
    }

    handleChangeContent(e, name) {
        this.setState({
            settings: {
                ...this.state.settings,
                [name]: {
                    ...this.state.settings[name],
                    [e.target.name]: e.target.value
                }
            }
        });
    }

    handleMailCollapseChange(e, name) {
        this.setState({
            settings: {
                ...this.state.settings,
                currentEmailMeetingName: e ? "" : name
            }
        });
    }

    handleSMSCollapseChange(e, name) {
        this.setState({
            settings: {
                ...this.state.settings,
                currentSMSMeetingName: e ? "" : name
            }
        });
    }

    increment(e) {
        this.setState({
            settings: {
                ...this.state.settings,
                [e.target.name]: {
                    value: parseInt(e.target.value) + 1,
                    disableDec: false
                }
            }
        });
    }

    decrement(e) {
        if (parseInt(e.target.value) === 1) {
            this.setState({
                settings: {
                    ...this.state.settings,
                    [e.target.name]: {
                        value: parseInt(e.target.value) - 1,
                        disableDec: true
                    }
                }
            });
        } else {
            this.setState({
                settings: {
                    ...this.state.settings,
                    [e.target.name]: {
                        value: parseInt(e.target.value) - 1,
                        disableDec: false
                    },
                }
            });
        }
    }

    handleSubmit(e) {
        e.preventDefault();
        let settings = this.props.settings;
        let objIndex;
        this.setState({
            successful: false,
        });

        objIndex = settings.findIndex((obj => obj.name === "invitation_email"));
        settings[objIndex].value = this.state.settings.invitation_email;
        objIndex = settings.findIndex((obj => obj.name === "invitation_sms"));
        settings[objIndex].value = this.state.settings.invitation_sms;
        objIndex = settings.findIndex((obj => obj.name === "room_duration"));
        settings[objIndex].value = this.state.settings.room_duration.value;
        objIndex = settings.findIndex((obj => obj.name === "meeting_room_availability_before"));
        settings[objIndex].value = this.state.settings.meeting_room_availability_before.value;
        objIndex = settings.findIndex((obj => obj.name === "meeting_room_availability_after"));
        settings[objIndex].value = this.state.settings.meeting_room_availability_after.value;
        objIndex = settings.findIndex((obj => obj.name === "default_meeting_room_duration"));
        settings[objIndex].value = this.state.settings.default_meeting_room_duration.value;
        objIndex = settings.findIndex((obj => obj.name === "secure_login"));
        settings[objIndex].value = this.state.settings.secure_login;
        objIndex = settings.findIndex((obj => obj.name === "password_length"));
        settings[objIndex].value = this.state.settings.password_length.value;
        objIndex = settings.findIndex((obj => obj.name === "password_max_retries"));
        settings[objIndex].value = this.state.settings.password_max_retries.value;
        objIndex = settings.findIndex((obj => obj.name === "contact_mail"));
        settings[objIndex].value = this.state.settings.contact_mail;

        this.props.dispatch(updateSettings(settings, this.props.user))
            .then(() => {
                this.setState({
                    successful: true,
                    message: data.message.success
                })
            })
            .catch(() => {
                this.setState({
                    successful: false,
                    message: data.message.error
                })
            })
            .finally(() => {
                setTimeout(() => {
                    this.setState({
                        message: ""
                    })
                }, 1000);
            });

        if(this.state.prevSettings.sms_token.value !== this.state.settings.sms_token.value) {
            settingsService.updateSmsToken(this.state.settings.sms_token.value, this.state.settings.sms_token.id, this.props.user)
                .then(() => {
                    this.setState({
                        successful: true,
                        message: data.message.success,
                        prevSettings: {
                            ...this.state.prevSettings,
                            sms_token: {
                                ...this.state.prevSettings.sms_token,
                                value: this.state.settings.sms_token.value
                            }
                        }
                    })
                })
                .catch(() => {
                    this.setState({
                        successful: false,
                        message: data.message.error
                    })
                })
                .finally(() => {
                    setTimeout(() => {
                        this.setState({
                            message: ""
                        })
                    }, 1000);
                });
        }


        if(this.state.prevSettings.invitationMailContent.body !== this.state.settings.invitationMailContent.body ||
            this.state.prevSettings.invitationMailContent.subject !== this.state.settings.invitationMailContent.subject ||
            this.state.prevSettings.meetingCreationMailContent.body !== this.state.settings.meetingCreationMailContent.body ||
            this.state.prevSettings.meetingCreationMailContent.subject !== this.state.settings.meetingCreationMailContent.subject ||
            this.state.prevSettings.meetingUpdateMailContent.body !== this.state.settings.meetingUpdateMailContent.body ||
            this.state.prevSettings.meetingUpdateMailContent.subject !== this.state.settings.meetingUpdateMailContent.subject ||
            this.state.prevSettings.meetingDeleteMailContent.body !== this.state.settings.meetingDeleteMailContent.body ||
            this.state.prevSettings.meetingDeleteMailContent.subject !== this.state.settings.meetingDeleteMailContent.subject) {

            settingsService.updateMails([this.state.settings.invitationMailContent, this.state.settings.meetingCreationMailContent,
                this.state.settings.meetingUpdateMailContent, this.state.settings.meetingDeleteMailContent], this.props.user)
                .then(() => {
                    this.setState({
                        successful: true,
                        message: data.message.success
                    })
                })
                .catch(() => {
                    this.setState({
                        successful: false,
                        message: data.message.error
                    })
                })
                .finally(() => {
                    setTimeout(() => {
                        this.setState({
                            message: ""
                        })
                    }, 1000);
                });
        }

        if(this.state.prevSettings.invitationSMSContent.message !== this.state.settings.invitationSMSContent.message ||
            this.state.prevSettings.meetingCreationSMSContent.message !== this.state.settings.meetingCreationSMSContent.message ||
            this.state.prevSettings.meetingUpdateSMSContent.message !== this.state.settings.meetingUpdateSMSContent.message ||
            this.state.prevSettings.meetingDeleteSMSContent.message !== this.state.settings.meetingDeleteSMSContent.message) {

            settingsService.updateSMS([this.state.settings.invitationSMSContent, this.state.settings.meetingCreationSMSContent,
                this.state.settings.meetingUpdateSMSContent, this.state.settings.meetingDeleteSMSContent], this.props.user)
                .then(() => {
                    this.setState({
                        successful: true,
                        message: data.message.success
                    })
                })
                .catch(() => {
                    this.setState({
                        successful: false,
                        message: data.message.error
                    })
                })
                .finally(() => {
                    setTimeout(() => {
                        this.setState({
                            message: ""
                        })
                    }, 1000);
                });
        }
    }

    componentDidMount() {
        let invitationEmail = true;
        let invitationSms = true;
        let roomDuration = 0;
        let meetingRoomAvailabilityBefore = 0;
        let meetingRoomAvailabilityAfter = 0;
        let defaultMeetingRoomDuration = 0;
        let secureLogin = true;
        let passwordLength = 0;
        let passwordMaxRetries = 0;
        let contactMail = "";

        if(this.props.settings) {
            this.props.settings.forEach(settings => {
                if (settings.name === "invitation_email") {
                    invitationEmail = settings.value;
                } else if (settings.name === "invitation_sms") {
                    invitationSms = settings.value;
                } else if (settings.name === "room_duration") {
                    roomDuration = settings.value;
                } else if (settings.name === "meeting_room_availability_before") {
                    meetingRoomAvailabilityBefore = settings.value;
                } else if (settings.name === "meeting_room_availability_after") {
                    meetingRoomAvailabilityAfter = settings.value;
                } else if (settings.name === "default_meeting_room_duration") {
                    defaultMeetingRoomDuration = settings.value;
                } else if (settings.name === "secure_login") {
                    secureLogin = settings.value;
                } else if (settings.name === "password_length") {
                    passwordLength = settings.value;
                } else if (settings.name === "password_max_retries") {
                    passwordMaxRetries = settings.value;
                } else if (settings.name === "contact_mail") {
                    contactMail = settings.value;
                }

                this.setState({
                    settings: {
                        ...this.state.settings,
                        invitation_email: invitationEmail === 'true',
                        invitation_sms: invitationSms === 'true',
                        secure_login: secureLogin === 'true',
                        contact_mail: contactMail,
                        room_duration: {value: roomDuration, disableDec: false},
                        meeting_room_availability_before: {value: meetingRoomAvailabilityBefore, disableDec: false},
                        meeting_room_availability_after: {value: meetingRoomAvailabilityAfter, disableDec: false},
                        default_meeting_room_duration: {value: defaultMeetingRoomDuration, disableDec: false},
                        password_length: {value: passwordLength, disableDec: false},
                        password_max_retries: {value: passwordMaxRetries, disableDec: false}
                    }
                });
            });
        }


        return new Promise((resolve, reject) => {
            settingsService.getSmsToken(this.props.user).then(value => {
                resolve(this.setState({
                    settings: {
                        ...this.state.settings,
                        sms_token: {
                            ...this.state.settings.sms_token,
                            value: value.value,
                            id: value.id
                        }
                    },
                    prevSettings: {
                        ...this.state.prevSettings,
                        sms_token: {
                            ...this.state.prevSettings.sms_token,
                            value: value.value
                        }
                    }
                }));
            });
            mailService.getMailContent(this.props.user).then(value => {
                const idxInv = value.findIndex(obj => obj.type === "invitation");
                const idxMeetingCreation = value.findIndex(obj => obj.type === "meetingcreation");
                const idxMeetingUpdate = value.findIndex(obj => obj.type === "meetingupdate");
                const idxMeetingDelete = value.findIndex(obj => obj.type === "meetingdelete");
                resolve(this.setState({
                    settings: {
                        ...this.state.settings,
                        invitationMailContent: {
                            ...this.state.settings.invitationMailContent,
                            subject: idxInv >= 0 ? value[idxInv].content.subject : "",
                            body: idxInv >= 0 ? value[idxInv].content.body : ""
                        },
                        meetingCreationMailContent: {
                            ...this.state.settings.meetingCreationMailContent,
                            subject: idxMeetingCreation >= 0 ? value[idxMeetingCreation].content.subject : "",
                            body: idxMeetingCreation >= 0 ? value[idxMeetingCreation].content.body : ""
                        },
                        meetingUpdateMailContent: {
                            ...this.state.settings.meetingUpdateMailContent,
                            subject: idxMeetingUpdate >= 0 ? value[idxMeetingUpdate].content.subject : "",
                            body: idxMeetingUpdate >= 0 ? value[idxMeetingUpdate].content.body : ""
                        },
                        meetingDeleteMailContent: {
                            ...this.state.settings.meetingDeleteMailContent,
                            subject: idxMeetingDelete >= 0 ? value[idxMeetingDelete].content.subject : "",
                            body: idxMeetingDelete >= 0 ? value[idxMeetingDelete].content.body : ""
                        }
                    },
                    prevSettings: {
                        ...this.state.prevSettings,
                        invitationMailContent: {
                            ...this.state.settings.invitationMailContent,
                            subject: idxInv >= 0 ? value[idxInv].subject : "",
                            body: idxInv >= 0 ? value[idxInv].body : ""
                        },
                        meetingCreationMailContent: {
                            ...this.state.settings.meetingCreationMailContent,
                            subject: idxMeetingCreation >= 0 ? value[idxMeetingCreation].subject : "",
                            body: idxMeetingCreation >= 0 ? value[idxMeetingCreation].body : ""
                        },
                        meetingUpdateMailContent: {
                            ...this.state.settings.meetingUpdateMailContent,
                            subject: idxMeetingUpdate >= 0 ? value[idxMeetingUpdate].subject : "",
                            body: idxMeetingUpdate >= 0 ? value[idxMeetingUpdate].body : ""
                        },
                        meetingDeleteMailContent: {
                            ...this.state.settings.meetingDeleteMailContent,
                            subject: idxMeetingDelete >= 0 ? value[idxMeetingDelete].subject : "",
                            body: idxMeetingDelete >= 0 ? value[idxMeetingDelete].body : ""
                        }
                    }}));
            }).catch(reason => {
                reject(reason);
            });
            getSMSContent(this.props.user).then(value => {
                const idxInv = value.data.findIndex(obj => obj.type === "invitation");
                const idxMeetingCreation = value.data.findIndex(obj => obj.type === "meetingcreation");
                const idxMeetingUpdate = value.data.findIndex(obj => obj.type === "meetingupdate");
                const idxMeetingDelete = value.data.findIndex(obj => obj.type === "meetingdelete");

                resolve(this.setState({
                    settings: {
                        ...this.state.settings,
                        invitationSMSContent: {
                            ...this.state.settings.invitationSMSContent,
                            message: idxInv >= 0 ? value.data[idxInv].content.message : ""
                        },
                        meetingCreationSMSContent: {
                            ...this.state.settings.meetingCreationSMSContent,
                            message: idxMeetingCreation >= 0 ? value.data[idxMeetingCreation].content.message : ""
                        },
                        meetingUpdateSMSContent: {
                            ...this.state.settings.meetingUpdateSMSContent,
                            message: idxMeetingUpdate >= 0 ? value.data[idxMeetingUpdate].content.message : ""
                        },
                        meetingDeleteSMSContent: {
                            ...this.state.settings.meetingDeleteSMSContent,
                            message: idxMeetingDelete >= 0 ? value.data[idxMeetingDelete].content.message : ""
                        }
                    },
                    prevSettings: {
                        ...this.state.prevSettings,
                        invitationSMSContent: {
                            ...this.state.settings.invitationSMSContent,
                            message: idxInv >= 0 ? value.data[idxInv].content.message : ""
                        },
                        meetingCreationSMSContent: {
                            ...this.state.settings.meetingCreationSMSContent,
                            message: idxMeetingCreation >= 0 ? value.data[idxMeetingCreation].content.message : ""
                        },
                        meetingUpdateSMSContent: {
                            ...this.state.settings.meetingUpdateSMSContent,
                            message: idxMeetingUpdate >= 0 ? value.data[idxMeetingUpdate].content.message : ""
                        },
                        meetingDeleteSMSContent: {
                            ...this.state.settings.meetingDeleteSMSContent,
                            message: idxMeetingDelete >= 0 ? value.data[idxMeetingDelete].content.message : ""
                        }
                    }
                }));
            }).catch(reason => {
                reject(reason);
            });
        });






    }

    render() {
        if (!this.props.user || !this.props.user.roles.includes("Admin")) {
            return <Redirect to="/login"/>;
        }

        return (
            <div className="Settings">
                <WhitePanel>
                    <form onSubmit={this.handleSubmit} id="Settings">
                        <div className="settings_section">
                            <h2>{data.room.title} :</h2>
                            <FormGroup controlId="setting">
                                <Checkbox checked={this.state.settings.invitation_email}
                                          onChange={this.handleChangeCheckboxes}
                                          name="invitation_email"
                                >{data.room.invitationEmail}</Checkbox>
                                <Checkbox
                                    checked={this.state.settings.invitation_sms}
                                    onChange={this.handleChangeCheckboxes}
                                    name="invitation_sms"
                                >{data.room.invitationSms}</Checkbox>
                                <ControlLabel style={{fontSize: "15px", fontWeight: "200"}}>{data.room.availability} :</ControlLabel>
                                <span className="quantity-picker">
                                    <button type="button" name="room_duration" value={this.state.settings.room_duration.value} className={`${this.state.settings.room_duration.disableDec ? 'mod-disable ' : ''}quantity-modifier`} disabled={this.state.settings.room_duration.disableDec} onClick={this.decrement}>&ndash;</button>
                                    <input className="quantity-display" type="text" value={this.state.settings.room_duration.value} readOnly />
                                    <button type="button" name="room_duration" value={this.state.settings.room_duration.value} className="quantity-modifier" onClick={this.increment}>&#xff0b;</button>
                                </span>
                            </FormGroup>
                        </div>
                        <div className="settings_section">
                            <h2>{data.meetings.title} :</h2>
                            <FormGroup controlId="setting">
                                <ControlLabel style={{fontSize: "15px", fontWeight: "200"}}>{data.meetings.defaultDuration} :</ControlLabel>
                                <span className="quantity-picker">
                                    <button type="button" name="default_meeting_room_duration" value={this.state.settings.default_meeting_room_duration.value} className={`${this.state.settings.default_meeting_room_duration.disableDec ? 'mod-disable ' : ''}quantity-modifier`} disabled={this.state.settings.default_meeting_room_duration.disableDec} onClick={this.decrement}>&ndash;</button>
                                    <input className="quantity-display" type="text" value={this.state.settings.default_meeting_room_duration.value} readOnly />
                                    <button type="button" name="default_meeting_room_duration" value={this.state.settings.default_meeting_room_duration.value} className="quantity-modifier" onClick={this.increment}>&#xff0b;</button>
                                </span>
                                <ControlLabel style={{fontSize: "15px", fontWeight: "200"}}>{data.meetings.availabilityBefore} :</ControlLabel>
                                <span className="quantity-picker">
                                    <button type="button" name="meeting_room_availability_before" value={this.state.settings.meeting_room_availability_before.value} className={`${this.state.settings.meeting_room_availability_before.disableDec ? 'mod-disable ' : ''}quantity-modifier`} disabled={this.state.settings.meeting_room_availability_before.disableDec} onClick={this.decrement}>&ndash;</button>
                                    <input className="quantity-display" type="text" value={this.state.settings.meeting_room_availability_before.value} readOnly />
                                    <button type="button" name="meeting_room_availability_before" value={this.state.settings.meeting_room_availability_before.value} className="quantity-modifier" onClick={this.increment}>&#xff0b;</button>
                                </span>
                                <ControlLabel style={{fontSize: "15px", fontWeight: "200"}}>{data.meetings.availabilityAfter} :</ControlLabel>
                                <span className="quantity-picker">
                                    <button type="button" name="meeting_room_availability_after" value={this.state.settings.meeting_room_availability_after.value} className={`${this.state.settings.meeting_room_availability_after.disableDec ? 'mod-disable ' : ''}quantity-modifier`} disabled={this.state.settings.meeting_room_availability_after.disableDec} onClick={this.decrement}>&ndash;</button>
                                    <input className="quantity-display" type="text" value={this.state.settings.meeting_room_availability_after.value} readOnly />
                                    <button type="button" name="meeting_room_availability_after" value={this.state.settings.meeting_room_availability_after.value} className="quantity-modifier" onClick={this.increment}>&#xff0b;</button>
                                </span>
                            </FormGroup>
                        </div>
                        <div className="settings_section">
                            <h2>{data.user.title} :</h2>
                            <FormGroup controlId="setting">
                                <ControlLabel style={{fontSize: "15px", fontWeight: "200"}}>{data.user.minimumPasswordLength} :</ControlLabel>
                                    <span className="quantity-picker">
                                        <button type="button" name="password_length" value={this.state.settings.password_length.value} className={`${this.state.settings.password_length.disableDec ? 'mod-disable ' : ''}quantity-modifier modifier-left`} disabled={this.state.settings.password_length.disableDec} onClick={this.decrement}>&ndash;</button>
                                        <input className="quantity-display" type="text" value={this.state.settings.password_length.value} readOnly />
                                        <button type="button" name="password_length" value={this.state.settings.password_length.value} className="quantity-modifier modifier-right" onClick={this.increment}>&#xff0b;</button>
                                    </span>
                                <ControlLabel style={{fontSize: "15px", fontWeight: "200"}}>{data.user.triesBeforeBlocking} :</ControlLabel>
                                    <span className="quantity-picker">
                                        <button type="button" name="password_max_retries" value={this.state.settings.password_max_retries.value} className={`${this.state.settings.password_max_retries.disableDec ? 'mod-disable ' : ''}quantity-modifier modifier-left`} disabled={this.state.settings.password_max_retries.disableDec} onClick={this.decrement}>&ndash;</button>
                                        <input className="quantity-display" type="text" value={this.state.settings.password_max_retries.value} readOnly />
                                        <button type="button" name="password_max_retries" value={this.state.settings.password_max_retries.value} className="quantity-modifier modifier-right" onClick={this.increment}>&#xff0b;</button>
                                    </span>
                                <ControlLabel style={{fontSize: "15px", fontWeight: "200"}}>{data.user.roomCreationRequirement.title} :</ControlLabel>
                                <Radio
                                    name="secure_login"
                                    checked={this.state.settings.secure_login}
                                    onChange={this.handleChangeRadios}
                                >
                                    {data.user.roomCreationRequirement.secureLogin}
                                </Radio>
                                <Radio
                                    name="secure_login"
                                    checked={!this.state.settings.secure_login}
                                    onChange={this.handleChangeRadios}
                                >
                                    {data.user.roomCreationRequirement.code}
                                </Radio>
                            </FormGroup>
                        </div>
                        <div className="settings_section">
                            <h2 style={{display: "inline"}}>{data.contact.title} :</h2>
                            <FormGroup>
                                <FormControl
                                    placeholder={data.contact.placeholder}
                                    name="contact_mail"
                                    value={this.state.settings.contact_mail}
                                    onChange={this.handleChange}
                                />
                            </FormGroup>
                        </div>
                        <div className="settings_section">
                            <h2 style={{display: "inline"}}>{data.email.title} :</h2>
                            <OverlayTrigger
                                trigger="click"
                                rootClose
                                placement="left"
                                overlay={
                                    <Popover id="popover-positioned-left" title={data.email.help.title} className="show" style={{fontSize: "12px"}}>
                                        {data.email.help.description} :<br />
                                        <strong>{data.email.help.variables.title} :</strong>
                                        <ul style={{marginLeft: "-30px"}}>
                                            <li>
                                                {data.email.help.variables.fullname} : <i>$FULLNAME$</i>
                                            </li>
                                            <li>
                                                {data.email.help.variables.code} : <i>$CODE$</i>
                                            </li>
                                            <li>
                                                {data.email.help.variables.start} : <i>$STARTDATE$</i>
                                            </li>
                                            <li>
                                                {data.email.help.variables.end} : <i>$ENDDATE$</i>
                                            </li>
                                        </ul>
                                        <strong>{data.email.help.html.title} :</strong>
                                        <ul style={{marginLeft: "-30px"}}>
                                            <li>
                                                {data.email.help.html.underline} : &lt;u&gt;<u>text...</u>&lt;/u&gt;
                                            </li>
                                            <li>
                                                {data.email.help.html.bold} : &lt;strong&gt;<strong>text...</strong>&lt;/strong&gt;
                                            </li>
                                            <li>
                                                {data.email.help.html.italic} : &lt;i&gt;<i>text...</i>&lt;/i&gt;
                                            </li>
                                        </ul>
                                    </Popover>
                                }
                            >
                                <img className="help-image" src={require(`../../${data.helpButton}`)} width={20} alt="help"/>
                            </OverlayTrigger>
                            <FormGroup controlId="setting">
                                <CollapsableSetting
                                    title={data.email.subTitle}
                                    collapsed={this.state.settings.currentEmailMeetingName !== "invitationMailContent"}
                                    onCollapseChange={e => this.handleMailCollapseChange(e, "invitationMailContent")}
                                    collapseContent={
                                        <div className="invitation-email">
                                            <FormControl
                                                name="subject"
                                                value={this.state.settings.invitationMailContent.subject}
                                                placeholder={data.email.subject}
                                                onChange={e => this.handleChangeContent(e, "invitationMailContent")}
                                            />
                                            <FormControl
                                                componentClass="textarea"
                                                rows="5"
                                                name="body"
                                                value={this.state.settings.invitationMailContent.body}
                                                placeholder={data.email.body}
                                                onChange={e => this.handleChangeContent(e, "invitationMailContent")}
                                            />
                                        </div>
                                    }
                                />
                                <ControlLabel style={{fontSize: "15px", fontWeight: "200"}}>{data.meetingEmails.title} :</ControlLabel>
                                <CollapsableSetting
                                    title={data.meetingEmails.subTitle1}
                                    collapsed={this.state.settings.currentEmailMeetingName !== "meetingCreationMailContent"}
                                    onCollapseChange={e => this.handleMailCollapseChange(e, "meetingCreationMailContent")}
                                    collapseContent={
                                        <div className="invitation-email">
                                            <FormControl
                                                name="subject"
                                                value={this.state.settings.meetingCreationMailContent.subject}
                                                placeholder={data.email.subject}
                                                onChange={e => this.handleChangeContent(e, "meetingCreationMailContent")}
                                            />
                                            <FormControl
                                                componentClass="textarea"
                                                rows="5"
                                                name="body"
                                                value={this.state.settings.meetingCreationMailContent.body}
                                                placeholder={data.email.body}
                                                onChange={e => this.handleChangeContent(e, "meetingCreationMailContent")}
                                            />
                                        </div>
                                    }
                                />
                                <CollapsableSetting
                                    title={data.meetingEmails.subTitle2}
                                    collapsed={this.state.settings.currentEmailMeetingName !== "meetingUpdateMailContent"}
                                    onCollapseChange={e => this.handleMailCollapseChange(e, "meetingUpdateMailContent")}
                                    collapseContent={
                                        <div className="invitation-email">
                                            <FormControl
                                                name="subject"
                                                value={this.state.settings.meetingUpdateMailContent.subject}
                                                placeholder={data.email.subject}
                                                onChange={e => this.handleChangeContent(e, "meetingUpdateMailContent")}
                                            />
                                            <FormControl
                                                componentClass="textarea"
                                                rows="5"
                                                name="body"
                                                value={this.state.settings.meetingUpdateMailContent.body}
                                                placeholder={data.email.body}
                                                onChange={e => this.handleChangeContent(e, "meetingUpdateMailContent")}
                                            />
                                        </div>
                                    }
                                />
                                <CollapsableSetting
                                    title={data.meetingEmails.subTitle3}
                                    collapsed={this.state.settings.currentEmailMeetingName !== "meetingDeleteMailContent"}
                                    onCollapseChange={e => this.handleMailCollapseChange(e, "meetingDeleteMailContent")}
                                    collapseContent={
                                        <div className="invitation-email">
                                            <FormControl
                                                name="subject"
                                                value={this.state.settings.meetingDeleteMailContent.subject}
                                                placeholder={data.email.subject}
                                                onChange={e => this.handleChangeContent(e, "meetingDeleteMailContent")}
                                            />
                                            <FormControl
                                                componentClass="textarea"
                                                rows="5"
                                                name="body"
                                                value={this.state.settings.meetingDeleteMailContent.body}
                                                placeholder={data.email.body}
                                                onChange={e => this.handleChangeContent(e, "meetingDeleteMailContent")}
                                            />
                                        </div>
                                    }
                                />
                            </FormGroup>
                        </div>
                        <div className="settings_section">
                            <h2 style={{display: "inline"}}>{data.sms.title} :</h2>
                            <OverlayTrigger
                                trigger="click"
                                rootClose
                                placement="left"
                                overlay={
                                    <Popover id="popover-positioned-left" title={data.sms.help.title} className="show" style={{fontSize: "12px"}}>
                                        {data.sms.help.description} :<br />
                                        <strong>{data.sms.help.variables.title} :</strong>
                                        <ul style={{marginLeft: "-30px"}}>
                                            <li>
                                                {data.sms.help.variables.fullname} : <i>$FULLNAME$</i>
                                            </li>
                                            <li>
                                                {data.sms.help.variables.code} : <i>$CODE$</i>
                                            </li>
                                            <li>
                                                {data.sms.help.variables.start} : <i>$STARTDATE$</i>
                                            </li>
                                            <li>
                                                {data.sms.help.variables.end} : <i>$ENDDATE$</i>
                                            </li>
                                        </ul>
                                    </Popover>
                                }
                            >
                                <img className="help-image" src={require(`../../${data.helpButton}`)} width={20} alt="help"/>
                            </OverlayTrigger>
                            <FormGroup controlId="setting">
                                <CollapsableSetting
                                    title={data.sms.subTitle}
                                    collapsed={this.state.settings.currentSMSMeetingName !== "invitationSMSContent"}
                                    onCollapseChange={e => this.handleSMSCollapseChange(e, "invitationSMSContent")}
                                    collapseContent={
                                        <div className="invitation-email">
                                            <FormControl
                                                componentClass="textarea"
                                                rows="5"
                                                name="message"
                                                value={this.state.settings.invitationSMSContent.message}
                                                placeholder={data.sms.message}
                                                onChange={e => this.handleChangeContent(e, "invitationSMSContent")}
                                            />
                                        </div>
                                    }
                                />
                                <ControlLabel style={{fontSize: "15px", fontWeight: "200"}}>{data.meetingSMS.title} :</ControlLabel>
                                <CollapsableSetting
                                    title={data.meetingSMS.subTitle1}
                                    collapsed={this.state.settings.currentSMSMeetingName !== "meetingCreationSMSContent"}
                                    onCollapseChange={e => this.handleSMSCollapseChange(e, "meetingCreationSMSContent")}
                                    collapseContent={
                                        <div className="invitation-email">
                                            <FormControl
                                                componentClass="textarea"
                                                rows="5"
                                                name="message"
                                                value={this.state.settings.meetingCreationSMSContent.message}
                                                placeholder={data.sms.message}
                                                onChange={e => this.handleChangeContent(e, "meetingCreationSMSContent")}
                                            />
                                        </div>
                                    }
                                />
                                <CollapsableSetting
                                    title={data.meetingSMS.subTitle2}
                                    collapsed={this.state.settings.currentSMSMeetingName !== "meetingUpdateSMSContent"}
                                    onCollapseChange={e => this.handleSMSCollapseChange(e, "meetingUpdateSMSContent")}
                                    collapseContent={
                                        <div className="invitation-email">
                                            <FormControl
                                                componentClass="textarea"
                                                rows="5"
                                                name="message"
                                                value={this.state.settings.meetingUpdateSMSContent.message}
                                                placeholder={data.sms.message}
                                                onChange={e => this.handleChangeContent(e, "meetingUpdateSMSContent")}
                                            />
                                        </div>
                                    }
                                />
                                <CollapsableSetting
                                    title={data.meetingSMS.subTitle3}
                                    collapsed={this.state.settings.currentSMSMeetingName !== "meetingDeleteSMSContent"}
                                    onCollapseChange={e => this.handleSMSCollapseChange(e, "meetingDeleteSMSContent")}
                                    collapseContent={
                                        <div className="invitation-email">
                                            <FormControl
                                                componentClass="textarea"
                                                rows="5"
                                                name="message"
                                                value={this.state.settings.meetingDeleteSMSContent.message}
                                                placeholder={data.sms.message}
                                                onChange={e => this.handleChangeContent(e, "meetingDeleteSMSContent")}
                                            />
                                        </div>
                                    }
                                />
                            </FormGroup>

                        </div>
                        <div className="text-right">
                            <Button bsSize="large" type="submit" form="Settings">
                                {data.submit}
                            </Button>
                        </div>
                        {this.state.message && (
                            <div className="form-group">
                                <div
                                    className={this.state.successful ? "alert alert-success" : "alert alert-danger"}
                                    role="alert">
                                    {this.state.message}
                                </div>
                            </div>
                        )}
                    </form>
                </WhitePanel>
                <div className="home-img" onClick={() => this.props.history.push("/administration")}>
                    <img src={require(`../../${data.homeButton}`)} alt="home-img" width={50}/>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const {settings} = state.settings;
    const {user} = state.auth;
    return {
        settings,
        user
    };
}

export default connect(mapStateToProps)(Settings);
