import React, {Component} from "react";
import PropTypes from "prop-types";
import Select from 'react-select';
import {Col, Row} from "react-bootstrap";
import {getUsers} from "../../../../services/user/userService";
import TextContent from "../../../text-content/TextContent";

const {secretariat} = TextContent(localStorage.getItem("language"));
const data = {
    name: secretariat.internal.name,
}

export default class AddInternalPaneContentDoctor extends Component {
    constructor(props) {
        super(props);

        this.state = {
            internals: [],
            errors: {
                name: ""
            }
        }

        this.handleSelect = this.handleSelect.bind(this);
        this.customFilter = this.customFilter.bind(this);
    }

    customFilter(option, searchText) {
        return option.data.fullname.toLowerCase().includes(searchText.toLowerCase()) ||
            option.data.login.toLowerCase().includes(searchText.toLowerCase())
    }

    handleSelect(val) {
        this.props.getInternal(val);
        if (this.props.onClose) {
            this.props.onClose();
        }
    }

    componentDidMount() {
        return new Promise(((resolve, reject) => {
            getUsers(this.props.currentUser).then(value => {
                resolve(this.setState({
                    internals: value.data
                }));
            }).catch(reason => {
                reject(reason);
            });
        }));
    }

    render() {
        return (
            <div className="AddInternalPane">
                <Row className="row">
                    <Col md={12}>
                        <Select
                            className="react-selectcomponent"
                            classNamePrefix="name-select"
                            onChange={this.handleSelect}
                            getOptionLabel={option => option.fullname}
                            getOptionValue={option => `${option}`}
                            options={this.state.internals}
                            isSearchable={true}
                            filterOption={this.customFilter}
                            noOptionsMessage={() => null}
                            placeholder={data.name}
                            autoFocus={true}
                            menuIsOpen={true}
                        />
                    </Col>
                </Row>
            </div>
        );
    }
}

AddInternalPaneContentDoctor.propTypes = {
    onClose: PropTypes.func,
    getInternal: PropTypes.any,
    currentUser: PropTypes.any
}