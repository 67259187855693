import React, {Component} from "react";
import PropTypes from "prop-types";
import CallableDialog from "../../../../nsi-lib/components/modals/CallableDialog";
import AddUserPaneContent from "../content/AddUserPaneContent";

import "../../User.css";
import TextContent from "../../../text-content/TextContent";


const {authentication} = TextContent(localStorage.getItem("language"));
const data = {
    manageDoctor: authentication.add.manageDoctor,
}

export default class AddUserPaneDialog extends Component {
    constructor(props) {
        super(props);

        this.closeDialog = this.closeDialog.bind(this);
    }

    closeDialog() {
        if (this.props.onClose) {
            this.props.onClose();
        }
    }

    render() {
        const {userId, isOpen} = this.props;

        return (
            <CallableDialog
                isOpen={isOpen}
                handleClose={this.closeDialog}
                fullwidth
                content={
                    <div className="AddUserPaneDialog">
                        <h3>{data.manageDoctor} :</h3>
                        <button className="close-button" onClick={() => this.closeDialog()}>
                            <i className="fa fa-times fa-2x" />
                        </button>
                        <AddUserPaneContent
                            userId={userId}
                            onClose={() => this.closeDialog()}
                        />
                    </div>
                }
            />
        );
    }
}

AddUserPaneDialog.propTypes = {
    isOpen: PropTypes.bool,
    userId: PropTypes.any,
    onClose: PropTypes.func
}
