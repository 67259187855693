import React, {Component} from "react";
import PropTypes from "prop-types";
import CallableDialog from "../../../../nsi-lib/components/modals/CallableDialog";
import AddExternalPaneContentDoctor from "../content/AddExternalPaneContentDoctor";

export default class AddExternalPaneDialogDoctor extends Component {
    constructor(props) {
        super(props);

        this.closeDialog = this.closeDialog.bind(this);
        this.getExternal = this.getExternal.bind(this);
        this.editExternal = this.editExternal.bind(this);
        this.deleteExternal = this.deleteExternal.bind(this);
    }

    getExternal(external) {
        this.props.getExternal(external)
    }

    editExternal(external) {
        this.props.editExternal(external)
    }

    deleteExternal(external) {
        this.props.deleteExternal(external)
    }

    closeDialog() {
        if (this.props.onClose) {
            this.props.onClose();
        }
    }

    render() {
        const {isOpen, content, index} = this.props;

        return (
            <CallableDialog
                isOpen={isOpen}
                dialogClassName="AddExternalPaneDialog"
                handleClose={this.closeDialog}
                fullwidth
                content={
                    <div>
                        <button className="close-button" onClick={() => this.closeDialog()}>
                            <i className="fa fa-times fa-2x" />
                        </button>
                        <AddExternalPaneContentDoctor
                            onClose={() => this.closeDialog()}
                            getExternal={this.getExternal}
                            editExternal={this.editExternal}
                            deleteExternal={this.deleteExternal}
                            content={content}
                            index={index}
                        />
                    </div>
                }
            />
        );
    }
}

AddExternalPaneDialogDoctor.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    getExternal: PropTypes.any,
    editExternal: PropTypes.any,
    deleteExternal: PropTypes.any,
    content: PropTypes.any,
    index: PropTypes.any
}
