import React, {Component} from "react";
import PropTypes from "prop-types";
import {Button, Col, ControlLabel, FormControl, FormGroup, HelpBlock, Row} from "react-bootstrap";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import TextContent from "../../../text-content/TextContent";
import {connect} from "react-redux";
import isEmail from "validator/lib/isEmail";
import {Redirect} from "react-router-dom";
import {register} from "../../../../actions/auth";
import PhoneInput from "react-phone-number-input";
import { isValidPhoneNumber } from 'react-phone-number-input'
import flags from "react-phone-number-input/flags";

const {authentication, resources} = TextContent(localStorage.getItem("language"));

const data = {
    avatar: resources.authentication.register.avatar,
    Form: {
        fullname: authentication.register.Form.fullname,
        username: authentication.register.Form.username,
        password: authentication.register.Form.password,
        passwordConfirm: authentication.register.Form.passwordConfirm,
        email: authentication.register.Form.email,
        submit: authentication.register.Form.submit
    },
    Error: {
        mandatory: authentication.Error.Mandatory,
        mailValidator: authentication.register.Error.mailValidator,
        fullnameLength: authentication.register.Error.fullnameLength,
        usernameLength: authentication.register.Error.usernameLength,
        passwordLengthStart: authentication.register.Error.passwordLengthStart,
        passwordLengthEnd: authentication.register.Error.passwordLengthEnd,
        passwordConfirm: authentication.register.Error.passwordConfirm
    }
}

class CreateUserPaneContent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            fullname: "",
            username: "",
            email: "",
            phone: "",
            password: "",
            confirmPassword: "",
            roles: {
                roleList: [],
                selectedRoles: []
            },
            errors: {
                fullname: "",
                fullnameLength: "",
                username: "",
                usernameLength: "",
                emailFormat: "",
                phoneFormat: "",
                password: "",
                passwordLength: "",
                confirmPassword: "",
                confirmPasswordEquals: ""
            },
            successful: false,
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.onChangeUsername = this.onChangeUsername.bind(this);
        this.onChangeLogin = this.onChangeLogin.bind(this);
        this.onChangeEmail = this.onChangeEmail.bind(this);
        this.onChangePhone = this.onChangePhone.bind(this);
        this.onChangePassword = this.onChangePassword.bind(this);
        this.onChangeConfirmPassword = this.onChangeConfirmPassword.bind(this);
    }

    onChangeUsername(e) {
        const value = e.target.value;
        let error = false;
        let lengthError = false;
        if (value.length === 0) {
            error = true;
        }
        if (value.length < 3 || value.length > 50)
        {
            lengthError = true;
        }
        this.setState({
            fullname: value,
            errors: {
                ...this.state.errors,
                fullname: error,
                fullnameLength: lengthError
            }
        });
    }

    onChangeLogin(e) {
        const value = e.target.value;
        let error = false;
        let lengthError = false;
        if (value.length === 0) {
            error = true;
        }
        if (value.length > 50) {
            lengthError = true;
        }
        this.setState({
            username: e.target.value.replace(/\s/g, ''),
            errors: {
                ...this.state.errors,
                username: error,
                usernameLength: lengthError
            }
        });
    }

    onChangeEmail(e) {
        const value = e.target.value;
        let formatError = false;
        if (value.length > 0) {
            if (!isEmail(value)) {
                formatError = true;
            }
        }
        this.setState({
            email: e.target.value,
            errors: {
                ...this.state.errors,
                emailFormat: formatError
            }
        });
    }

    onChangePhone(value) {
        let formatError = false;
        if (value) {
            if (!isValidPhoneNumber(value)) {
                formatError = true;
            }
        }
        this.setState({
            phone: value,
            errors: {
                ...this.state.errors,
                phoneFormat: formatError
            }
        });
    }

    onChangePassword(e) {
        let passwordLength = 0;
        this.props.settings.forEach(setting => {
            if(setting.name === "password_length")
                passwordLength = setting.value;
        });

        const value = e.target.value;
        let error = false;
        let lengthError = false;
        if (value.length === 0) {
            error = true;
        }
        if (value.length < passwordLength || value.length > 60) {
            lengthError = true;
        }
        this.setState({
            password: e.target.value,
            errors: {
                ...this.state.errors,
                password: error,
                passwordLength: lengthError
            }
        });
    }

    onChangeConfirmPassword(e) {
        const value = e.target.value;
        let error = false;
        let passwordError = false;
        if (value.length === 0) {
            error = true;
        }
        if (this.state.password !== this.state.confirmPassword) {
            passwordError = true;
        }
        this.setState({
            confirmPassword: e.target.value,
            errors: {
                ...this.state.errors,
                confirmPassword: error,
                confirmPasswordEquals: passwordError
            }
        });
    }

    onChangeRoles(value, event) {
        if (event.action === "select-option" && event.option.value === "*") {
            this.setState(this.options);
        } else if (
            event.action === "deselect-option" &&
            event.option.value === "*"
        ) {
            this.setState([]);
        } else if (event.action === "deselect-option") {
            this.setState(value.filter((o) => o.value !== "*"));
        } else if (value.length === this.options.length - 1) {
            this.setState(this.options);
        } else {
            this.setState(value);
        }
    }

    getDropdownButtonLabel({ placeholderButtonLabel, value }) {
        if (value && value.some((o) => o.value === "*")) {
            return `${placeholderButtonLabel}: All`;
        } else {
            return `${placeholderButtonLabel}: ${value.length} selected`;
        }
    }

    handleSubmit(e) {
        e.preventDefault();

        this.setState({
            successful: false,
        });

        this.props.dispatch(register(this.state.fullname, this.state.username, this.state.email ? this.state.email : null, this.state.phone ? this.state.phone : null, this.state.password, this.state.roles.selectedRoles))
            .then(() => {
                this.setState({
                    successful: true,
                });
                window.location.href = "/users";
            })
            .catch(() => {
                this.setState({
                    successful: false,
                });
            });
    }

    componentDidMount() {
        this.setState({
            roles: {
                ...this.state.roles,
                roleList: this.props.roles,
            }
        });
    }

    render() {
        let passwordLength = 0;
        const {message, currentUser, isLoggedIn, settings} = this.props;

        const formValid = !this.state.errors.fullname && !this.state.errors.fullnameLength && !this.state.errors.username
            && !this.state.errors.usernameLength && !this.state.errors.emailFormat && !this.state.errors.phoneFormat
            && !this.state.errors.password && !this.state.errors.passwordLength && !this.state.errors.confirmPassword
            && this.state.fullname.length > 2 && this.state.fullname.length > 0 && this.state.password.length > 0
            && this.state.confirmPassword.length > 0 && this.state.password === this.state.confirmPassword;

        const options = this.state.roles.roleList.map(function(row) {
            return { label : row.name, value : row.id }
        })

        settings.forEach(setting => {
            if(setting.name === "password_length")
                passwordLength = setting.value;
        });

        if (isLoggedIn && !currentUser.roles.includes("Admin")) {
            return <Redirect to="/"/>
        } else if (!isLoggedIn) {
            return <Redirect to="/"/>
        }

        return (
            <div className="CreateUserPane">
                <Row>
                    <Col lg={4} md={4} sm={12} xs={12}>
                        <Col lg={12} md={12} sm={6} xs={6}>
                            <img src={require(`../../../../${data.avatar}`)} className="profile-img-card"
                                 alt="profile-img" width={400} style={{marginTop: '50px'}}/>
                        </Col>
                    </Col>
                    <Col lg={7} md={7} sm={12} xs={12}>
                        <form onSubmit={this.handleSubmit} id="Create">
                            {!this.state.successful && (
                                <div>
                                    <FormGroup controlId="fullname"
                                               validationState={this.state.errors.fullname || this.state.errors.fullnameLength ? "error" : null}>
                                        <ControlLabel>{data.Form.fullname}</ControlLabel>
                                        <FormControl
                                            placeholder={data.Form.fullname}
                                            value={this.state.fullname}
                                            onChange={this.onChangeUsername}
                                        />
                                        {this.state.errors.fullname ?
                                            <HelpBlock>{data.Error.mandatory}</HelpBlock> : null}
                                        {this.state.errors.fullnameLength ?
                                            <HelpBlock>{data.Error.fullnameLength}</HelpBlock> : null}
                                    </FormGroup>
                                    <FormGroup controlId="username"
                                               validationState={this.state.errors.username || this.state.errors.usernameLength ? "error" : null}>
                                        <ControlLabel>{data.Form.username}</ControlLabel>
                                        <FormControl
                                            placeholder={data.Form.username}
                                            value={this.state.username}
                                            onChange={this.onChangeLogin}
                                        />
                                        {this.state.errors.username ?
                                            <HelpBlock>{data.Error.mandatory}</HelpBlock> : null}
                                        {this.state.errors.usernameLength ?
                                            <HelpBlock>{data.Error.usernameLength}</HelpBlock> : null}
                                    </FormGroup>
                                    <FormGroup controlId="email" validationState={this.state.errors.emailFormat ? "error" : null}>
                                        <ControlLabel>{data.Form.email}</ControlLabel>
                                        <FormControl
                                            placeholder={data.Form.email}
                                            type="email"
                                            value={this.state.email}
                                            onChange={this.onChangeEmail}
                                        />
                                        {this.state.errors.emailFormat ? <HelpBlock>{data.Error.mailValidator}</HelpBlock> : null}
                                    </FormGroup>
                                    <FormGroup controlId="phone" validationState={this.state.errors.phoneFormat ? "error" : null}>
                                        <ControlLabel>Phone</ControlLabel>
                                        <PhoneInput
                                            placeholder="Phone"
                                            flags={flags}
                                            value={this.state.phone}
                                            onChange={this.onChangePhone}
                                        />
                                        {this.state.errors.phoneFormat ? <HelpBlock>Phone non valide!</HelpBlock> : null}
                                    </FormGroup>
                                    <FormGroup controlId="password"
                                               validationState={this.state.errors.password || this.state.errors.passwordLength ? "error" : null}>
                                        <ControlLabel>{data.Form.password}</ControlLabel>
                                        <FormControl
                                            placeholder={data.Form.password}
                                            type="password"
                                            value={this.state.password}
                                            onChange={this.onChangePassword}
                                        />
                                        {this.state.errors.password ?
                                            <HelpBlock>{data.Error.mandatory}</HelpBlock> : null}
                                        {this.state.errors.passwordLength ?
                                            <HelpBlock>{data.Error.passwordLengthStart} {passwordLength} {data.Error.passwordLengthEnd}</HelpBlock> : null}
                                    </FormGroup>
                                    <FormGroup controlId="confirmPassword"
                                               validationState={this.state.errors.confirmPassword || this.state.password !== this.state.confirmPassword ? "error" : null}>
                                        <ControlLabel>{data.Form.passwordConfirm}</ControlLabel>
                                        <FormControl
                                            placeholder={data.Form.passwordConfirm}
                                            type="password"
                                            value={this.state.confirmPassword}
                                            onChange={this.onChangeConfirmPassword}
                                        />
                                        {this.state.errors.confirmPassword ?
                                            <HelpBlock>{data.Error.mandatory}</HelpBlock> : null}
                                        {this.state.password !== this.state.confirmPassword ?
                                            <HelpBlock>{data.Error.passwordConfirm}</HelpBlock> : null}
                                    </FormGroup>
                                    <FormGroup controlId="roles">
                                        <ReactMultiSelectCheckboxes
                                            className="checkboxes"
                                            options={[
                                                {label: "All", value: "*"},
                                                ...options
                                            ]}
                                            placeholderButtonLabel="Roles"
                                            getDropdownButtonLabel={this.getDropdownButtonLabel}
                                            value={this.state.roles.selectedRoles}
                                            onChange={this.onChangeRoles}
                                            setState={(e) => this.setState({roles: {...this.state.roles, selectedRoles: e}})}
                                        />
                                    </FormGroup>
                                </div>
                            )}
                            {message && (
                                <div className="form-group">
                                    <div
                                        className={this.state.successful ? "alert alert-success" : "alert alert-danger"}
                                        role="alert">
                                        {message}
                                    </div>
                                </div>
                            )}
                        </form>
                    </Col>
                </Row>
                <div className="update-user">
                    <Button bsSize="large" disabled={!formValid} type="submit" form="Create">
                        {data.Form.submit}
                    </Button>
                </div>
            </div>
        );
    }
}

CreateUserPaneContent.propTypes = {
    roles: PropTypes.any,
    onClose: PropTypes.func
}

function mapStateToProps(state) {
    const {message} = state.message;
    const {user, isLoggedIn} = state.auth;
    const {settings} = state.settings;
    return {
        message,
        currentUser: user,
        isLoggedIn,
        settings
    };
}

export default connect(mapStateToProps)(CreateUserPaneContent);