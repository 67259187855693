import React, {Component} from "react";
import PropTypes from 'prop-types';
import Setting from "./Setting";
import TextField from "@material-ui/core/TextField";

export default class InputSetting extends Component {

    constructor(props) {
        super(props);

        this.state = {
            value: this.props.value ? this.props.value : ''
        }

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(e) {
        const editable = this.props.editable ? this.props.editable : false;
        const value = e.target.value;

        if (editable) {
            if (!this.props.basedOnPropValue) {
                this.setState({
                    value: value
                });
            }

            if (this.props.onChange) {
                this.props.onChange(value);
            }
        }
    }

    render() {
        const value = this.props.basedOnPropValue ? this.props.value : this.state.value;
        const title = this.props.title ? this.props.title : 'No title';
        const type = this.props.type ? this.props.type : 'text';
        const size = this.props.size ? this.props.size : 'normal';

        let titleComp;
        switch (size) {
            case "small":
                titleComp = (
                    <h6>{title}</h6>
                )
                break;
            case "normal":
                titleComp = (
                    <h5>{title}</h5>
                )
                break;
            default : console.log("Problem with size title")
        }

        const actionComp = (
            <TextField
                multiline={this.props.multiline}
                value={value}
                size={"small"}
                variant={"outlined"}
                onChange={this.handleChange}
                disabled={!this.state.value && !this.props.editable}
                type={type}
            />
        )

        return (
            <Setting title={titleComp} action={actionComp}/>
        );
    }

}

InputSetting.propTypes = {
    onChange: PropTypes.func,
    title: PropTypes.string.isRequired,
    value: PropTypes.string,
    basedOnPropValue: PropTypes.bool,
    editable: PropTypes.bool,
    multiline: PropTypes.bool,
    type: PropTypes.string,
    size: PropTypes.oneOf(['small', 'normal'])
}
