import React, {Component} from "react";
import {ReactMultiEmail, isEmail} from "react-multi-email";
import PropTypes from "prop-types";
import {Button, Col, ControlLabel, FormGroup, HelpBlock, Row, Tab, Tabs} from "react-bootstrap";
import CallableDialog from "../../../nsi-lib/components/modals/CallableDialog";
import {sendMailForInvitation} from "../../../actions/mail";
import {connect} from "react-redux";
import TextContent from "../../../components/text-content/TextContent";
import PhoneInput, {isValidPhoneNumber} from "react-phone-number-input";
import flags from "react-phone-number-input/flags";
import {sendSMS} from "../../../services/sms/smsService";

import "./RoomShareDialog.css";
import 'react-multi-email/style.css';

const { room } = TextContent(localStorage.getItem("language"));
const data = (
    {
        Email: room.roomShare.Email,
        Phone: room.roomShare.Phone,
        MailShare: room.roomShare.MailShare,
        PhoneShare: room.roomShare.PhoneShare,
        Send: room.roomShare.Send
    }
);

class RoomShareDialog extends Component {
    constructor(props) {
        super(props);

        this.state = {
            emails: [],
            phones: [],
            phone: "",
            onEmail: false,
            onPhone: false,
            errors: {
                emailFormat: "",
                phoneFormat: "",

            },
        }

        this.closeDialog = this.closeDialog.bind(this);
        this.handleChangePhone = this.handleChangePhone.bind(this);
        this.sendInvite = this.sendInvite.bind(this);
        this.removePhone = this.removePhone.bind(this);
    }

    closeDialog() {
        if (this.props.onClose) {
            this.props.onClose();
        }
    }

    handleChangePhone(value) {
        let formatError = false;
        if (value) {
            if (!isValidPhoneNumber(value)) {
                formatError = true;
            }
        }
        this.setState({
            phone: value,
            errors: {
                ...this.state.errors,
                phoneFormat: formatError
            }
        });
    }


    sendInvite(e) {
        e.preventDefault();

        if (this.state.phones.length > 0) {
            new Promise(() => {
                sendSMS("invitation", this.props.code, this.props.user ? this.props.user.fullname : "Invité", this.state.phones)
                    .then(() => {
                        this.setState({phones : []});
                        this.closeDialog();
                    })
            });
        }
        if (this.state.emails.length > 0) {
            this.props.dispatch(sendMailForInvitation("invitation", this.props.code, this.props.user ? this.props.user.fullname : "Invité", this.state.emails))
                .then(() => {
                    this.setState({emails : []});
                    this.closeDialog();
                });
        }

    }

    removePhone(index) {
        const phoneArray = [...this.state.phones];
        phoneArray.splice(index, 1);

        this.setState({phones: phoneArray});
    }

    componentDidMount() {
        let invitationEmail = true;
        let invitationSms = true;
        this.props.settings.forEach(setting => {
            if(setting.name === "invitation_email")
                invitationEmail = setting.value;
            if(setting.name === "invitation_sms")
                invitationSms = setting.value;
        });
        this.setState({
            onEmail: invitationEmail,
            onPhone: invitationSms
        });
    }

    render() {
        const isOpen = this.props.isOpen;
        const FormValid = !this.state.errors.phoneFormat && (this.state.emails.length > 0 || this.state.phone > 0
        || this.state.phones.length > 0);


        return (
            <CallableDialog
                isOpen={isOpen}
                handleClose={this.closeDialog}
                dialogClassName="RoomShareDialog size_dialog"
                fullwidth
                content={
                    <div className="Share">
                        <button className="close-button" onClick={() => this.closeDialog()}>
                            <i className="fa fa-times fa-2x"/>
                        </button>
                        <Tabs id="uncontrolled-tab-example">
                            {this.state.onEmail === "true" &&
                            <Tab eventKey={1} title={data.MailShare}>
                                <ReactMultiEmail
                                    className="emails"
                                    placeholder={data.Email}
                                    emails={this.state.emails}
                                    onChange={(_emails) => {
                                        this.setState({emails: _emails});
                                    }}
                                    validateEmail={email => {
                                        return isEmail(email);
                                    }}
                                    getLabel={(
                                        email,
                                        index,
                                        removeEmail: (index: number) => void,
                                    ) => {
                                        return (
                                            <div data-tag="emails" key={index}>
                                                {email}
                                                <span data-tag-handle="times" onClick={() => removeEmail(index)}>
                                                    ×
                                                </span>
                                            </div>
                                        );
                                    }}
                                />
                                <Button onClick={this.sendInvite} disabled={!FormValid}
                                        style={{float: "right", marginTop: "20px"}}>
                                    {data.Send}
                                </Button>
                            </Tab>
                            }
                            {this.state.onPhone === "true" &&
                            <Tab eventKey={2} title={data.PhoneShare}>
                                <Row>
                                    <Col lg={11} md={11} sm={10} xs={10}>
                                        {/*<FormGroup controlId="phone">
                                            <PhoneInput
                                                placeholder={data.Phone}
                                                flags={flags}
                                                value={this.state.phone}
                                                onChange={this.handleChangePhone}
                                            />
                                        </FormGroup>*/}

                                        <FormGroup controlId="phone" validationState={this.state.errors.phoneFormat ? "error" : null}>
                                            <ControlLabel>Phone</ControlLabel>
                                            <PhoneInput
                                                placeholder={data.phone}
                                                flags={flags}
                                                value={this.state.phone}
                                                onChange={this.handleChangePhone}
                                            />
                                            {this.state.errors.phoneFormat ? <HelpBlock>Phone non valide!</HelpBlock> : null}
                                        </FormGroup>
                                    </Col>
                                    <Col lg={1} md={1} sm={2} xs={2}>
                                        <i
                                            className="fa fa-plus add"
                                            onClick={() => {
                                                if (isValidPhoneNumber(this.state.phone)) {
                                                    const joined = this.state.phones.concat(this.state.phone);
                                                    this.setState({ phones: joined, phone: "" });
                                                }
                                            }}
                                        />
                                    </Col>
                                </Row>
                                <ul className="items">
                                    {this.state.phones.length > 0 && this.state.phones.map((phone, index) =>
                                        <li key={index} onClick={() => this.removePhone(index)}>
                                            {phone}
                                        </li>
                                    )}
                                </ul>
                                <Button onClick={this.sendInvite} disabled={!FormValid}
                                        style={{float: "right", marginTop: "20px"}}>
                                    {data.Send}
                                </Button>
                            </Tab>
                            }
                        </Tabs>
                    </div>
                }
            />
        );
    }
}

RoomShareDialog.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    code: PropTypes.string
}

function mapStateToProps(state) {
    const {user} = state.auth;
    const {settings} = state.settings;
    return {
        user,
        settings
    };
}

export default connect(mapStateToProps)(RoomShareDialog);