import {MAIL_SUCCESS, MAIL_FAIL, SET_MESSAGE} from "./types";

import mailService from "../services/mail/mailService";

export const sendMailForInvitation = (type, code, fullname, emails, startDate, endDate) => (dispatch) => {
    return mailService.sendMailForInvitation(type, code, fullname, emails, startDate, endDate).then(
        (data) => {
            dispatch({
                type: MAIL_SUCCESS,
                payload: {mail: data}
            });

            return Promise.resolve();
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            dispatch({
                type: MAIL_FAIL,
            });

            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });

            return Promise.reject();
        }
    )
}


export const sendMailForInvitationCalendar = (type, code, fullname,host_email, emails, startDate, endDate) => (dispatch) => {
    return mailService.sendMailForInvitationCalendar(type, code, fullname,host_email, emails, startDate, endDate).then(
        (data) => {
            dispatch({
                type: MAIL_SUCCESS,
                payload: {mail: data}
            });

            return Promise.resolve();
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            dispatch({
                type: MAIL_FAIL,
            });

            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });

            return Promise.reject();
        }
    )
}
