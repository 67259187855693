import React, {Component} from "react";
import PropTypes from "prop-types";
import CallableDialog from "../../../../nsi-lib/components/modals/CallableDialog";
import AddHolidaysDatesPaneContent from "../content/AddHolidaysDatesPaneContent";
import TextContent from "../../../text-content/TextContent";

const {doctor} = TextContent(localStorage.getItem("language"));
const data = {
    title: doctor.holiday.holidaysDate.dialogTitle
}

export default class AddHolidaysDatesPaneDialog extends Component {
    constructor(props) {
        super(props);

        this.closeDialog = this.closeDialog.bind(this);
    }

    closeDialog() {
        if (this.props.onClose) {
            this.props.onClose();
        }
    }

    render() {
        const {isOpen, dates} = this.props;

        return (
            <CallableDialog
                isOpen={isOpen}
                handleClose={this.closeDialog}
                fullwidth
                dialogClassName="AddHolidaysDatesPaneDialog"
                content={
                    <div>
                        <h3>{data.title} :</h3>
                        <button className="schedule-close-button" onClick={() => this.closeDialog()}>
                            <i className="fa fa-times fa-2x" />
                        </button>
                        <AddHolidaysDatesPaneContent
                            dates={(newDates) => {
                                dates(newDates);
                                this.closeDialog();
                            }}
                        />
                    </div>
                }
            />
        );
    }
}

AddHolidaysDatesPaneDialog.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    dates: PropTypes.any
}