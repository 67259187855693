import React, {Component} from "react";
import PropTypes from 'prop-types';

export default class Setting extends Component {



    render() {
        const icon = this.props.icon;
        const title = this.props.title;
        const action = this.props.action;

        const iconClasses = this.props.iconClassName ? this.props.iconClassName : '';
        const titleClasses = this.props.titleClassName ? this.props.titleClassName : '';
        const actionClasses = this.props.actionClassName ? this.props.actionClassName : '';

        return (
            <div className="row">
                <div className="col d-flex align-items-center">
                    {icon &&
                    <div className={iconClasses + " mr-2"}>
                        {icon}
                    </div>
                    }
                    <div className={titleClasses + " flex-grow-1"}>
                        {title}
                    </div>
                    {action &&
                    <div className={actionClasses + " ml-2"}>
                        {action}
                    </div>
                    }
                </div>
            </div>
        );
    }

}

Setting.propTypes = {
    title: PropTypes.node.isRequired,
    action: PropTypes.node,
    icon: PropTypes.node,
    iconClassName: PropTypes.string,
    titleClassName: PropTypes.string,
    actionClassName: PropTypes.string
}
