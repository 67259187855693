import React, {Component} from "react";
import {DataGrid} from "@material-ui/data-grid";

/**
 * Custom MaterialUI DataGrid
 */
export default class CustomDataGrid extends Component {

    constructor(props) {
        super(props);
        this.onPageChange = this.onPageChange.bind(this);
        this.onPageSizeChange = this.onPageSizeChange.bind(this);
        this.onSelection = this.onSelection.bind(this);
    }

    render() {

        const data = this.props.rows ? this.props.rows : []
        const columns = this.props.columns ? this.props.columns : []
        const pageSize = this.props.pageSize ? this.props.pageSize : 0;
        const pageNo = this.props.pageNumber ? this.props.pageNumber : 0;
        const totalElements = this.props.totalElements ? this.props.totalElements : 0

        return (
            <div className="row">
                <div className="col customDataGrid">
                    <DataGrid
                        rows={data}
                        columns={columns}
                        page={pageNo}
                        pageSize={pageSize}
                        rowCount={totalElements}
                        pagination={true}
                        onPageChange={this.onPageChange}
                        onPageSizeChange={this.onPageSizeChange}
                        onSelectionChange={this.onSelection}
                        checkboxSelection={true}
                        paginationMode={"server"}/>
                </div>
            </div>
        );
    }

    onPageChange(e) {
        if (this.props.onPageChange) this.props.onPageChange(e);
    }

    onPageSizeChange(e) {
        if (this.props.onPageSizeChange) this.props.onPageSizeChange(e);
    }

    onSelection(e) {
        if (this.props.onSelection) this.props.onSelection(e);
    }

}
