import React, {Component} from "react";
import {Button, Col, ControlLabel, FormControl, FormGroup, HelpBlock, Row} from "react-bootstrap";
import TextContent from "../../../text-content/TextContent";
import {connect} from "react-redux";
import {Redirect} from "react-router-dom";
import {updateUserPassword} from "../../../../services/user/userService";

import "../../Profile.css";

const { resources, profile } = TextContent(localStorage.getItem("language"));

const data = {
    avatar: resources.authentication.register.avatar,
    currentPassword: profile.updatePassword.currentPassword,
    password: profile.updatePassword.password,
    passwordConfirm: profile.updatePassword.passwordConfirm,
    submit: profile.updatePassword.submit,
    mandatory: profile.updatePassword.error.mandatory,
    passwordLengthStart: profile.updatePassword.error.passwordLengthStart,
    passwordLengthEnd: profile.updatePassword.error.passwordLengthEnd,
    passwordMatch: profile.updatePassword.error.passwordConfirm,
    success: profile.updatePassword.message.success,
    error: profile.updatePassword.message.error,
}

class UpdatePasswordPaneContent extends Component {
    initialState = {
        previousPassword: "",
        password: "",
        confirmPassword: "",
        errors: {
            previousPassword: "",
            password: "",
            passwordLength: "",
            confirmPassword: "",
            confirmPasswordEquals: ""
        },
        successful: false,
        message: ""
    };

    constructor(props) {
        super(props);

        this.state = this.initialState;

        this.handleSubmit = this.handleSubmit.bind(this);
        this.onChangePassword = this.onChangePassword.bind(this);
        this.onChangePreviousPassword = this.onChangePreviousPassword.bind(this);
        this.onChangeConfirmPassword = this.onChangeConfirmPassword.bind(this);
    }

    onChangePreviousPassword(e) {
        const value = e.target.value;
        let error = false;
        if (value.length === 0) {
            error = true;
        }
        this.setState({
            previousPassword: e.target.value,
            errors: {
                ...this.state.errors,
                previousPassword: error
            }
        });
    }

    onChangePassword(e) {
        let passwordLength = 0;
        this.props.settings.forEach(setting => {
            if(setting.name === "password_length")
                passwordLength = setting.value;
        });

        const value = e.target.value;
        let error = false;
        let lengthError = false;
        if (value.length === 0) {
            error = true;
        }
        if (value.length < passwordLength || value.length > 60) {
            lengthError = true;
        }
        this.setState({
            password: e.target.value,
            errors: {
                ...this.state.errors,
                password: error,
                passwordLength: lengthError
            }
        });
    }

    onChangeConfirmPassword(e) {
        const value = e.target.value;
        let error = false;
        let passwordError = false;
        if (value.length === 0) {
            error = true;
        }
        if (this.state.password !== this.state.confirmPassword) {
            passwordError = true;
        }
        this.setState({
            confirmPassword: e.target.value,
            errors: {
                ...this.state.errors,
                confirmPassword: error,
                confirmPasswordEquals: passwordError
            }
        });
    }

    handleSubmit(e) {
        e.preventDefault();

        this.setState({
            successful: false,
        });

        updateUserPassword(this.state.previousPassword, this.state.password, this.props.user)
            .then(() => {
                this.setState({
                    successful: true,
                    message: data.success
                });
                window.location.reload();
            }).catch(() => {
            this.setState({
                successful: false,
                message: data.error,
            });
            setTimeout(() => {
                this.setState({
                    ...this.initialState
                });
            }, 2500);
        });
    }

    render() {
        let passwordLength = 0;
        this.props.settings.forEach(setting => {
            if(setting.name === "password_length")
                passwordLength = setting.value;
        });

        const formValid = !this.state.errors.password && !this.state.errors.passwordLength && !this.state.errors.confirmPassword
            && !this.state.errors.previousPassword && this.state.password.length > 0 && this.state.confirmPassword.length > 0
            && this.state.password === this.state.confirmPassword;

        if (!this.props.isLoggedIn) {
            return <Redirect to="/"/>
        }

        return (
            <div className="UpdatePassword">
                <Row>
                    <Col lg={4} md={4} sm={12} xs={12}>
                        <Col lg={12} md={12} sm={6} xs={6}>
                            <img src={require(`../../../../${data.avatar}`)} className="profile-img-card"
                                 alt="profile-img" width={400} style={{marginTop: '50px'}}/>
                        </Col>
                    </Col>
                    <Col lg={7} md={7} sm={12} xs={12}>
                        <form onSubmit={this.handleSubmit} id="UpdatePassword">
                            {!this.state.successful && (
                                <div>
                                    <FormGroup controlId="previousPassword"
                                               validationState={this.state.errors.previousPassword ? "error" : null}>
                                        <ControlLabel>{data.currentPassword}</ControlLabel>
                                        <FormControl
                                            placeholder={data.currentPassword}
                                            type="password"
                                            value={this.state.previousPassword}
                                            onChange={this.onChangePreviousPassword}
                                        />
                                        {this.state.errors.previousPassword ? <HelpBlock>Mandatory</HelpBlock> : null}
                                    </FormGroup>
                                    <FormGroup controlId="password"
                                               validationState={this.state.errors.password || this.state.errors.passwordLength ? "error" : null}>
                                        <ControlLabel>{data.password}</ControlLabel>
                                        <FormControl
                                            placeholder={data.password}
                                            type="password"
                                            value={this.state.password}
                                            onChange={this.onChangePassword}
                                        />
                                        {this.state.errors.password ?
                                            <HelpBlock>{data.mandatory}</HelpBlock> : null}
                                        {this.state.errors.passwordLength ?
                                            <HelpBlock>{data.passwordLengthStart} {passwordLength} {data.passwordLengthEnd}</HelpBlock> : null}
                                    </FormGroup>
                                    <FormGroup controlId="confirmPassword"
                                               validationState={this.state.errors.confirmPassword || this.state.password !== this.state.confirmPassword ? "error" : null}>
                                        <ControlLabel>{data.passwordConfirm}</ControlLabel>
                                        <FormControl
                                            placeholder={data.passwordConfirm}
                                            type="password"
                                            value={this.state.confirmPassword}
                                            onChange={this.onChangeConfirmPassword}
                                        />
                                        {this.state.errors.confirmPassword ?
                                            <HelpBlock>{data.mandatory}</HelpBlock> : null}
                                        {this.state.password !== this.state.confirmPassword ?
                                            <HelpBlock>{data.passwordMatch}</HelpBlock> : null}
                                    </FormGroup>
                                </div>
                            )}
                            {this.state.message && (
                                <div className="form-group">
                                    <div
                                        className={this.state.successful ? "alert alert-success" : "alert alert-danger"}
                                        role="alert">
                                        {this.state.message}
                                    </div>
                                </div>
                            )}
                        </form>
                    </Col>
                </Row>
                <div style={{float: "right"}}>
                    <Button bsSize="large" disabled={!formValid} type="submit" form="UpdatePassword">
                        {data.submit}
                    </Button>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const {user, isLoggedIn} = state.auth;
    const {settings} = state.settings;
    return {
        user,
        isLoggedIn,
        settings
    };
}

export default connect(mapStateToProps)(UpdatePasswordPaneContent);