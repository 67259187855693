import React, { useState, useEffect } from 'react';
import ProgressComponent from '@material-ui/core/CircularProgress';
import { withRouter } from 'react-router-dom';

function JitsiMeetComponent(props) {
  const [loading, setLoading] = useState(true);
  const containerStyle = {
    display: 'flex',
    justifyContent: 'center'
  };

  let roomId = props.room;

  const jitsiContainerStyle = {
    display: (loading ? 'none' : 'block'),
    width: '100%',
    height: 'auto',
  }

 function startConference() {
  try {
   const domain = process.env.REACT_APP_JITSI_DOMAIN;
   const options = {
    roomName: roomId,
    width: "100%",
    height: 660,
    parentNode: document.getElementById('jitsi-container'),
    interfaceConfigOverwrite: {
     filmStripOnly: false,
     SHOW_JITSI_WATERMARK: false,
     CLOSE_PAGE_GUEST_HINT: false,
     SHOW_PROMOTIONAL_CLOSE_PAGE: false
    },
    configOverwrite: {
     disableSimulcast: false,
    },
   };

   const api = new window.JitsiMeetExternalAPI(domain, options);
   api.addEventListener('videoConferenceJoined', () => {
    console.log('Local User Joined');
    setLoading(false);
    api.executeCommand('displayName', props.user ? props.user.fullname : 'Invité');
   });
   api.addEventListener('readyToClose', () => {
    props.history.push('/');
   });
  } catch (error) {
   console.error('Failed to load Jitsi API', error);
  }
 }

 useEffect(() => {
  // verify the JitsiMeetExternalAPI constructor is added to the global..
  if (window.JitsiMeetExternalAPI) startConference();
  else alert('Jitsi Meet API script not loaded');
 }, []); // eslint-disable-line react-hooks/exhaustive-deps

 return (
  <div
   style={containerStyle}
  >
   {loading && <ProgressComponent />}
   <div
    id="jitsi-container"
    style={jitsiContainerStyle}
   />
  </div>
 );
}

export default withRouter(JitsiMeetComponent);
