import {createStore, applyMiddleware} from "redux";
import {composeWithDevTools} from "redux-devtools-extension";
import thunk from "redux-thunk";
import rootReducer from "./reducers";
import jwtDecode from "jwt-decode";

const middleware = [thunk];

const checkTokenExpirationMiddleware = store => next => action => {
    const token =
        JSON.parse(localStorage.getItem("user")) &&
        JSON.parse(localStorage.getItem("user"))["accessToken"];

    if (token && jwtDecode(token).exp < Date.now() / 1000) {
        next(action);
        localStorage.removeItem("user");
    }
    next(action);
};

const store = createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware(...middleware, checkTokenExpirationMiddleware))
);

export default store;