import React, {Component} from "react";
import {connect} from "react-redux";
import {
    Bar,
    ComposedChart,
    Line,
    CartesianGrid,
    Legend,
    Tooltip,
    XAxis,
    YAxis,
    ResponsiveContainer,
} from 'recharts';

import { Pie } from 'react-chartjs-2';

import moment from 'moment';
import "./dashboard.css";
import {allRoom, roomPerUser} from "../../services/room/roomService";


import { CSVLink } from "react-csv";

import TextContent from "../text-content/TextContent";

const {dashboard} = TextContent(localStorage.getItem("language"));
const data = {
    title1: dashboard.title1,
    title2: dashboard.title2,
    download: dashboard.download,
    legend1_1: dashboard.legend1_1,
    legend1_2: dashboard.legend1_2,
    period: dashboard.period,
    legend2_1: dashboard.legend2_1,
    legend2_2: dashboard.legend2_2,
}

class Dashboard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            userRoom:[],
            allPlanandNoRoom:[],
            wells: [],
            well: [],
            dataRoomPlanAndNon:[],

            startWeek:"",
            endWeek:"",

            startDate:"",
            endDate:""
        }
        this.previousMonth = this.previousMonth.bind(this);
        this.nextMonth = this.nextMonth.bind(this);

        this.previousWeek = this.previousWeek.bind(this);
        this.nextWeek = this.nextWeek.bind(this);

    }

    previousMonth(){
        let ActualMonth =  moment(localStorage.getItem("startDate"));
        let prevMonthFirstDay =  ActualMonth.subtract(1, 'months').date(1).format('YYYY-MM-DD');
        let prevMonthLastDay =  ActualMonth.subtract(0,'months').endOf('month').format('YYYY-MM-DD');
        localStorage.setItem("startDate", prevMonthFirstDay);
        localStorage.setItem("endDate", prevMonthLastDay);

        this.setState({
            startDate: prevMonthFirstDay
        })

        this.setState({
            endDate: prevMonthLastDay
        })

        new Promise((resolve, reject) => {
            roomPerUser(this.props.user, prevMonthFirstDay, prevMonthLastDay).then(value => {
                if (this.state.userRoom.length) {
                    this.setState({
                        userRoom: []
                    })
                    for (let i = 0; i < value.data.length; i++) {
                        this.state.userRoom.push({
                            login: value.data[i].login,
                            roomPerPersonPerDate: value.data[i].roomPerPersonPerDate
                        })
                    }
                } else
                    for (let i = 0; i < value.data.length; i++) {
                        this.state.userRoom.push({
                            login: value.data[i].login,
                            roomPerPersonPerDate: value.data[i].roomPerPersonPerDate
                        })
                    }
                this.setState({
                    wells: value.data
                })
            }).catch(reason => {
                reject(reason);
            });
        });

       // window.location.reload();
}



    nextMonth(){
        let ActualMonth =  moment(localStorage.getItem("startDate"));
        let nextMonthFirstDay =  ActualMonth.add(1, 'months').date(1).format('YYYY-MM-DD');
        let nextMonthLastDay =  ActualMonth.add(0,'months').endOf('month').format('YYYY-MM-DD');
        localStorage.setItem("startDate", nextMonthFirstDay);
        localStorage.setItem("endDate", nextMonthLastDay);

        this.setState({
            startDate: nextMonthFirstDay
        })

        this.setState({
            endDate: nextMonthLastDay
        })


        new Promise((resolve, reject) => {
            roomPerUser(this.props.user, nextMonthFirstDay, nextMonthLastDay).then(value => {
                if (this.state.userRoom.length) {
                    this.setState({
                        userRoom: []
                    })
                    for (let i = 0; i < value.data.length; i++) {
                        this.state.userRoom.push({
                            login: value.data[i].login,
                            roomPerPersonPerDate: value.data[i].roomPerPersonPerDate
                        })
                    }
                } else
                    for (let i = 0; i < value.data.length; i++) {
                        this.state.userRoom.push({
                            login: value.data[i].login,
                            roomPerPersonPerDate: value.data[i].roomPerPersonPerDate
                        })
                    }
                this.setState({
                    wells: value.data
                })
            }).catch(reason => {
                reject(reason);
            });
        });


       // window.location.reload();
    }

    previousWeek(){
        let ActualWeek =  moment(localStorage.getItem("startWeek"));
        let prevWeekFirstDay =  ActualWeek.isoWeekday(-6).format('YYYY-MM-DD');
        let prevWeekLastDay =  ActualWeek.day(+7).format('YYYY-MM-DD');
        localStorage.setItem("startWeek", prevWeekFirstDay);
        localStorage.setItem("endWeek", prevWeekLastDay);

        this.setState({
            startWeek: prevWeekFirstDay
        })

        this.setState({
            endWeek: prevWeekLastDay
        })




        new Promise((resolve, reject) => {
            allRoom(this.props.user, prevWeekFirstDay, prevWeekLastDay).then(value => {
                if (this.state.allPlanandNoRoom.length){
                    this.setState({
                        allPlanandNoRoom: []
                    })

                    for (let i = 0; i < value.data.length; i++) {
                        this.state.allPlanandNoRoom.push({
                            starting_date: value.data[i].starting_date,
                            roomNoPlan: value.data[i].roomNonPlannified,
                            roomPlan: value.data[i].roomPlannified,
                            allRoom: value.data[i].allRoom
                        })
                    }
                }
                else {
                    for (let i = 0; i < value.data.length; i++) {
                        this.state.allPlanandNoRoom.push({
                            starting_date: value.data[i].starting_date,
                            roomNoPlan: value.data[i].roomNonPlannified,
                            roomPlan: value.data[i].roomPlannified,
                            allRoom: value.data[i].allRoom
                        })
                    }
                }
                this.setState({
                    wells: value.data
                })
            }).catch(reason => {
                reject(reason);
            });
        });

    }

    nextWeek(){
        let ActualWeek =  moment(localStorage.getItem("startWeek"));
        let nextWeekFirstDay =  ActualWeek.add(1, 'week').format('YYYY-MM-DD');
        let nextWeekLastDay =  ActualWeek.day(+7).format('YYYY-MM-DD');
        localStorage.setItem("startWeek", nextWeekFirstDay);
        localStorage.setItem("endWeek", nextWeekLastDay);
        this.setState({
            startWeek: nextWeekFirstDay
        })

        this.setState({
            endWeek: nextWeekLastDay
        })
        new Promise((resolve, reject) => {
            allRoom(this.props.user, nextWeekFirstDay, nextWeekLastDay).then(value => {
                if (this.state.allPlanandNoRoom.length){
                    this.setState({
                        allPlanandNoRoom:[]
                    })
                    for (let i = 0; i < value.data.length; i++) {
                        this.state.allPlanandNoRoom.push({
                            starting_date: value.data[i].starting_date,
                            roomNoPlan: value.data[i].roomNonPlannified,
                            roomPlan: value.data[i].roomPlannified,
                            allRoom: value.data[i].allRoom
                        })
                    }
                }
                else {
                    for (let i = 0; i < value.data.length; i++) {
                        this.state.allPlanandNoRoom.push({
                            starting_date: value.data[i].starting_date,
                            roomNoPlan: value.data[i].roomNonPlannified,
                            roomPlan: value.data[i].roomPlannified,
                            allRoom: value.data[i].allRoom
                        })
                    }
                }
                this.setState({
                    wells: value.data
                })
            }).catch(reason => {
                reject(reason);
            });
        });
    }

    componentDidMount () {

        const startOfMonth = moment().clone().startOf('month').format('YYYY-MM-DD');
        const endOfMonth   = moment().clone().endOf('month').format('YYYY-MM-DD');
        let currentDate = moment();
        let weekOfStart = currentDate.clone().startOf('isoWeek').format('YYYY-MM-DD');
        let weekOfEnd = currentDate.clone().endOf('isoWeek').format('YYYY-MM-DD');

        if (localStorage.getItem("startDate") === null)
        {
            localStorage.setItem("startDate", startOfMonth);
            localStorage.setItem("endDate", endOfMonth);
        }
        if (localStorage.getItem("startWeek") === null)
        {
            localStorage.setItem("startWeek", weekOfStart);
            localStorage.setItem("endWeek", weekOfEnd);
        }





        let startWeek = localStorage.getItem("startWeek");
        let endWeek = localStorage.getItem("endWeek");
        let startMonth = localStorage.getItem("startDate");
        let endMonth = localStorage.getItem("endDate");


        this.setState({
            startWeek: startWeek
        })

        this.setState({
            endWeek: endWeek
        })

        this.setState({
            startDate: startMonth
        })

        this.setState({
            endDate: endMonth
        })


        new Promise((resolve, reject) => {
            allRoom(this.props.user, startWeek, endWeek).then(value => {
                for (let i = 0; i < value.data.length; i++) {
                        this.state.allPlanandNoRoom.push({
                            starting_date: value.data[i].starting_date,
                            roomNoPlan: value.data[i].roomNonPlannified,
                            roomPlan: value.data[i].roomPlannified,
                            allRoom: value.data[i].allRoom
                        })
                }
                this.setState({
                    well: value.data
                })
            })
                roomPerUser(this.props.user, startMonth, endMonth).then(value => {
                    if (this.state.userRoom.length){
                        this.setState({
                            userRoom:[]
                        })
                        for (let i = 0; i < value.data.length; i++) {
                            this.state.userRoom.push({
                                login: value.data[i].login,
                                roomPerPersonPerDate: value.data[i].roomPerPersonPerDate
                            })
                        }
                    }
                    else
                        for (let i = 0; i < value.data.length; i++) {
                            this.state.userRoom.push({
                                login: value.data[i].login,
                                roomPerPersonPerDate: value.data[i].roomPerPersonPerDate
                            })
                        }
                    this.setState({
                        wells: value.data
                    })
                }).catch(reason => {
                    reject(reason);
                });
        });

    }


    render() {

        let noData = [];
        const { wells, well } = this.state

        let start_date =  localStorage.getItem("startDate");
        let end_date = localStorage.getItem("endDate");

        let start_week =  localStorage.getItem("startWeek");
        let end_week = localStorage.getItem("endWeek");

        const dataRoomPerUser = [];
        for (let i = 0; i < this.state.userRoom.length; i++) {
            dataRoomPerUser.push({
                login: this.state.userRoom[i].login,
                roomPerPersonPerDate: this.state.userRoom[i].roomPerPersonPerDate
            })
        }


        let dataRoomPlanAndNon = [];

        if (dataRoomPlanAndNon.length){
             dataRoomPlanAndNon = []
            for (let j = 0; j < this.state.allPlanandNoRoom.length; j++) {
                dataRoomPlanAndNon.push({
                    roomPlannified: this.state.allPlanandNoRoom[j].roomPlan,
                    roomNonPlannified: this.state.allPlanandNoRoom[j].roomNoPlan,
                    allRoom: this.state.allPlanandNoRoom[j].allRoom,
                    starting_date: this.state.allPlanandNoRoom[j].starting_date
                })
            }
        }

    else {


            for (let j = 0; j < this.state.allPlanandNoRoom.length; j++) {
                dataRoomPlanAndNon.push({
                    roomPlannified: this.state.allPlanandNoRoom[j].roomPlan,
                    roomNonPlannified: this.state.allPlanandNoRoom[j].roomNoPlan,
                    allRoom: this.state.allPlanandNoRoom[j].allRoom,
                    starting_date: this.state.allPlanandNoRoom[j].starting_date
                })
            }


        }



        const topFiveUser = []

        dataRoomPerUser.sort((a, b) => {
            return b.roomPerPersonPerDate - a.roomPerPersonPerDate;
        });

        for (let i = 0; i < 5; i++) {
            if (dataRoomPerUser[i]){
                topFiveUser.push({
                    name: dataRoomPerUser[i].login,
                    data: dataRoomPerUser[i].roomPerPersonPerDate
                })
            }
        }


        const dataForPie = [];
        for (let i = 0; i < topFiveUser.length; i++) {
            dataForPie.push(topFiveUser[i].data)
        }
        const labelsForPie = [];
        for (let i = 0; i < topFiveUser.length; i++) {
            labelsForPie.push(topFiveUser[i].name)
        }

        const dataPie = {
            labels: labelsForPie,
            datasets: [
                {
                    label: '# of Votes',
                    data: dataForPie,
                    backgroundColor: [
                        'rgba(255, 99, 132, 0.2)',
                        'rgba(54, 162, 235, 0.2)',
                        'rgba(255, 206, 86, 0.2)',
                        'rgba(75, 192, 192, 0.2)',
                        'rgba(153, 102, 255, 0.2)',
                    ],
                    borderColor: [
                        'rgba(255, 99, 132, 1)',
                        'rgba(54, 162, 235, 1)',
                        'rgba(255, 206, 86, 1)',
                        'rgba(75, 192, 192, 1)',
                        'rgba(153, 102, 255, 1)',
                    ],
                    borderWidth: 1,
                },
            ],
        };


        return wells.length || well.length ? (
            <div className="dashboard">
                <div className="row contain_dashboard">
                    <h2 className="title_dashboard">

                        {data.title1}
                    </h2>

                    <div className="row first_data_class">

                    <div className="pie_class col-lg-4">
                        <Pie data={dataPie}
                             height="300px"
                             className="class_pie"
                        />
                    </div>


                    <div className="chart_div col-md-10 col-lg-6  col-12">





                        <div className="row move_all">
                            <div className="col-lg-12 period_row_1">
                                <h3 className="class_periode">{data.period} :</h3>

                                <button type="button" className="btn btn-default btn-sm move-day move_prev" onClick={this.previousMonth}>
                                    <i className="calendar-icon ic-arrow-line-left" data-action="move-prev"/>
                                </button>
                                <h4 className="date_choose"> {start_date} ~ {end_date}</h4>
                                <button type="button" className="btn btn-default btn-sm move-day move_next" onClick={this.nextMonth}>
                                    <i className="calendar-icon ic-arrow-line-right" data-action="move-next"/>
                                </button>
                            </div>
                        </div>

                        <div className="table-wrapper-scroll-y my-custom-scrollbar">
                            <table className="table table-sm table-striped table-fixed" id="ministres">
                                <thead className="header_style">
                                <tr>
                                    <th scope="col">{data.legend1_1}</th>
                                    <th scope="col" className="second_head">{data.legend1_2}</th>
                                </tr>
                                </thead>
                                <tbody>
                                {dataRoomPerUser.map((RoomPerUser, index) =>
                                    {
                                        return (
                                        <tr key={index}>
                                            <td>{RoomPerUser.login}</td>
                                            <td>{RoomPerUser.roomPerPersonPerDate}</td>
                                        </tr>
                                        )
                                    }
                                )}

                                </tbody>
                            </table>
                        </div>
                    </div>



                    <div className="col-lg-2 cold-md-2 button_csv">
                        <button className="btn btn-lg btn-default button_download">
                            <CSVLink filename="data_per_user" data={dataRoomPerUser} separator={","}>
                                {data.download}
                            </CSVLink>
                        </button>
                    </div>
                </div>

                </div>


                    <div className="row contain_dashboard">
                        <h2 className="title_dashboard">
                            {data.title2}
                        </h2>




                        <div className="chart_div col-md-11 col-lg-8 offset-lg-2 offset-md-1 col-12">
                            <div className="row move_all">
                                <div className="col-lg-12 period_row_2">
                                    <h3 className="class_periode">{data.period} :</h3>

                                    <button type="button" className="btn btn-default btn-sm move-day move_prev" onClick={this.previousWeek}>
                                        <i className="calendar-icon ic-arrow-line-left" data-action="move-prev"/>
                                    </button>
                                    <h4 className="date_choose"> {this.state.startWeek} ~ {this.state.endWeek}</h4>
                                    <button type="button" className="btn btn-default btn-sm move-day move_next" onClick={this.nextWeek}>
                                        <i className="calendar-icon ic-arrow-line-right" data-action="move-next"/>
                                    </button>
                                </div>
                            </div>
                            <ResponsiveContainer width="90%" height={250}>
                            <ComposedChart width={830} height={250} data={dataRoomPlanAndNon}>
                                <XAxis dataKey="starting_date"/>
                                <YAxis />
                                <Tooltip />
                                <Legend verticalAlign="bottom" height={0}/>
                                <CartesianGrid stroke="#f5f5f5" />
                                <Bar dataKey="roomPlannified" fill="#37ab49" name={data.legend2_1}/>
                                <Bar dataKey="roomNonPlannified" fill="#0056b3" name={data.legend2_2}/>
                                <Line type="monotone" dataKey="allRoom" stroke="#ff7300" />
                            </ComposedChart>
                            </ResponsiveContainer>
                        </div>
                        <div className="col-lg-2 cold-md-2 button_csv">
                            <button className="btn btn-lg btn-default button_download">
                                <CSVLink filename="all_information" data={dataRoomPlanAndNon} separator={","}>
                                    {data.download}
                                </CSVLink>
                            </button>
                        </div>
                    </div>


                    </div>
        )
            : (

        <div className="dashboard">
            <h2 className="title_dashboard">
                {data.title1}
            </h2>
            <div className="row contain_dashboard">
                <div className="chart_div col-md-10 offset-2">
                    <div className="row move_all">
                        <div className="col-md-4 offset-2">
                            <button type="button" className="btn btn-default btn-sm move-day move_prev" onClick={this.previousMonth}>
                                <i className="calendar-icon ic-arrow-line-left" data-action="move-prev"/>
                            </button>
                            <h4 className="date_choose"> {start_date} ~ {end_date}</h4>
                            <button type="button" className="btn btn-default btn-sm move-day move_next" onClick={this.nextMonth}>
                                <i className="calendar-icon ic-arrow-line-right" data-action="move-next"/>
                            </button>
                        </div>
                    </div>
                    <div className="table-wrapper-scroll-y my-custom-scrollbar">
                        <table className="table table-sm table-striped table-fixed" id="ministres">
                            <thead className="header_style">
                            <tr>
                                <th scope="col">{data.legend1_1}</th>
                                <th scope="col" className="second_head">{data.legend1_2}</th>
                            </tr>
                            </thead>
                            <tbody>
                            {dataRoomPerUser.map((RoomPerUser, index) =>
                                {
                                    return (
                                        <tr key={index}>
                                            <td>{RoomPerUser.login}</td>
                                            <td>{RoomPerUser.roomPerPersonPerDate}</td>
                                        </tr>
                                    )
                                }
                            )}

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div className="row contain_dashboard">
                <h2 className="title_dashboard">
                    {data.title2}
                </h2>
                <div className="chart_div col-md-10 offset-2">
                    <div className="row move_all">
                        <div className="col-md-4 offset-2">
                        <button type="button" className="btn btn-default btn-sm move-day move_prev" onClick={this.previousWeek}>
                            <i className="calendar-icon ic-arrow-line-left" data-action="move-prev"/>
                        </button>
                            <h4 className="date_choose"> {start_week} ~ {end_week}</h4>
                        <button type="button" className="btn btn-default btn-sm move-day move_next" onClick={this.nextWeek}>
                            <i className="calendar-icon ic-arrow-line-right" data-action="move-next"/>
                        </button>
                        </div>


                    </div>
                    <ResponsiveContainer width="90%" height={250}>

                        <ComposedChart width={830} height={250} data={noData}>
                            <XAxis dataKey="starting_date"/>
                            <YAxis />
                            <Tooltip />
                            <Legend verticalAlign="bottom" height={36}/>
                            <CartesianGrid stroke="#f5f5f5" />
                            <Bar dataKey="roomPlannified" fill="#37ab49" name={data.legend2_1}/>
                            <Bar dataKey="roomNonPlannified" fill="#0056b3" name={data.legend2_2}/>
                            <Line type="monotone" dataKey="allRoom" stroke="#ff7300" />
                        </ComposedChart>
                    </ResponsiveContainer>
                </div>
            </div>
        </div>
            );
    }
}

function mapStateToProps(state) {
    const {user} = state.auth;
    return {
        user,
    };
}

export default connect(mapStateToProps)(Dashboard);