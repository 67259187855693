import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL

export function getRoles(user) {
    return new Promise((resolve, reject) => {
        axios.get(API_URL + "role",{
            headers: { 'Authorization': 'Bearer ' + user.accessToken }
        }).then(value => {
            resolve(value);
        }).catch(reason => {
            reject(reason);
        });
    });
}

export function createRole(name, user) {
    return new Promise((resolve, reject) => {
        axios.post(API_URL + "role/new", name, {
            headers: { 'Authorization': 'Bearer ' + user.accessToken }
        }).then(value => {
            resolve(value);
        }).catch(reason => {
            reject(reason);
        });
    });
}

export function deleteRole(id, user) {
    return new Promise((resolve, reject) => {
        axios.delete(API_URL + "role/" + id, {
            headers: { 'Authorization': 'Bearer ' + user.accessToken }
        }).then(value => {
            resolve(value);
        }).catch(reason => {
            reject(reason);
        });
    });
}