import React from "react";
import { withRouter } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import "./Home.css";
import TextContent from "../../components/text-content/TextContent";

const { home } = TextContent(localStorage.getItem("language"));
const data = {
    buttonRight: home.Text_Button_1,
    buttonLeft: home.Text_Button_2
}

class Home extends React.Component {

    render() {
        return (
            <div className="Home">
                <Row>
                    <Col lg={6} md={6} sm={6} xs={6} className="create-room">
                        <div className="container__img">
                            <a className="button button-left" href={"/createRoom"}>{data.buttonRight}</a>
                        </div>
                    </Col>
                    <Col lg={6} md={6} sm={6} xs={6} className="join-room">
                        <div className="container__img">
                            <a className="center-block button button-right" href={"/joinRoom"}>{data.buttonLeft}</a>
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default withRouter(Home);