import React from "react";
import WhitePanel from "../../components/white-panel/WhitePanel";
import "./NotFound.css";
import TextContent from "../../components/text-content/TextContent";

const data = {
    notFound: TextContent(localStorage.getItem("language")).notFound
}

export default function NotFound() {
    return (
        <div className="NotFound">
            <WhitePanel>
                <h3>{data.notFound}</h3>
            </WhitePanel>
        </div>
    );
}
