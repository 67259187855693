import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL

export function getUserRooms(currentUser) {
    return new Promise((resolve, reject) => {
        axios.get(API_URL + "room/" + currentUser.id + "/rooms", {
            headers: { 'Authorization': 'Bearer ' + currentUser.accessToken }
        }).then(value => {
            resolve(value);
        }).catch(reason => {
            reject(reason);
        });
    });
}

export function getUserLedRooms(currentUser) {
    return new Promise((resolve, reject) => {
        axios.get(API_URL + "room/" + currentUser.id + "/ledRooms", {
            headers: { 'Authorization': 'Bearer ' + currentUser.accessToken }
        }).then(value => {
            resolve(value);
        }).catch(reason => {
            reject(reason);
        });
    });
}

export function getRoomCode(currentUser, roomid) {
    return new Promise((resolve, reject) => {
        axios.get(API_URL + "room/" + roomid + "/code", {
            headers: { 'Authorization': 'Bearer ' + currentUser.accessToken }
        }).then(value => {
            resolve(value);
        }).catch(reason => {
            reject(reason);
        });
    });
}

export function getRoomLeader(currentUser, roomid) {
    return new Promise((resolve, reject) => {
        axios.get(API_URL + "room/" + roomid + "/leaders", {
            headers: { 'Authorization': 'Bearer ' + currentUser.accessToken }
        }).then(value => {
            resolve(value);
        }).catch(reason => {
            reject(reason);
        });
    });
}

export function createRoomFromUser(currentUser, startingDate) {
    return new Promise((resolve, reject) => {
        axios.post(API_URL + "room/new/" + currentUser.id, {
            starting_date: startingDate
        }, {
            headers: { 'Authorization': 'Bearer ' + currentUser.accessToken }
        }).then(value => {
            resolve(value);
        }).catch(reason => {
            reject(reason);
        });
    });
}

export function createRoomForMeetings(currentUser, starting_date, end_date, leaders, participants, details) {
    return new Promise((resolve, reject) => {
        axios.post(API_URL + "room/new/" + currentUser.id, {
            starting_date,
            end_date,
            leaders,
            participants,
            details
        }, {
            headers: { 'Authorization': 'Bearer ' + currentUser.accessToken }
        }).then(value => {
            resolve(value);
        }).catch(reason => {
            reject(reason);
        });
    });
}

export function createRoom(login, starting_date) {
    return new Promise((resolve, reject) => {
        axios.post(API_URL + "room/new", {
            login,
            starting_date
        }).then(value => {
            resolve(value);
        }).catch(reason => {
            reject(reason);
        });
    });
}

export function setRoomParticipant(currentUser, participants) {
    return new Promise((resolve, reject) => {
        axios.put(API_URL + "participant", {
            participants
        }, {
            headers: { 'Authorization': 'Bearer ' + currentUser.accessToken }
        }).then(value => {
            resolve(value);
        }).catch(reason => {
            reject(reason);
        });
    });
}

export function updateRoom(currentUser, starting_date, end_date, details, leaders, participants, roomId) {
    return new Promise((resolve, reject) => {
        axios.put(API_URL + "room/" + roomId, {
            starting_date,
            end_date,
            details,
            leaders,
            participants
        }, {
            headers: { 'Authorization': 'Bearer ' + currentUser.accessToken }
        }).then(value => {
            resolve(value);
        }).catch(reason => {
            reject(reason);
        });
    });
}

export function deleteRoom(currentUser, roomId) {
    return new Promise((resolve, reject) => {
        axios.delete(API_URL + "room/" + roomId, {
            headers: { 'Authorization': 'Bearer ' + currentUser.accessToken }
        }).then(value => {
            resolve(value);
        }).catch(reason => {
            reject(reason);
        });
    });
}
export function getAllRoom(currentUser, startDate, endDate) {
    return new Promise((resolve, reject) => {
        axios.get(API_URL + "room/all/?startDate="+startDate+"&endDate="+endDate, {
            headers: { 'Authorization': 'Bearer ' + currentUser.accessToken }
        }).then(value => {
            resolve(value);
        }).catch(reason => {
            reject(reason);
        });
    });
}

export function roomNonPlanified(currentUser, startDate, endDate) {
    return new Promise((resolve, reject) => {
        axios.get(API_URL + "room/noplannified?startDate="+startDate+"&endDate="+endDate, {
            headers: { 'Authorization': 'Bearer ' + currentUser.accessToken }
        }).then(value => {
            resolve(value);
        }).catch(reason => {
            reject(reason);
        });
    });
}

export function roomPlanified(currentUser, startDate, endDate) {
    return new Promise((resolve, reject) => {
        axios.get(API_URL + "room/plannified?startDate="+startDate+"&endDate="+endDate, {
            headers: { 'Authorization': 'Bearer ' + currentUser.accessToken }
        }).then(value => {
            resolve(value);
        }).catch(reason => {
            reject(reason);
        });
    });
}

export function roomPerUser(currentUser, startDate, endDate) {
    return new Promise((resolve, reject) => {
        axios.get(API_URL + "room/userday?startDate="+startDate+"&endDate="+endDate, {
            headers: { 'Authorization': 'Bearer ' + currentUser.accessToken }
        }).then(value => {
            resolve(value);
        }).catch(reason => {
            reject(reason);
        });
    });
}




export function allRoom(currentUser, startDate, endDate) {
    return new Promise((resolve, reject) => {
        axios.get(API_URL + "room/allroom?startDate="+startDate+"&endDate="+endDate, {
            headers: { 'Authorization': 'Bearer ' + currentUser.accessToken }
        }).then(value => {
            resolve(value);
        }).catch(reason => {
            reject(reason);
        });
    });
}