import React, {Component} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationTriangle} from "@fortawesome/free-solid-svg-icons";
import PropTypes from 'prop-types';

export default class GenericError extends Component {


    render() {
        const message = this.props.message;
        return (
            <div className="row">
                <div className="col mx-auto text-center">
                    <FontAwesomeIcon
                        icon={faExclamationTriangle}
                        className="text-danger"
                        size={"5x"}
                    />
                    {!message &&
                    <h5 className="mt-2">Something went wrong, <br/>please try again.</h5>
                    }
                    {message &&
                    <h5 className="mt-2">{message}</h5>
                    }
                </div>
            </div>
        );
    }

}

GenericError.propTypes = {
    message: PropTypes.string
}
