import React, {useEffect, useState} from "react";
import { Row, Col } from "react-bootstrap";
import {Link, withRouter} from "react-router-dom";
import RoomLink from "../room-link/RoomLink"
import WhitePanel from "../../white-panel/WhitePanel";
import TextContent from "../../text-content/TextContent";
import "./RoomCreator.css";
import {connect} from "react-redux";
import {createRoom, createRoomFromUser} from "../../../services/room/roomService";
import {logout} from "../../../actions/auth";

const { resources, room } = TextContent(localStorage.getItem("language"));
const data = {
    createImage: resources.room.roomCreator.createImage,
    infoImage: resources.room.roomCreator.infoImage,
    share: room.roomCreator.share,
    create: room.roomCreator.create
}

function RoomCreator(props) {
    const [userRoom, setUserRoom] = useState({
        user: props.user,
        room: null
    })

    useEffect(() => {
        let newDate = new Date();
        if (window.location.href !== window.document.referrer) {
            if (userRoom.user) {
                new Promise((resolve, reject) => {
                    createRoomFromUser(userRoom.user, newDate)
                        .then(value => {
                            resolve(
                                setUserRoom({
                                    ...userRoom,
                                    room: value.data
                                }));
                            localStorage.setItem("room", JSON.stringify(value.data))
                        }).catch(() => {
                        props.dispatch(logout());
                        reject(props.history.push("/login"));
                    });
                });
            } else {
                new Promise((resolve, reject) => {
                    createRoom(props.login, newDate)
                        .then(value => {
                            resolve(
                                setUserRoom({
                                    ...userRoom,
                                    room: value.data
                                }));
                        }).catch(() => {
                            reject(props.error(false, "An error occurred"))
                        });
                });
            }
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="RoomCreator">
            <WhitePanel>
                <h1>{data.create} :</h1>
                <Row>
                    <Col xs={1} xsOffset={11}>
                        <Link to="/" onClick={() => localStorage.removeItem("room")}>
                            <i className="fa fa-times fa-2x" />
                        </Link>
                    </Col>
                </Row>
                <Row style={{marginTop: "20px", marginBottom: "-20px"}}>
                    <Col lg={4} md={12} sm={12} xs={12} className="create-image">
                        <div className="container__img">
                            <img src={require(`../../../${data.createImage}`)} alt="" className="center-block" />
                        </div>
                    </Col>
                    <Col lg={8} md={12} sm={12} xs={12} className="fields">
                        {window.location.href !== window.document.referrer && userRoom.room ?
                            <RoomLink user={userRoom.user} room={userRoom.room} />
                            : window.location.href === window.document.referrer ?
                                <RoomLink user={userRoom.user} room={JSON.parse(localStorage.getItem("room"))} />
                                : null
                        }
                    </Col>
                </Row>
            </WhitePanel>
        </div>
    )
}

export default withRouter(connect()(RoomCreator));