import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL

export function getStatus(user) {
    return new Promise((resolve, reject) => {
        axios.get(API_URL + "status", {
            headers: { 'Authorization': 'Bearer ' + user.accessToken }
        }).then(value => {
            resolve(value);
        }).catch(reason => {
            reject(reason);
        });
    });
}