import React, {Component} from "react";
import {DropdownButton, MenuItem} from "react-bootstrap";
import Calendar from '@toast-ui/react-calendar';
import moment from 'moment';
import {getAssistedUsers} from "../../services/user/userService";
import {connect} from "react-redux";
import {
    createRoomForMeetings,
    getRoomLeader,
    setRoomParticipant,
    updateRoom
} from "../../services/room/roomService";
import {sendMailForInvitationCalendar} from "../../actions/mail";

import {getUserHolidays} from "../../services/holidays/holidaysService";
import MeetingPaneDialog from "./Pane/dialog/MeetingPaneDialog";
import TextContent from "../text-content/TextContent";

import 'tui-calendar/dist/tui-calendar.css';
import 'tui-date-picker/dist/tui-date-picker.css';
import 'tui-time-picker/dist/tui-time-picker.css';
import './css/Secretariat.css';
import './css/icons.css';
import {sendSMS} from "../../services/sms/smsService";

const {secretariat} = TextContent(localStorage.getItem("language"));
const data = {
    day: secretariat.day,
    week: secretariat.week,
    month: secretariat.month,
    today: secretariat.today,
    away: secretariat.away,
    holidays: secretariat.holidays,
    monday: secretariat.weekDays.monday,
    tuesday: secretariat.weekDays.tuesday,
    wednesday: secretariat.weekDays.wednesday,
    thursday: secretariat.weekDays.thursday,
    friday: secretariat.weekDays.friday,
    saturday: secretariat.weekDays.saturday,
    sunday: secretariat.weekDays.sunday,
    format: secretariat.dateFormat,
    monthFormat: secretariat.monthFormat,
    windowConfirm: secretariat.windowConfirm,
    titleCalendar: secretariat.titleCalendar,
    template: {
        delete: secretariat.template.delete,
        edit: secretariat.template.edit,
        update: secretariat.template.update,
        save: secretariat.template.save,
        title: secretariat.template.title,
        startDate: secretariat.template.startDate,
        endDate: secretariat.template.endDate,
    },
    error: {
        create: secretariat.error.create,
        update: secretariat.error.update,
        delete: secretariat.error.delete,
    }
}

class Secretariat extends Component {
    constructor(props) {
        super(props);

        this.state = {
            calendarInstance: "",
            calendarList: [],
            scheduleList: [],
            holidayList: [],
            selectedDoctor: 0,
            selectedDoctorIndex: 0,
            doctors: "",
            currentRoomCode: "",
            createPane: {
                toggledCreatePane: false,
                defaultContent: ""
            },
            intermediatePane: {
                toggledIntermediatePane: false,
                defaultContent: ""
            },
            message: ""
        }

        this.handleChangeSelectedDoctor = this.handleChangeSelectedDoctor.bind(this);
        this.onClickNavi = this.onClickNavi.bind(this);
        this.onClickMenu = this.onClickMenu.bind(this);
        this.getCalendars = this.getCalendars.bind(this);

        this.getNewSchedule = this.getNewSchedule.bind(this);
        this.updateSchedule = this.updateSchedule.bind(this);
    }

    calendarRef = React.createRef();

    toggleCreatePaneDialog(open, defaultContent) {
        let result;
        let shouldAsk = false;
        this.state.holidayList.forEach(holiday => {
            if (holiday.start !== false && holiday.end !== false) {
                const start = new Date(holiday.start);
                const end = new Date(holiday.end);
                const dateToCheck = defaultContent && new Date(defaultContent.start);

                result = defaultContent && moment(dateToCheck).isBetween(start, end);
                if (result) {
                    shouldAsk = true;
                }
            }
        });
        if (shouldAsk && !defaultContent.schedule) {
            const userChoice = window.confirm(data.windowConfirm);
            open = userChoice === true;
        }
        if (defaultContent && defaultContent.schedule) {

            if (defaultContent.end) {
                defaultContent.schedule.start = defaultContent.start
                defaultContent.schedule.end = defaultContent.end
            }

            defaultContent = defaultContent.schedule;
            this.setState({
                currentRoomCode: defaultContent.raw.code
            });
        }
        this.setState({
            createPane: {
                ...this.state.createPane,
                toggledCreatePane: open,
                defaultContent
            }
        });
        if (defaultContent) {


            this.setState({
                createPane: {
                    ...this.state.createPane,
                    toggledCreatePane: open,
                    defaultContent
                }
            });
        } else {
            this.setState({
                createPane: {
                    ...this.state.createPane,
                    toggledCreatePane: open,

                }
            });
        }
    }

    handleChangeSelectedDoctor(doctor,index) {
        localStorage.setItem("selectedDoctor", JSON.stringify(doctor));

        localStorage.setItem("IndexDoctor", index);


        this.setState({
            selectedDoctorIndex: index
        })

        this.setState({selectedDoctor: doctor, scheduleList: [], holidayList: []});

        doctor.led_rooms.forEach(room => {
            getRoomLeader(this.props.user, room.roomid).then(value => {
                this.setState(prevState => ({
                    scheduleList: [...prevState.scheduleList, {
                        id: room.roomid,
                        calendarId: doctor.id,
                        title: room.details ? room.details.title : "",
                        category: "time",
                        dueDateClass: "",
                        start: room.starting_date,
                        end: room.end_date,
                        raw: {
                            description: room.details ? room.details.description : "",
                            code: room.code,

                            participants: {
                                ...this.state.participants,
                                internal: value.data,
                                external: room.participants
                            }
                        }
                    }]
                }));
            });
        });
        getUserHolidays(this.props.user, doctor.id).then(value => {
            value.data && value.data.length > 0 && value.data.forEach(holiday => {
                let monday;
                let recurrenceDay;
                let selectedRecurrenceDay;
                if (holiday.day) {
                    const day = holiday.day.toLowerCase();
                    selectedRecurrenceDay = this.getRecurrenceDay(day);
                }
                if (this.state.calendarInstance) {
                    monday = new Date(moment(this.state.calendarInstance.getDateRangeStart().getTime()).format('YYYY-MM-DD'));
                    recurrenceDay = moment(new Date(monday).setDate(monday.getDate() + selectedRecurrenceDay)).format('YYYY-MM-DD');
                }
                this.setState(prevState => ({
                    holidayList: [...prevState.holidayList, {
                        calendarId: doctor.id,
                        title: holiday.isrecurrent ? data.away : data.holidays,
                        category: "time",
                        start: holiday.isrecurrent ? holiday.morning && holiday.afternoon ? `${recurrenceDay}T00:00:00.000` : holiday.morning ? `${recurrenceDay}T00:00:00.000` : holiday.afternoon && `${recurrenceDay}T12:00:00.000` : holiday.starting_date,
                        end: holiday.isrecurrent ? holiday.morning && holiday.afternoon ? `${recurrenceDay}T22:00:00.000` : holiday.morning ? `${recurrenceDay}T12:00:00.000` : holiday.afternoon && `${recurrenceDay}T22:00:00.000` : holiday.end_date,
                        isVisible: holiday.isrecurrent ? !(!holiday.morning && !holiday.afternoon) : true,
                        isReadOnly: true,
                        color: '#ffffff',
                        bgColor: holiday.isrecurrent ? '#e3e3e3' : '#bdfcb8',
                        dragBgColor: holiday.isrecurrent ? '#e3e3e3' : '#bdfcb8',
                        borderColor: holiday.isrecurrent ? '#e3e3e3' : '#bdfcb8',
                        raw: {
                            type: "holidays",
                            day: holiday.day ? holiday.day.toLowerCase() : "",
                            morning: holiday.morning,
                            afternoon: holiday.afternoon,
                            isRecurrent: holiday.isrecurrent,
                            selectedRecurrenceDay
                        }
                    }]
                }));
            });
        });
    }

    currentCalendarDate(format) {
        const currentDate = moment([this.state.calendarInstance.getDate().getFullYear(), this.state.calendarInstance.getDate().getMonth(), this.state.calendarInstance.getDate().getDate()]);
        return currentDate.format(format);
    }

    setDropdownCalendarType() {
        const calendarTypeName = document.getElementById('calendarTypeName');
        const calendarTypeIcon = document.getElementById('calendarTypeIcon');
        let type = this.state.calendarInstance.getViewName();
        let iconClassName;

        if (type === 'day') {
            type = data.day;
            iconClassName = 'calendar-icon ic_view_day';
        } else if (type === 'week') {
            type = data.week;
            iconClassName = 'calendar-icon ic_view_week';
        } else {
            type = data.month;
            iconClassName = 'calendar-icon ic_view_month';
        }

        calendarTypeName.innerHTML = type;
        calendarTypeIcon.className = iconClassName;
    }

    setRenderRangeText(first) {
        const html = [];
        const renderRange = document.getElementById('renderRange');
        const options = this.state.calendarInstance.getOptions();
        const viewName = this.state.calendarInstance.getViewName();
        const newHolidayList = this.state.holidayList.slice();

        if (viewName === 'day') {
            const currentDay = new Date(this.currentCalendarDate('YYYY-MM-DD'));

            newHolidayList.forEach(holiday => {
                if (holiday.raw.isRecurrent) {
                    if ((currentDay.getDay() || 7) - 1 === holiday.raw.selectedRecurrenceDay) {
                        holiday.start = holiday.raw.morning && holiday.raw.afternoon ? `${this.currentCalendarDate('YYYY-MM-DD')}T00:00:00.000` : holiday.raw.morning ? `${this.currentCalendarDate('YYYY-MM-DD')}T00:00:00.000` : holiday.raw.afternoon && `${this.currentCalendarDate('YYYY-MM-DD')}T12:00:00.000`;
                        holiday.end = holiday.raw.morning && holiday.raw.afternoon ? `${this.currentCalendarDate('YYYY-MM-DD')}T22:00:00.000` : holiday.raw.morning ? `${this.currentCalendarDate('YYYY-MM-DD')}T12:00:00.000` : holiday.raw.afternoon && `${this.currentCalendarDate('YYYY-MM-DD')}T22:00:00.000`;
                        holiday.isVisible = !(!holiday.raw.morning && !holiday.raw.afternoon);
                    }
                }
            });
            if(!first) this.setState({holidayList: newHolidayList});

            html.push(this.currentCalendarDate(data.format));
        } else if (viewName === 'month' &&
            (!options.month.visibleWeeksCount || options.month.visibleWeeksCount > 4)) {
            newHolidayList.forEach(holiday => {
                if (holiday.raw.isRecurrent) {
                    holiday.isVisible = false;
                }
            });

            if(!first) this.setState({holidayList: newHolidayList});

            html.push(this.currentCalendarDate(data.monthFormat));
        } else {
            const monday = new Date(moment(this.state.calendarInstance.getDateRangeStart().toDate()).format('YYYY-MM-DD'));

            newHolidayList.forEach(holiday => {
                if (holiday.raw.isRecurrent) {
                    const recurrenceDay = new Date(monday).setDate(monday.getDate() + holiday.raw.selectedRecurrenceDay);
                    holiday.start = holiday.raw.morning && holiday.raw.afternoon ? `${moment(recurrenceDay).format('YYYY-MM-DD')}T00:00:00.000` : holiday.raw.morning ? `${moment(recurrenceDay).format('YYYY-MM-DD')}T00:00:00.000` : holiday.raw.afternoon && `${moment(recurrenceDay).format('YYYY-MM-DD')}T12:00:00.000`;
                    holiday.end = holiday.raw.morning && holiday.raw.afternoon ? `${moment(recurrenceDay).format('YYYY-MM-DD')}T22:00:00.000` : holiday.raw.morning ? `${moment(recurrenceDay).format('YYYY-MM-DD')}T12:00:00.000` : holiday.raw.afternoon && `${moment(recurrenceDay).format('YYYY-MM-DD')}T22:00:00.000`;
                    holiday.isVisible = !(!holiday.raw.morning && !holiday.raw.afternoon);
                }
            });
            if(!first) this.setState({holidayList: newHolidayList});

            html.push(moment(this.state.calendarInstance.getDateRangeStart().getTime()).format(data.format));
            html.push(' ~ ');
            html.push(moment(this.state.calendarInstance.getDateRangeEnd().getTime()).format(data.format));
        }
        renderRange.innerHTML = html.join('');

    }

    getRecurrenceDay(day) {
        let selectedRecurrenceDay;
        switch (day) {
            case "monday": selectedRecurrenceDay = 0
                break;
            case "tuesday": selectedRecurrenceDay = 1
                break;
            case "wednesday": selectedRecurrenceDay = 2
                break;
            case "thursday": selectedRecurrenceDay = 3
                break;
            case "friday": selectedRecurrenceDay = 4
                break;
            case "saturday": selectedRecurrenceDay = 5
                break;
            case "sunday": selectedRecurrenceDay = 6
                break;
            default: console.log("No recurrence day")
        }
        return selectedRecurrenceDay;
    }

    getDataAction(target) {
        return target.dataset ? target.dataset.action : target.getAttribute('data-action');
    }

    getCalendars() {



        let calendars = [];
        this.state.doctors &&
        this.state.doctors.forEach(doctor => {
            calendars.push({
                id: doctor.id,
                name: doctor.fullname,
                color: '#ffffff',
                bgColor: '#00a9ff',
                dragBgColor: '#00a9ff',
                borderColor: '#00a9ff'
            });
        });

        return calendars;
    }

    getNewSchedule(content, shouldSendEmail, shouldSendSMS) {
        let participantsToSend, leadersToSend;
        let emails = [];
        let phones = [];
        let mailForHost = [];


        participantsToSend = content.raw.participants.external;
        leadersToSend = content.raw.participants.internal;



        participantsToSend.forEach(participant => {
            if (participant.email) {
                emails.push(participant.email);
            }
            if (participant.phone) {
                phones.push(participant.phone);
            }
        });

        leadersToSend.forEach(leader => {
            if (leader.email) {
                emails.push(leader.email);
                mailForHost.push(leader.email);
            }
            if (leader.phone) {
                phones.push(leader.phone);
            }
        });

        new Promise((resolve, reject) => {
            setRoomParticipant(this.props.user, participantsToSend)
                .then(value => {


                    resolve(createRoomForMeetings(this.props.user, content.start, content.end, leadersToSend, value.data, {title: content.title, description: content.raw.description})
                        .then(value => {
                            content.id = value.data.roomid;
                            content.raw.code = value.data.code;
                            this.setState(prevState => ({
                                scheduleList: [...prevState.scheduleList, content]
                            }));
                            if (shouldSendEmail)
                                resolve(this.props.dispatch(sendMailForInvitationCalendar("meetingcreation", value.data.code, this.state.selectedDoctor.fullname, mailForHost, emails, content.start, content.end)));
                            if (shouldSendSMS)
                                resolve(sendSMS("meetingcreation", value.data.code, this.state.selectedDoctor.fullname, phones, content.start, content.end));
                            window.location.reload();
                        }).catch(() => {
                            reject(this.setState({
                                message: data.error.create
                            }));
                        }));
                });
        });
    }

    updateSchedule(content, shouldSendEmail, shouldSendSMS) {
        let oldParticipants, participantsToSend, newParticipants;
        let oldLeaders, leadersToSend, newLeaders;
        let oldEmails = [], newEmails = [];
        let oldPhones = [], newPhones = [];
        let oldMailForHost = [], newMailForHost = [];






        oldParticipants = content.raw.oldParticipants.external;
        oldLeaders = content.raw.oldParticipants.internal;
        newParticipants = content.raw.newParticipants.external;
        newLeaders = content.raw.newParticipants.internal;
        participantsToSend = content.raw.participants.external;
        leadersToSend = content.raw.participants.internal;

        oldParticipants.forEach(participant => {
            if (participant.email) oldEmails.push(participant.email);
            if (participant.phone) oldPhones.push(participant.phone);
        });

        oldLeaders.forEach(leader => {
            if (leader.email) oldEmails.push(leader.email);
            if (leader.phone) oldPhones.push(leader.phone);
            if (leader.email) oldMailForHost.push(leader.email)

        })

        newParticipants.forEach(participant => {
            if (participant.email) newEmails.push(participant.email);
            if (participant.phone) newPhones.push(participant.phone);
        });

        newLeaders.forEach(leader => {
            if (leader.email) newEmails.push(leader.email);
            if (leader.phone) newPhones.push(leader.phone);
            if (leader.email) newMailForHost.push(leader.email)

        });

        const index = this.state.scheduleList.findIndex(schedule => schedule.id === content.id),
              scheduleList = [...this.state.scheduleList];
        scheduleList[index] = content;


        new Promise((resolve, reject) => {
            if (newParticipants && newParticipants.length > 0) {
                setRoomParticipant(this.props.user, newParticipants)
                    .then(value => {
                        participantsToSend = [...value.data];
                        resolve(updateRoom(this.props.user, content.start, content.end, {title: content.title, description: content.raw.description}, leadersToSend, participantsToSend, content.id)
                            .then(() => {
                                this.setState({scheduleList});
                                if (shouldSendEmail) {
                                    resolve(this.props.dispatch(sendMailForInvitationCalendar("meetingupdate", this.state.currentRoomCode, this.state.selectedDoctor.fullname,oldMailForHost, oldEmails, content.start, content.end)));
                                    resolve(this.props.dispatch(sendMailForInvitationCalendar("meetingcreation", this.state.currentRoomCode, this.state.selectedDoctor.fullname,newMailForHost, newEmails, content.start, content.end)));
                                }
                                if (shouldSendSMS) {
                                    resolve(sendSMS("meetingupdate", this.state.currentRoomCode, this.state.selectedDoctor.fullname, oldPhones, content.start, content.end));
                                    resolve(sendSMS("meetingcreation", this.state.currentRoomCode, this.state.selectedDoctor.fullname, newPhones, content.start, content.end));
                                }
                            }).catch(() => {
                                reject(this.setState({
                                    message: data.error.update
                                }));
                            }));
                    });
            } else {
                updateRoom(this.props.user, content.start, content.end, {title: content.title, description: content.raw.description}, leadersToSend, participantsToSend, content.id)
                    .then(() => {
                        this.setState({scheduleList});
                        if (shouldSendEmail) {
                            resolve(this.props.dispatch(sendMailForInvitationCalendar("meetingupdate", this.state.currentRoomCode, this.state.selectedDoctor.fullname,oldMailForHost, oldEmails, content.start, content.end)));
                            resolve(this.props.dispatch(sendMailForInvitationCalendar("meetingcreation", this.state.currentRoomCode, this.state.selectedDoctor.fullname,newMailForHost, newEmails, content.start, content.end)));
                        }
                        if (shouldSendSMS) {
                            resolve(sendSMS("meetingupdate", this.state.currentRoomCode, this.state.selectedDoctor.fullname, oldPhones, content.start, content.end));
                            resolve(sendSMS("meetingcreation", this.state.currentRoomCode, this.state.selectedDoctor.fullname, newPhones, content.start, content.end));
                        }
                        window.location.reload();
                    }).catch(() => {
                        reject(this.setState({
                            message: data.error.update
                        }));
                    });
            }
        });
    }



    onClickMenu(e) {
        const target = e.target;
        const action = this.getDataAction(target);
        const options = this.state.calendarInstance.getOptions();
        let viewName = '';

        switch (action) {
            case 'toggle-daily':
                viewName = 'day';
                break;
            case 'toggle-weekly':
                viewName = 'week';
                break;
            case 'toggle-monthly':
                options.month.visibleWeeksCount = 0;
                viewName = 'month';
                break;
            default:
                break;
        }

        this.state.calendarInstance.setOptions(options, true);
        this.state.calendarInstance.changeView(viewName, true);

        this.setDropdownCalendarType();
        this.setRenderRangeText(false);
    }

    onClickNavi(e) {
        const action = this.getDataAction(e.target);

        switch (action) {
            case 'move-prev':
                this.state.calendarInstance.prev();
                break;
            case 'move-next':
                this.state.calendarInstance.next();
                break;
            case 'move-today':
                this.state.calendarInstance.today();
                break;
            default:
                return;
        }

        this.setRenderRangeText(false);
    }

    componentDidMount() {
        if (!this.props.isLoggedIn) {
            this.props.history.push("/login");
        } else if (this.props.user && !this.props.user.roles.includes("Secretariat")) {
            this.props.history.push("/profile");
        } else {
            this.setState({
                calendarInstance: this.calendarRef.current ? this.calendarRef.current.getInstance() : ""
            });
            return new Promise((resolve, reject) => {
                getAssistedUsers(this.props.user).then(value => {
                    for (let i = 0; i < value.data.length; i++) {

                        if (localStorage.getItem("selectedDoctor") === null) {

                            localStorage.setItem("IndexDoctor", 0);
                            localStorage.setItem("selectedDoctor", JSON.stringify(value.data[0]));
                            this.setState({
                                selectedDoctor: localStorage.getItem("selectedDoctor") === null ? value.data[0] : JSON.parse(localStorage.getItem("selectedDoctor")),
                                doctors: value.data,
                            });
                        }
                        else{


                            let indexDoctor = localStorage.getItem("IndexDoctor");

                            this.setState({
                                doctors: value.data,
                                selectedDoctor: localStorage.getItem("selectedDoctor") != null ? value.data[indexDoctor] : JSON.parse(localStorage.getItem("selectedDoctor")),
                            });
                            localStorage.setItem("selectedDoctor", JSON.stringify(value.data[indexDoctor]));
                        }
                    }
                    this.state.selectedDoctor && this.state.selectedDoctor.led_rooms.forEach(room => {
                        getRoomLeader(this.props.user, room.roomid).then(value => {
                            this.setState(prevState => ({
                                scheduleList: [...prevState.scheduleList, {
                                    id: room.roomid,
                                    calendarId: this.state.selectedDoctor.id,
                                    title: room.details ? room.details.title : "",
                                    category: "time",
                                    start: room.starting_date,
                                    end: room.end_date,
                                    raw: {
                                        description: room.details ? room.details.description : "",
                                        code: room.code,
                                        participants: {
                                            ...this.state.participants,
                                            internal: value.data,
                                            external: room.participants
                                        }
                                    }
                                }]
                            }));
                        });
                    });
                    this.state.selectedDoctor &&
                        getUserHolidays(this.props.user, this.state.selectedDoctor.id).then(value => {
                            value.data && value.data.length > 0 && value.data.forEach(holiday => {
                                let monday;
                                let recurrenceDay;
                                let selectedRecurrenceDay;
                                if (holiday.day) {
                                    const day = holiday.day.toLowerCase();
                                    selectedRecurrenceDay = this.getRecurrenceDay(day);
                                }
                                if (this.state.calendarInstance) {
                                    monday = new Date(moment(this.state.calendarInstance.getDateRangeStart().getTime()).format('YYYY-MM-DD'));
                                    recurrenceDay = moment(new Date(monday).setDate(monday.getDate() + selectedRecurrenceDay)).format('YYYY-MM-DD');
                                }

                                this.setState(prevState => ({holidayList: [...prevState.holidayList, {
                                        calendarId: this.state.selectedDoctor.id,
                                        title: holiday.isrecurrent ? data.away : data.holidays,
                                        category: "time",
                                        start: holiday.isrecurrent ? holiday.morning && holiday.afternoon ? `${recurrenceDay}T00:00:00.000` : holiday.morning ? `${recurrenceDay}T00:00:00.000` : holiday.afternoon && `${recurrenceDay}T12:00:00.000` : holiday.starting_date,
                                        end: holiday.isrecurrent ? holiday.morning && holiday.afternoon ? `${recurrenceDay}T22:00:00.000` : holiday.morning ? `${recurrenceDay}T12:00:00.000` : holiday.afternoon && `${recurrenceDay}T22:00:00.000` : holiday.end_date,
                                        isVisible: holiday.isrecurrent ? !(!holiday.morning && !holiday.afternoon) : true,
                                        isReadOnly: true,
                                        color: '#ffffff',
                                        bgColor: holiday.isrecurrent ? '#e3e3e3' : '#bdfcb8',
                                        dragBgColor: holiday.isrecurrent ? '#e3e3e3' : '#bdfcb8',
                                        borderColor: holiday.isrecurrent ? '#e3e3e3' : '#bdfcb8',
                                        raw: {
                                            type: "holidays",
                                            day: holiday.day ? holiday.day.toLowerCase() : "",
                                            morning: holiday.morning,
                                            afternoon: holiday.afternoon,
                                            isRecurrent: holiday.isrecurrent,
                                            selectedRecurrenceDay
                                        }
                                    }]
                                }));
                            });
                        });
                }).catch(reason => {
                    reject(reason);
                });
            });
        }
    }

    render() {
        if (this.state.calendarInstance) {
            this.setRenderRangeText(true);
        }


        return (
            <div className="Secretariat">
                <div id="menu" className="top-menu">
                    <h2 className="name_title_doctor">{data.titleCalendar} : {this.state.selectedDoctor && this.state.selectedDoctor.fullname}</h2>
                    <span id="menu-navi" onClick={this.onClickNavi}>
                        <button type="button" className="btn btn-default btn-sm move-day" data-action="move-prev">
                            <i className="calendar-icon ic-arrow-line-left" data-action="move-prev"/>
                        </button>
                        <button type="button" className="btn btn-default btn-sm move-day" data-action="move-next">
                            <i className="calendar-icon ic-arrow-line-right" data-action="move-next"/>
                        </button>
                        <button type="button" className="btn btn-default btn-sm move-today" data-action="move-today">
                            {data.today}
                        </button>
                    </span>




                    <div className="calendar-type">
                        <DropdownButton
                            className="doctor-selector"
                            style={{marginRight: "5px"}}
                            noCaret
                            pullRight
                            id="split-button-pull-right"
                            title={
                                <div>
                                    <span>{this.state.selectedDoctor && this.state.selectedDoctor.fullname}</span>
                                    <i className="calendar-icon tui-full-calendar-dropdown-arrow"/>
                                </div>
                            }
                        >
                            {this.state.doctors && this.state.doctors.map((doctor, index) =>
                                <MenuItem eventKey={index} key={index}
                                          onClick={() => this.handleChangeSelectedDoctor(doctor,index)}>{doctor.fullname}</MenuItem>
                            )}
                        </DropdownButton>
                        <DropdownButton
                            noCaret
                            id="dropdownMenu-calendarType"
                            title={
                                <div>
                                    <i id="calendarTypeIcon" className="calendar-icon ic_view_week"
                                       style={{marginRight: "4px"}}/>
                                    <span id="calendarTypeName">{data.week}</span>
                                    <i className="calendar-icon tui-full-calendar-dropdown-arrow"/>
                                </div>
                            }
                        >
                            <MenuItem eventKey="1" className="dropdown-menu-title" data-action="toggle-daily"
                                      onClick={this.onClickMenu}><i
                                className="calendar-icon ic_view_day"/>{data.day}</MenuItem>
                            <MenuItem eventKey="2" className="dropdown-menu-title" data-action="toggle-weekly"
                                      onClick={this.onClickMenu}><i
                                className="calendar-icon ic_view_week"/>{data.week}</MenuItem>
                            <MenuItem eventKey="3" className="dropdown-menu-title" data-action="toggle-monthly"
                                      onClick={this.onClickMenu}><i
                                className="calendar-icon ic_view_month"/>{data.month}</MenuItem>
                        </DropdownButton>
                    </div>
                    <span id="renderRange" className="render-range"/>
                </div>
                <Calendar
                    ref={this.calendarRef}
                    height="700px"
                    usageStatistics={false}
                    template={{
                        timegridDisplayPrimaryTime: localStorage.getItem("language") !== "english" && function (time) {
                            return time.hour + "h";
                        },
                        popupDelete: () => {return data.template.delete},
                        popupEdit: () => {return data.template.edit},
                        popupSave: () => {return data.template.save},
                        popupUpdate: () => {return data.template.update},
                        titlePlaceholder: () => {return data.template.title},
                        startDatePlaceholder: () => {return data.template.startDate},
                        endDatePlaceholder: () => {return data.template.endDate},
                        alldayTitle: () => {return data.holidays}
                    }}
                    calendars={this.getCalendars()}
                    schedules={this.state.scheduleList.concat(this.state.holidayList)}
                    taskView={false}
                    useCreationPopup={false}
                    useDetailPopup={false}
                    week={{
                        daynames: [data.sunday, data.monday, data.tuesday, data.wednesday, data.thursday, data.friday, data.saturday],
                        hourStart: 7,
                        hourEnd: 19,
                        startDayOfWeek: 1
                    }}
                    month={{
                        daynames: [data.sunday, data.monday, data.tuesday, data.wednesday, data.thursday, data.friday, data.saturday],
                        narrowWeekend: true,
                        startDayOfWeek: 1
                    }}
                    onBeforeCreateSchedule={(e) => {this.toggleCreatePaneDialog(true, e)}}
                    onBeforeUpdateSchedule={(e) => {this.toggleCreatePaneDialog(true, e)}}
                    onClickSchedule={(e) => {this.toggleCreatePaneDialog(true,e)}}

                />

                <MeetingPaneDialog
                    isOpen={this.state.createPane.toggledCreatePane}
                    onClose={() => this.toggleCreatePaneDialog(false)}
                    defaultContent={this.state.createPane.defaultContent}
                    getScheduleContent={(e, shouldSendEmail, shouldSendSMS) => e.raw.isUpdate ? this.updateSchedule(e, shouldSendEmail, shouldSendSMS) : this.getNewSchedule(e, shouldSendEmail, shouldSendSMS)}
                    currentUser={this.props.user}
                    selectedDoctor={this.state.selectedDoctor}
                    settings={this.props.settings}
                />

            </div>
        );
    }
}

function mapStateToProps(state) {
    const {user, isLoggedIn} = state.auth;
    const {settings} = state.settings;
    return {
        user,
        isLoggedIn,
        settings
    };
}

export default connect(mapStateToProps)(Secretariat);
