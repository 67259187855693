import React, {Component} from "react";
import PropTypes from 'prop-types';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleRight} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import Setting from "./Setting";

export default class NavigableSetting extends Component {



    render() {
        const title = this.props.title ? this.props.title : 'No title';
        const url = this.props.url ? this.props.url : '';
        const icon = this.props.icon;
        const size = this.props.size ? this.props.size : 'normal';


        let iconComp = null;
        if (icon) {
            iconComp = (
                <Link to={url}>
                    <FontAwesomeIcon
                        icon={icon}
                        size={"lg"}
                        className="text-dark"
                    />
                </Link>
            )
        }

        const titleComp = (
            <Link to={url}>
                {size === 'normal' &&
                <h5 className="text-dark">{title}</h5>
                }
                {size === 'small' &&
                <h6 className="text-dark">{title}</h6>
                }
            </Link>
        )

        const actionComp = (
            <Link to={url}>
                <FontAwesomeIcon
                    icon={faAngleRight}
                    size={"2x"}
                    className="text-dark"
                />
            </Link>
        )

        return (
            <Setting title={titleComp} icon={iconComp} action={actionComp}/>
        );
    }

}

NavigableSetting.propTypes = {
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    icon: PropTypes.any,
    size: PropTypes.oneOf(['normal', 'small'])
}
